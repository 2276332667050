import React from "react";
import {Button, Modal} from "react-bootstrap";
import {declineAccount} from "../actions";
import useToken from "../../../Hooks/useToken";
import {useHandleError} from "../../../Hooks/useHandleError";

type Props = {
    show: boolean,
    onHide: () => void,
    userId: number,
    fireRefresh: () => void,
}

const DeclineModal: React.FC<Props> = ({show, onHide, userId, fireRefresh}) => {

    const [reason, setReason] = React.useState<string>("");

    const {loginUser} = useToken();

    const handleError = useHandleError();

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setReason(e.target.value);
    }

    const handleSubmit = () => {
        declineAccount(loginUser, userId, reason)
            .then(() => {
                fireRefresh();
                onHide()
            })
            .catch(handleError)
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Refuser un compte</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <span>Êtes-vous sûr de vouloir refuser ce compte ?</span>
                </div>
                <div>
                    <div className="m-3">Raison</div>
                    <textarea className="form-control" rows={3} onChange={handleChange} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Annuler
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Refuser
                </Button>
            </Modal.Footer>
        </Modal>
    );
}


export default DeclineModal;