import React, { useState } from 'react'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import useToken from '../../../Hooks/useToken'
import { sendCredentials } from '../actions'
import useAuth from '../../../Hooks/useAuth'
import useToast from '../../../Hooks/useToast'
import { LoadingComponent } from '../../../Components/Loading'

interface ConnectWinpharmaProps {
  // Add any props you need here
}

const ConnectWinpharma: React.FC<ConnectWinpharmaProps> = (props) => {
  // Add your component logic here
  const { loginUser } = useToken()
  const { setUser, user } = useAuth()
  const { showError } = useToast()

  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({
    pharmaId: '',
    login: '',
    password: '',
  })

  const handleDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsLoading(true)
    sendCredentials(loginUser, data.pharmaId, data.login, data.password).then(
      (res) => {
        console.log(res)
        if (res?.status === 404) {
          showError('Pharmacie non trouvée')
        } else {
          setUser({
            ...user,
            business: {
              ...user.business,
              dispensary: {
                ...user.business.dispensary,
                winpharmaId: data.pharmaId,
              },
            },
          })
        }
        setTimeout(() => {
          setIsLoading(false)
        }, 500)
      }
    )
    // Add your form submission logic here
  }

  return (
    <div>
      <div className="w-full px-3">
        <span className="title">
          <b>Connectez votre compte Winpharma</b>
        </span>
        <form
          className="login-form !w-full max-w-sm mx-auto"
          onSubmit={handleSubmit}
        >
          <FloatingLabel
            className="mb-1 login-form-label w-full"
            label="Numéro de pharmacie Winpharma"
            controlId="pharmaId"
          >
            <Form.Control
              type="text"
              autoComplete="off"
              placeholder="1234567890"
              className="login-form-input w-full"
              onChange={handleDataChange}
              name="pharmaId"
              value={data.pharmaId}
              required
            />
          </FloatingLabel>
          <FloatingLabel
            className="mb-1 login-form-label w-full"
            label="Identifiant Winpharma"
            controlId="login"
          >
            <Form.Control
              type="text"
              autoComplete="off"
              className="login-form-input w-full"
              onChange={handleDataChange}
              name="login"
              value={data.login}
              required
            />
          </FloatingLabel>
          <FloatingLabel
            className="mb-1 login-form-label w-full"
            label="Mot de passe Winpharma"
            controlId="password"
          >
            <Form.Control
              type="password"
              placeholder="password"
              autoComplete="off"
              onChange={handleDataChange}
              name="password"
              className="login-form-input w-full"
              required
            />
          </FloatingLabel>
          {isLoading ? (
            <Button
              variant="primary"
              size="lg"
              className="text-white text-white mt-3 mx-auto"
              disabled
            >
              <LoadingComponent size={20} color="white" />
            </Button>
          ) : (
            <Button
              variant="primary text-white mt-3 mx-auto"
              size="lg"
              type="submit"
            >
              Connecter
            </Button>
          )}
        </form>
      </div>
    </div>
  )
}

export default ConnectWinpharma
