import React from "react";
import {Modal, ModalHeader} from "react-bootstrap";
import Dropzone from 'react-dropzone'

type Props = {
    title: string;
    isOpen: boolean;
    handleClose: () => void;
    onUpload: (file: File) => void;
}


export const UploadModal: React.FC<Props> = (props: Props) => {
    return (
        <Modal show={props.isOpen} onHide={props.handleClose}>
            <ModalHeader closeButton>{props.title}</ModalHeader>
            <Modal.Body>
                <Dropzone onDrop={acceptedFiles => {
                    props.onUpload(acceptedFiles[0]);
                    props.handleClose()
                }}>
                    {({getRootProps, getInputProps}) => (
                        <section>
                            <div {...getRootProps()} style={{padding: "10vh"}}>
                                <input {...getInputProps()} />
                                <>Déposez vos fichiers ici, ou cliquez pour sélectionner dans votre navigateur de fichiers</>
                            </div>
                        </section>
                    )}
                </Dropzone>
            </Modal.Body>
        </Modal>
    )
};
