import {BasketContext} from "../../Contexts/basket/context";
import React, {useContext} from "react";
import HeaderLink from "./HeaderLink";
import {useMatch, useNavigate} from "react-router-dom";
import {Button, Card, Overlay} from "react-bootstrap";
import {Slugs} from "../../utils/constants";
import {FiShoppingCart} from "react-icons/fi";


export const HeaderCart = () => {
    const {state} = useContext(BasketContext);
    const navigate = useNavigate();

    const [isShowing, setShowing] = React.useState<boolean>(false);

    const match = useMatch({path: "/cart", end: true});

    const cartRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

    const handlePopoverButtonClick = () => {
        setShowing(false);
        navigate(Slugs.HOME);
    }

    const cartSize = 25;

    const isEmpty = state.basket === null || state.basket.basketProducts.length === 0 || state.basket.basketProducts.every(item => item.amount === 0);

    return isEmpty ? (
        <>
            <div className={(match ? '' : ' inactive ') + 'pl-header-link'} ref={cartRef}>
            <span onClick={() => window.innerWidth > 640 && setShowing(!isShowing)}>
                <FiShoppingCart className="pointer" size={cartSize}/>
            </span>
            </div>
            <Overlay show={isShowing} onHide={() => setShowing(false)} rootClose={true} target={cartRef.current} placement="bottom">
                <Card className="popover-cart">
                    <Card.Img variant="top" src={require("../../assets/images/empty_cart.png")} />
                    <Card.Text className="popover-content pt-3">Votre panier est vide...</Card.Text>
                    <Button variant="primary" className="popover-content text-white m-2" size="sm" onClick={handlePopoverButtonClick}>
                        Continuer mes achats
                    </Button>
                </Card>
            </Overlay>
        </>
    ) : (
        <HeaderLink to={Slugs.CART}>
            <span className="MuiBadge-root">
                <FiShoppingCart className="pointer" size={cartSize}/>
                <span className="MuiBadge-badge MuiBadge-colorError MuiBadge-anchorOriginTopRightRectangle">{state ? state.basketCount : 0}</span>

            </span>
        </HeaderLink>
    )
}
