import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { LoadingComponent } from '../../../Components/Loading'
import useToken from '../../../Hooks/useToken'
import {
  getOtherUser,
  patchBusiness,
  patchCatalogPresentationText,
} from '../actions'
import { useHandleError } from '../../../Hooks/useHandleError'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Form from 'react-bootstrap/Form'
import useAuth from '../../../Hooks/useAuth'
import { getUser } from '../../LoginView/actions'
import { UserInformation } from '../methods'
import { User } from '../../../Types/User'

type Props = {
  onHide: () => void
  information?: UserInformation
  businessId: number
  onEdit?: (user: User | string) => void
}

const ModalProfileEdit: React.FC<Props> = ({
  information,
  onHide,
  businessId,
  onEdit,
}) => {
  const [isLoading, setLoading] = React.useState(false)

  const [value, setValue] = React.useState('')

  const { loginUser } = useToken()
  const handleError = useHandleError()

  const { setUser, user: currentUser } = useAuth()

  const handleHide = () => {
    onHide()
    setValue('')
  }

  const handleSubmit = () => {
    if (information) {
      setLoading(true)
      const body = { id: businessId } as any
      if (information.id == 'presentationText') {
        patchCatalogPresentationText(loginUser, value)
          .then(() => {
            onEdit?.(value)
            getUser(loginUser.access_token)
              .then((user) => {
                if (businessId == currentUser.business.id) setUser(user)
              })
              .catch(handleError)
              .finally(handleHide)
          })
          .finally(() => setLoading(false))
      } else {
        body[information.id] = value
        patchBusiness(loginUser, body)
          .then((business) => {
            if (
              businessId == currentUser.business.id &&
              information.id == 'email'
            ) {
              alert('Votre email a été changé.\nMerci de vous reconnecter.')
              window.location.reload()
            }
            ;(businessId == currentUser.business.id
              ? getUser(loginUser.access_token)
              : getOtherUser(loginUser, business.user.id)
            )
              .then((user) => {
                if (businessId == currentUser.business.id) setUser(user)
                onEdit?.(user)
              })
              .catch(handleError)
              .finally(handleHide)
          })
          .catch(handleError)
          .finally(() => setLoading(false))
      }
    }
  }

  return (
    <Modal show={!!information} onHide={handleHide}>
      <Modal.Header closeButton>
        <Modal.Title>Modifier votre profil</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FloatingLabel
          className="mb-3 grey-form-label"
          label={information?.header || ''}
          controlId={information?.id || ''}
        >
          <Form.Control
            type="text"
            placeholder={information?.header || ''}
            aria-required
            name={information?.id || ''}
            className="grey-form-input"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </FloatingLabel>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleHide}>
          Annuler
        </Button>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <Button variant="primary" onClick={handleSubmit}>
            Sauvegarder
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default ModalProfileEdit
