import React from 'react'
import useAuth from "../../Hooks/useAuth";
import {Roles} from "../../Types/Role";
import DispensaryHomeView from "./Components/DispensaryHomeView";
import CatalogHomeView from "./Components/CatalogHomeView";
import {AccountValidation} from "../../Types/User";
import {Navigate} from "react-router-dom";
import {Slugs} from "../../utils/constants";
import AdminHomeView from "./Components/AdminHomeView";


const HomeView = () => {
  const {user} = useAuth();

  if (!user)
    return <></>;

  if (user.role === Roles.pharma) {
    if (user.adminValidated !== AccountValidation.VALIDATED) {
      return <Navigate to={Slugs.PROFILE} />;
    }
    return <DispensaryHomeView />
  }

  if (user.role === Roles.provider) {
    return <CatalogHomeView />
  }

  if (user.role === Roles.admin) {
    return <AdminHomeView />
  }

  return <></>;
};

export default HomeView
