import React, {ChangeEvent} from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import {FormStepProps} from "../../dispensary.index";
import {Button} from "react-bootstrap";


const FormNamePage: React.FC<FormStepProps> = ({handleChange, nextStep, values}) => {

    const [nameIsValid, setNameIsValid] = React.useState<boolean>(true);
    const checkIsNameValid = () => {
        if (values.name && values.name.length > 0) {
            return true;
        }
        setNameIsValid(false);
        return false;
    }

    const [firstNameIsValid, setFirstNameIsValid] = React.useState<boolean>(true);
    const checkIsFirstNameValid = () => {
        if (values.firstName && values.firstName.length > 0) {
            return true;
        }
        setFirstNameIsValid(false);
        return false;
    }

    const [emailIsValid, setEmailIsValid] = React.useState<boolean>(true);
    const checkIsEmailValid = () => {
        // Regex for email validation
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (values.email && values.email.length > 0 && emailRegex.test(values.email)) {
            return true;
        }
        setEmailIsValid(false);
        return false;
    }

    const [passwordIsValid, setPasswordIsValid] = React.useState<boolean>(true);
    const checkIsPasswordValid = () => {
        if (values.password && values.password.length > 6) {
            return true;
        }
        setPasswordIsValid(false);
        return false;
    }

    const [passwordConfirmationIsValid, setPasswordConfirmationIsValid] = React.useState<boolean>(true);
    const checkIsPasswordConfirmationValid = () => {
        if (values.passwordConfirmation && values.passwordConfirmation.length > 0 && values.passwordConfirmation === values.password) {
            return true;
        }
        setPasswordConfirmationIsValid(false);
        return false;
    }

    const handleNext = () => {
        const booleans = [checkIsNameValid(), checkIsFirstNameValid(), checkIsEmailValid(), checkIsPasswordValid(), checkIsPasswordConfirmationValid()];
        // If all booleans are true, we can go to the next step
        if (booleans.every(Boolean)) {
            nextStep();
        }
    }


        return (
        <>
            <FloatingLabel className={`mb-1 login-form-label`}
                           label={nameIsValid ? "Nom" : "Nom invalide"} controlId="name">
                <Form.Control
                    type="text"
                    placeholder="Nom"
                    autoComplete="off"
                    className="login-form-input"
                    name="name"
                    onChange={(e) => {
                        handleChange(e as ChangeEvent<HTMLInputElement>);
                        setNameIsValid(true);
                    }}
                    value={values.name}
                    isInvalid={!nameIsValid}
                    required
                />
            </FloatingLabel>
            <FloatingLabel className={`mb-1 login-form-label ${firstNameIsValid ? "" : "is-invalid"}`} label={firstNameIsValid ? "Prénom" : "Prénom invalide"} controlId="firstName">
                <Form.Control
                    type="text"
                    placeholder="Prénom"
                    autoComplete="off"
                    className="login-form-input"
                    name="firstName"
                    onChange={(e) => {
                        handleChange(e as ChangeEvent<HTMLInputElement>);
                        setFirstNameIsValid(true);
                    }}
                    value={values.firstName}
                    required
                    isInvalid={!firstNameIsValid}
                />
            </FloatingLabel>
            <FloatingLabel className={`mb-1 login-form-label ${emailIsValid ? "" : "is-invalid"}`} label={emailIsValid ? "Email" : "Email invalide"} controlId="email">
                <Form.Control
                    type="text"
                    placeholder="Email"
                    autoComplete="off"
                    className="login-form-input"
                    name="email"
                    onChange={(e) => {
                        handleChange(e as ChangeEvent<HTMLInputElement>);
                        setEmailIsValid(true);
                    }}
                    value={values.email}
                    required
                    isInvalid={!emailIsValid}
                />
            </FloatingLabel>
            <FloatingLabel className={`mb-1 login-form-label ${passwordIsValid ? "" : "is-invalid"}`} label={passwordIsValid ? "Mot de passe" : "Mot de passe trop court"} controlId="password">
                <Form.Control
                    type="password"
                    placeholder="Mot de passe"
                    autoComplete="off"
                    className="login-form-input"
                    name="password"
                    onChange={(e) => {
                        handleChange(e as ChangeEvent<HTMLInputElement>);
                        setPasswordIsValid(true);
                    }}
                    value={values.password}
                    required
                    isInvalid={!passwordIsValid}
                />
            </FloatingLabel>
            <FloatingLabel className={`mb-1 login-form-label ${passwordConfirmationIsValid ? "" : "is-invalid"}`} label={passwordConfirmationIsValid ? "Confirmer le mot de passe" : "Les mots de passe ne correspondent pas"}
                           controlId="passwordConfirmation">
                <Form.Control
                    type="password"
                    placeholder="Confirmation du mot de passe"
                    autoComplete="off"
                    className="login-form-input"
                    name="passwordConfirmation"
                    onChange={(e) => {
                        handleChange(e as ChangeEvent<HTMLInputElement>);
                        setPasswordConfirmationIsValid(true);
                    }}
                    value={values.passwordConfirmation}
                    required
                    isInvalid={!passwordConfirmationIsValid}
                />
            </FloatingLabel>

            <Button variant="primary"  onClick={handleNext}>
                Suivant
            </Button>

        </>
    );
}


export default FormNamePage;