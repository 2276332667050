import { Button, FloatingLabel, Form } from "react-bootstrap";
import React from "react";
import { AddCatalogDto } from '../actions';

type Props = {
    handleNext: (values: Partial<AddCatalogDto>) => void;
    inputs: any;
}

const FirstPageForm: React.FC<Props> = ({ handleNext, inputs }) => {

    const [businessName, setBusinessName] = React.useState<string>(inputs.businessName || "");
    const [businessAddress, setBusinessAddress] = React.useState<string>(inputs.businessAddress || "");
    const [businessCity, setBusinessCity] = React.useState<string>(inputs.businessCity || "");
    const [businessZipCode, setBusinessZipCode] = React.useState<string>(inputs.businessZipCode || "");
    const [siren, setSiren] = React.useState<string>(inputs.siren || "");

    const handleSubmit = () => {
        //Empty check on company name, to avoid having empty records
        if (businessName.trim() !== "") {
            //If other fields aren't inserted, assume their value is n/a (user can change via profile)
            handleNext({
                businessName,
                businessAddress: businessAddress || "n/a",
                businessCity: businessCity || "n/a",
                siren: siren || "n/a",
                businessZipCode: businessZipCode || "n/a"
            });
        }
    }

    return (
      <div>
          <div className={`p-2 ${businessName.trim() === "" ? 'border border-danger' : 'border border-success'}`} style={{ borderRadius: '8px' }}>
              <FloatingLabel
                className="mb-1 grey-form-label"
                label="Nom de l'entreprise (obligatoire)"
                controlId="businessName"
              >
                  <Form.Control
                    type="text"
                    className="grey-form-input"
                    placeholder="Nom de l'entreprise"
                    name="businessName"
                    value={businessName}
                    onChange={(e) => setBusinessName(e.target.value)}
                  />
              </FloatingLabel>
          </div>
          <div className="p-2">
              <FloatingLabel
                className="mb-1 grey-form-label"
                label="SIREN de l'entreprise"
                controlId="siren"
              >
                  <Form.Control
                    type="text"
                    className="grey-form-input"
                    placeholder="Siren de l'entreprise"
                    name="siren"
                    value={siren}
                    onChange={(e) => setSiren(e.target.value)}
                  />
              </FloatingLabel>
          </div>

          <div className="p-2">
              <FloatingLabel
                className="mb-1 grey-form-label"
                label="Adresse de l'entreprise"
                controlId="businessAddress"
              >
                  <Form.Control
                    type="text"
                    className="grey-form-input"
                    placeholder="Adresse de l'entreprise"
                    name="businessAddress"
                    value={businessAddress}
                    onChange={(e) => setBusinessAddress(e.target.value)}
                  />
              </FloatingLabel>
          </div>

          <div className="p-2 grid grid-cols-2 gap-3 w-full">
              <FloatingLabel
                className="mb-1 grey-form-label"
                label="Ville"
                controlId="businessCity"
              >
                  <Form.Control
                    type="text"
                    className="grey-form-input"
                    placeholder="Ville"
                    name="businessCity"
                    value={businessCity}
                    onChange={(e) => setBusinessCity(e.target.value)}
                  />
              </FloatingLabel>
              <FloatingLabel
                className="mb-1 grey-form-label"
                label="Code postal"
                controlId="businessZipCode"
              >
                  <Form.Control
                    type="text"
                    className="grey-form-input"
                    placeholder="Code postal"
                    name="businessZipCode"
                    value={businessZipCode}
                    onChange={(e) => setBusinessZipCode(e.target.value)}
                  />
              </FloatingLabel>
          </div>

          <div className="text-end">
              <Button className="text-white" variant="primary" onClick={handleSubmit}>
                  Suivant
              </Button>
          </div>
      </div>
    )
}

export default FirstPageForm;
