import React, { useState } from 'react'
import { FiArrowRight, FiArrowLeft } from 'react-icons/fi'
import { Form } from 'react-bootstrap'
import {
  BusinessConditionActionType,
  BusinessConditionContext,
} from '../../../../../Contexts/business-condition/context'
import { Provider } from '../../../../../Types/Provider'
import ConfirmationModal from '../ConfirmationModal'

type Props = {
  providers: Provider[]
  page: number
  totalPages: number
  handlePageChange: (page: number) => void
  isEdition: boolean
}

const ProvidersTable: React.FC<Props> = ({
  providers,
  page,
  totalPages,
  handlePageChange,
  isEdition,
}) => {
  const currentPageRangeMin = (page - 1) * 6 + 1
  const currentPageRangeMax = Math.min(page * 6 + 1, providers.length)

  return (
    <div className="ms-2 me-2 mt-2">
      <div className="row">
        <span className="col">Sélectionné</span>
        <span className="col-10">Marque</span>
      </div>
      {providers.map((provider) => (
        <div key={provider.id}>
          <ProviderRow provider={provider} isEdition={isEdition} />
        </div>
      ))}
      <div className="d-flex justify-content-end mt-2">
        <span>
          {page > 1 && (
            <FiArrowLeft
              onClick={() => handlePageChange(+page - 1)}
              className="ms-4"
            />
          )}
          {currentPageRangeMin} - {currentPageRangeMax} sur {totalPages}
          {totalPages > page && (
            <FiArrowRight
              onClick={() => handlePageChange(+page + 1)}
              className="ms-4"
            />
          )}
        </span>
      </div>
    </div>
  )
}
const ProviderRow: React.FC<{ provider: Provider; isEdition: boolean }> = ({
  provider,
  isEdition,
}) => {
  const { state, dispatch } = React.useContext(BusinessConditionContext)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleChecked = () => {
    if (
      isEdition &&
      state.provider?.id !== provider.id &&
      state.products.length > 0
    ) {
      setIsModalOpen(true)
    } else {
      handleProviderChange()
    }
  }

  const handleProviderChange = () => {
    if (isEdition) {
      dispatch({
        type: BusinessConditionActionType.UPDATE_PROVIDER,
        payload: provider,
      })
    } else {
      if (state.providers?.some((p) => p.id === provider.id)) {
        dispatch({
          type: BusinessConditionActionType.REMOVE_PROVIDER,
          payload: provider,
        })
      } else {
        dispatch({
          type: BusinessConditionActionType.ADD_PROVIDER,
          payload: provider,
        })
      }
    }
    if (isModalOpen) {
      setIsModalOpen(false)
    }
  }

  return (
    <>
      <div className="pharmacy-row row w-100 d-flex">
        <span className="col">
          <Form.Check
            type={isEdition ? 'radio' : 'checkbox'}
            checked={
              isEdition
                ? provider.id === state.provider?.id
                : state.providers?.some((p) => p.id === provider.id)
            }
            // checked={provider.id === state.provider?.id}
            onChange={toggleChecked}
          />
        </span>
        <span className="col-10">{provider.name}</span>
      </div>
      <ConfirmationModal
        onConfirm={handleProviderChange}
        onCancel={() => setIsModalOpen(false)}
        isLoading={false}
        header={"Confirmation d'édition de la marque"}
        body={
          'Si vous modifiez la marque liée à cette condition commerciale, tous les produits seront déselectionnés. Voulez-vous continuez?'
        }
        isOpen={isModalOpen}
      />
    </>
  )
}

export default ProvidersTable
