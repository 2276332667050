import React, { useEffect } from 'react'
import { getAllProvidersPaginated } from '../../actions'
import useToken from '../../../../Hooks/useToken'
import AdvancedSelect from './selected-pharmacies/AdvancedSelect'
import ProvidersTable from './selected-providers/ProvidersTable'
import { Provider } from '../../../../Types/Provider'
import {
  BusinessConditionActionType,
  BusinessConditionContext,
} from '../../../../Contexts/business-condition/context'

const SelectedProviders = ({ isEdition }: { isEdition: boolean }) => {
  const [providers, setProviders] = React.useState<Provider[]>([])

  const [currentPage, setCurrentPage] = React.useState<number>(1)
  const [totalPages, setTotalPages] = React.useState<number>(0)

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const { loginUser } = useToken()
  const { dispatch } = React.useContext(BusinessConditionContext)

  useEffect(() => {
    setCurrentPage(1)
    setProviders([])
    getAllProvidersPaginated(loginUser, {
      page: currentPage,
      pageSize: 6,
    }).then((r) => {
      setProviders(r.data)
      setTotalPages(Math.ceil(r.total / 6))
      setCurrentPage(r.page)

      if (!isEdition) {
        dispatch({
          type: BusinessConditionActionType.ADD_PROVIDER,
          payload: r.data[0],
        })
      }
    })
  }, [])

  return (
    <div className="text-start">
      <span className="section-title">Marque concernée</span>
      <ProvidersTable
        handlePageChange={handlePageChange}
        providers={providers}
        page={currentPage}
        totalPages={totalPages}
        isEdition={isEdition}
      />
    </div>
  )
}

export default SelectedProviders
