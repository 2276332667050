import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import ChangePassword from '../../Components/ChangePassword'
import ProfileInfo from '../../Components/Profile/ProfileInfo'
import { useAuth } from '../../Contexts/AuthProvider'
import { ProfileDocument } from '../../Components/Profile/ProfileDocument'
import { Roles } from '../../Types/Role'
import {
  downloadFile,
  getInfosForRole,
  handleDownloadCpsCard,
  handleDownloadIdCard,
  handleDownloadRibDocs,
  handleUploadCpsCard,
  handleUploadIdCard,
  handleUploadProviderCoverPhoto,
  handleUploadProviderLogo,
  handleUploadProviderPdfPresentation,
  handleUploadRibDocs,
  UserInformation,
} from './methods'
import useToken from '../../Hooks/useToken'
import { UploadModal } from '../../Components/Documents/UploadModal'
import { useNavigate } from 'react-router-dom'
import { Slugs } from '../../utils/constants'
import PreviewCoverPhotoModal from '../HomeView/Components/PreviewCoverPhotoModal'
import { FiEye } from 'react-icons/fi'
import { AccountValidation, User } from '../../Types/User'
import AccountNotValidatedBanner from './components/AccountNotValidatedBanner'
import ModalProfileEdit from './components/ModalProfileEdit'
import { getCatalog } from '../ShopView/actions'
import { Catalog } from '../../Types/Catalog'

function ProfileView() {
  const { user, setUser, signOut } = useAuth()
  const { loginUser } = useToken()
  const navigate = useNavigate()
  const infos = getInfosForRole(user)

  const [showFirstPage, setShowFirstPage] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [modalType, setModalType] = useState<{
    title: string
    handleUpload: Function
  }>({} as any)
  const [fieldEditing, setFieldEditing] = useState<UserInformation>()
  const [isChangingPassword, setIsChangingPassword] = useState<boolean>(false)

  const [catalog, setCatalog] = useState<Catalog>({} as Catalog)
  const handleLogout = () => {
    signOut()
    navigate(Slugs.LOGIN)
  }

  useEffect(() => {
    if (user.business?.catalog?.id)
      getCatalog(loginUser, user.business.catalog?.id?.toString()).then(
        setCatalog
      )
  }, [])

  return (
    <>
      <Card className="mb-3">
        {user.role === Roles.pharma &&
          user.adminValidated !== AccountValidation.VALIDATED && (
            <AccountNotValidatedBanner user={user} />
          )}
        <Card.Header>Infos utilisateur</Card.Header>
        <Card.Body className="text-center">
          <div className="flex flex-wrap gap-10">
            {infos.map((information) => (
              <Col className="sm-4 min-w-48" key={information.header}>
                <ProfileInfo
                  header={information.header}
                  info={information.info}
                  editable={information.isEditable}
                  handleEdit={() => setFieldEditing(information)}
                />
              </Col>
            ))}
            {user.role === Roles.pharma && (
              <>
                <Col className="mb-4 min-w-48">
                  <ProfileInfo
                    header="Document d'identité"
                    error={!user.business.dispensary.hasIdCardDocument}
                  >
                    <ProfileDocument
                      isExisting={user.business.dispensary.hasIdCardDocument}
                      handleDownload={() =>
                        Promise.resolve(handleDownloadIdCard(loginUser, user))
                      }
                      handleUpload={() => {
                        setModalType(idCardModalType)
                        setShowModal(true)
                      }}
                    />
                  </ProfileInfo>
                </Col>
                <Col className="mb-4 min-w-48">
                  <ProfileInfo
                    header="Carte CPS"
                    error={!user.business.dispensary.hasCpsCardDocument}
                  >
                    <ProfileDocument
                      isExisting={user.business.dispensary.hasCpsCardDocument}
                      handleDownload={() =>
                        Promise.resolve(handleDownloadCpsCard(loginUser, user))
                      }
                      handleUpload={() => {
                        setModalType(cpsCardModalType)
                        setShowModal(true)
                      }}
                    />
                  </ProfileInfo>
                </Col>
                <Col className="mb-4 min-w-48">
                  <ProfileInfo
                    header="Document RIB/IBAN"
                    error={!user.business.dispensary.hasRibDocument}
                  >
                    <ProfileDocument
                      isExisting={user.business.dispensary.hasRibDocument}
                      handleDownload={() =>
                        Promise.resolve(handleDownloadRibDocs(loginUser, user))
                      }
                      handleUpload={() => {
                        setModalType(ribDocsModalType)
                        setShowModal(true)
                      }}
                    />
                  </ProfileInfo>
                </Col>
              </>
            )}
            {user.role === Roles.provider && (
              <>
                <Col className="mb-4 min-w-48">
                  <ProfileInfo
                    header="Photo de couverture"
                    error={!user.business.catalog.hasCoverPhoto}
                  >
                    <ProfileDocument
                      isExisting={!!user.business.catalog.hasCoverPhoto}
                      handleUpload={() => {
                        setModalType(coverPhotoModalType)
                        setShowModal(true)
                      }}
                    >
                      <span className="pointer">
                        <FiEye onClick={() => setShowPreview(true)} />
                      </span>
                    </ProfileDocument>
                  </ProfileInfo>
                </Col>

                <Col className="mb-4 min-w-48">
                  <ProfileInfo header="PDF de présentation">
                    <ProfileDocument
                      isExisting={!!user.business.catalog.hasCoverPhoto}
                      handleUpload={() => {
                        setModalType(pdfPresentationType)
                        setShowModal(true)
                      }}
                    ></ProfileDocument>
                  </ProfileInfo>
                </Col>
                <Col className="mb-4 min-w-48">
                  <ProfileInfo header="Logo de présentation">
                    <ProfileDocument
                      isExisting={!!user.business.catalog.hasCoverPhoto}
                      handleUpload={() => {
                        setModalType(logoPresentationType)
                        setShowModal(true)
                      }}
                    ></ProfileDocument>
                  </ProfileInfo>
                </Col>
                <Col className="mb-4">
                  <ProfileInfo
                    header={'Texte de présentation'}
                    info={catalog.presentationText || ''}
                    editable={true}
                    handleEdit={() => {
                      setFieldEditing({
                        header: 'Texte de présentation',
                        info: catalog.presentationText || '',
                        isEditable: true,
                        id: 'presentationText',
                      })
                    }}
                  />
                </Col>
              </>
            )}
          </div>
        </Card.Body>
      </Card>

      {!showFirstPage && (
        <>
          {isChangingPassword ? (
            <ChangePassword />
          ) : (
            <div className="mt-5">
              <Button
                variant="primary"
                className="text-white me-2"
                onClick={() => setIsChangingPassword(true)}
              >
                Changer de mot de passe
              </Button>
              <Button
                variant="danger"
                className="text-white ms-2"
                onClick={handleLogout}
              >
                Se déconnecter
              </Button>
            </div>
          )}
        </>
      )}

      <UploadModal
        title={modalType.title}
        isOpen={showModal}
        handleClose={() => setShowModal(false)}
        onUpload={(file) =>
          Promise.resolve(
            modalType.handleUpload(loginUser, user, file, setUser)
          )
        }
      />
      {user.role === Roles.provider && (
        <PreviewCoverPhotoModal
          show={showPreview}
          onHide={() => setShowPreview(false)}
          catalog={catalog}
        />
      )}

      <ModalProfileEdit
        onHide={() => setFieldEditing(undefined)}
        information={fieldEditing}
        businessId={user.business.id}
        onEdit={(value) =>
          fieldEditing?.id === 'presentationText'
            ? setCatalog({
                ...catalog,
                presentationText: value as string,
              })
            : null
        }
      />
    </>
  )
}

const idCardModalType = {
  title: "Téléverser un document d'identité",
  handleUpload: handleUploadIdCard,
}

const cpsCardModalType = {
  title: 'Téléverser une carte CPS',
  handleUpload: handleUploadCpsCard,
}

const coverPhotoModalType = {
  title: 'Téléverser une photo de couverture pour votre entreprise',
  handleUpload: handleUploadProviderCoverPhoto,
}

const pdfPresentationType = {
  title:
    'Téléverser un fichier PDF pour présenter votre entreprise, celui-ci sera visible par les pharmacies',
  handleUpload: handleUploadProviderPdfPresentation,
}

const logoPresentationType = {
  title:
    'Téléverser un fichier Image correspondant à votre entreprise, celui-ci sera visible par les pharmacies',
  handleUpload: handleUploadProviderLogo,
}

const ribDocsModalType = {
  title: 'Téléverser un RIB de votre organisation',
  handleUpload: handleUploadRibDocs,
}

export default ProfileView

function OtherProfileView({
  user,
  onEdit,
}: {
  user: User
  onEdit: (user: User | string) => void
}) {
  const { loginUser } = useToken()
  const { user: thisUser } = useAuth()

  const [showPreview, setShowPreview] = React.useState<boolean>(false)
  const [fieldEditing, setFieldEditing] = React.useState<UserInformation>()

  const infos = useMemo(() => getInfosForRole(user), [user])

  const [catalog, setCatalog] = useState<Catalog>({} as Catalog)
  const downloadProviderCover = useCallback(() => {
    if (user.business?.catalog?.id)
      getCatalog(loginUser, user.business.catalog?.id.toString()).then(
        (catalog) => {
          {
            downloadFile(catalog.coverPhoto!)
            setCatalog(catalog)
          }
        }
      )
  }, [loginUser])

  return (
    <>
      <Card className="mb-3">
        {user.role === Roles.pharma &&
          user.adminValidated !== AccountValidation.VALIDATED && (
            <AccountNotValidatedBanner user={user} />
          )}
        <Card.Header>Infos utilisateur</Card.Header>
        <Card.Body className="text-center">
          <Row xs={1} sm={3} md={5} className="justify-content-center">
            {infos.map((information) => (
              <Col className="mb-4" key={information.header}>
                <ProfileInfo
                  header={information.header}
                  info={information.info}
                  editable={true}
                  handleEdit={() => setFieldEditing(information)}
                />
              </Col>
            ))}
            {user.role === Roles.pharma && (
              <>
                <Col className="mb-4">
                  <ProfileInfo
                    header="Document d'identité"
                    error={!user.business.dispensary.hasIdCardDocument}
                  >
                    <ProfileDocument
                      isExisting={user.business.dispensary.hasIdCardDocument}
                      handleDownload={() =>
                        Promise.resolve(
                          handleDownloadIdCard(loginUser, user, thisUser)
                        )
                      }
                    />
                  </ProfileInfo>
                </Col>
                <Col className="mb-4">
                  <ProfileInfo
                    header="Carte CPS"
                    error={!user.business.dispensary.hasCpsCardDocument}
                  >
                    <ProfileDocument
                      isExisting={user.business.dispensary.hasCpsCardDocument}
                      handleDownload={() =>
                        Promise.resolve(
                          handleDownloadCpsCard(loginUser, user, thisUser)
                        )
                      }
                    />
                  </ProfileInfo>
                </Col>
                <Col className="mb-4">
                  <ProfileInfo
                    header="RIB/IBAN"
                    error={!user.business.dispensary.hasRibDocument}
                  >
                    <ProfileDocument
                      isExisting={user.business.dispensary.hasRibDocument}
                      handleDownload={() =>
                        Promise.resolve(
                          handleDownloadRibDocs(loginUser, user, thisUser)
                        )
                      }
                    />
                  </ProfileInfo>
                </Col>
              </>
            )}

            {user.role === Roles.provider && (
              <>
                <Col className="mb-4">
                  <ProfileInfo
                    header="Photo de couverture"
                    error={!user.business.catalog.hasCoverPhoto}
                  >
                    <ProfileDocument
                      isExisting={!!user.business.catalog.hasCoverPhoto}
                      handleDownload={downloadProviderCover}
                    >
                      <span className="pointer">
                        <FiEye onClick={() => setShowPreview(true)} />
                      </span>
                    </ProfileDocument>
                  </ProfileInfo>
                </Col>
              </>
            )}
          </Row>
        </Card.Body>
      </Card>

      {user.role === Roles.provider && (
        <PreviewCoverPhotoModal
          show={showPreview}
          onHide={() => setShowPreview(false)}
          catalog={catalog}
        />
      )}

      <ModalProfileEdit
        onEdit={onEdit}
        onHide={() => setFieldEditing(undefined)}
        information={fieldEditing}
        businessId={user.business.id}
      />
    </>
  )
}

export { OtherProfileView }
