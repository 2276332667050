import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { User } from '../../../Types/User'
import { Roles } from '../../../Types/Role'
import {
  getOrders,
  getVerifiedAccounts,
  deleteAccount as deleteAccountRequest,
  lockAccount as lockAccountRequest,
  impersonateUser,
  updatePassword,
  updateCatalogPriority,
} from '../actions'
import useToken from '../../../Hooks/useToken'
import { useHandleError } from '../../../Hooks/useHandleError'
import '../index.scss'
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap'
import { OtherProfileView } from '../../ProfileView'
import { FiEye } from 'react-icons/fi'
import { Slugs } from '../../../utils/constants'

const AccountList = () => {
  //state
  const [accounts, setAccounts] = React.useState<User[]>([])
  const [orders, setOrders] = React.useState<
    { id: number; businessId: number; providerBusinessId: number }[]
  >([])
  const { loginUser } = useToken()
  const [modalProfile, setModalProfile] = useState<User>()
  const handleError = useHandleError()
  const providerAccounts = accounts.filter(
    (account) => account.role === Roles.provider
  )
  const priorities = Array.from(
    { length: providerAccounts.length + 1 },
    (_, i) => i
  )

  //behavior

  const handleRefresh = (loadOrders = false) => {
    getVerifiedAccounts(loginUser).then(setAccounts).catch(handleError)
    if (loadOrders) getOrders(loginUser).then(setOrders).catch(handleError)
  }

  useEffect(() => {
    handleRefresh(true)
  }, [])

  const renderSituation = (situation: number) => {
    switch (situation) {
      case Roles.admin:
        return 'Admin'
      case Roles.pharma:
        return 'Pharmacie'
      case Roles.provider:
        return 'Fournisseur'
      default:
        return 'Inconnu'
    }
  }

  const [approveModalShow, setApproveModalShow] = React.useState<boolean>(false)
  const [modalAction, setModalAction] = React.useState('')
  const [modalActionCallback, setModalActionCallback] =
    React.useState<() => void>()
  const [modalActionLoading, setModalActionLoading] = React.useState(false)

  const lockAccount = useCallback((account: User) => {
    setModalActionCallback(() => () => {
      setModalActionLoading(true)
      lockAccountRequest(loginUser, account.id, !account.locked)
        .then(() => setApproveModalShow(false))
        .finally(handleRefresh)
        .finally(() => setModalActionLoading(false))
    })
    setApproveModalShow(true)
    setModalAction(account.locked ? 'déverouiller' : 'verouiller')
  }, [])
  const deleteAccount = useCallback((account: User) => {
    setModalActionCallback(() => () => {
      setModalActionLoading(true)
      deleteAccountRequest(loginUser, account.id)
        .then(() => setApproveModalShow(false))
        .finally(handleRefresh)
        .finally(() => setModalActionLoading(false))
    })
    setApproveModalShow(true)
    setModalAction('supprimer')
  }, [])

  const [search, setSearch] = useState('')
  const searchedAccounts = useMemo(() => {
    return accounts.filter(
      (acc) =>
        `${acc.business.contactFirstName} ${acc.business.contactName}`
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        acc.email.toLowerCase().includes(search) ||
        orders.find(
          (order) =>
            [order.businessId, order.providerBusinessId].includes(
              acc.business.id
            ) && order.id == +search.trim()
        )
    )
  }, [search, accounts, orders])

  const { setToken } = useToken()

  const impersonate = (account: User) => {
    //TODO impersonate account
    impersonateUser(loginUser, account.id)
      .then(({ access_token }) => {
        setToken({ token: access_token })
        window.location.href = Slugs.HOME
      })
      .catch(handleError)
  }

  const handlePriorityChange = (id: number, newPriority: number) => {
    updateCatalogPriority(loginUser, id, newPriority)
      .then(() => {
        handleRefresh()
      })
      .catch(handleError)
  }

  const Accounts = useMemo(() => {
    if (searchedAccounts.length != 0) {
      return (
        <div className="mt-2">
          <div className="row p-2">
            <div className="col">Nom complet</div>
            <div className="col">Email</div>
            <div className="col hidden sm:block">Qualité</div>
            <div className="col hidden sm:block" />
            <div className="col hidden sm:block">Priorité</div>
          </div>
          {searchedAccounts.map((account) => (
            <div
              key={account.id}
              onClick={() => setModalProfile(account)}
              className="cursor-pointer"
            >
              <div className="flex justify-center sm:hidden">
                {renderSituation(account.role)}
              </div>
              <div className="row grey-bg-color rounded p-2 m-2 align-items-center hover:bg-gray-300">
                <div className="col">
                  {account.role == Roles.provider
                    ? account.business.businessName
                    : `${account.business.contactFirstName} ${account.business.contactName}`}
                </div>
                <div className="col">{account.email}</div>
                <div className="col hidden sm:block">
                  {renderSituation(account.role)}
                </div>

                <div
                  className="col flex items-center"
                  onClick={(e) => e.stopPropagation()}
                >
                  {account.role != Roles.provider && (
                    <button
                      onClick={() => lockAccount(account)}
                      className={`rounded bg-orange-600 hover:bg-orange-500 transition-colors text-white px-2 py-1 mr-1.5 ${
                        account.locked && 'bg-green-700 hover:bg-green-600'
                      }`}
                    >
                      {account.locked ? 'Déverrouiller' : 'Verouiller'}
                    </button>
                  )}
                  <button
                    onClick={() => deleteAccount(account)}
                    className="rounded bg-red-600 hover:bg-red-500 transition-colors text-white px-2 py-1"
                  >
                    Supprimer
                  </button>
                  {account.role != Roles.admin && (
                    <FiEye
                      onClick={() => impersonate(account)}
                      className="text-2xl text-green-600 ml-1.5"
                      title={`Visiter le compte de ${account.email}`}
                    />
                  )}
                </div>
                {account.role === Roles.provider && (
                  <div className="col hidden sm:block">
                    <Form.Select
                      value={account.business.catalog.priorityIndex || 0}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) =>
                        handlePriorityChange(
                          account.business.id,
                          Number(e.target.value)
                        )
                      }
                    >
                      {priorities.map((priority) => (
                        <option key={priority} value={priority}>
                          {priority}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )
    } else
      return (
        <div className="grey-bg-color rounded p-2 m-2 text-center">
          Aucun compte
        </div>
      )
  }, [searchedAccounts, handlePriorityChange])

  const onEdit = useCallback((user: User | string) => {
    if (typeof user == 'string') return
    setAccounts((accounts: User[]) =>
      accounts.map((account) => (account.id == user.id ? user : account))
    )
    setModalProfile((profile) => (profile?.id == user.id ? user : profile))
  }, [])

  const changeUserPassword = useCallback(async () => {
    if (!modalProfile?.id) return
    const password = prompt(
      "Entrez le nouveau mot de passe.\n\nN'écrivez rien pour ne pas changer le mot de passe."
    )
    if (password?.length) {
      try {
        await updatePassword(loginUser, modalProfile.id, password)
        alert('Mot de passe mis à jour.')
      } catch (e) {
        alert('Une erreur est survenue lors de la mise à jour du mot de passe.')
      }
    }
  }, [modalProfile?.id, loginUser])

  return (
    <div className="text-start">
      <span className="page-title">Liste des comptes</span>
      <FloatingLabel
        className="mb-1 login-form-label"
        label="Rechercher un compte..."
        controlId="search"
      >
        <Form.Control
          type="text"
          placeholder="Rechercher un compte..."
          autoComplete="off"
          className="login-form-input"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          required
        />
      </FloatingLabel>
      {Accounts}
      {modalProfile && (
        <Modal
          show
          onHide={() => setModalProfile(undefined)}
          dialogClassName="!w-[80%] !max-w-[80%]"
        >
          <OtherProfileView user={modalProfile} onEdit={onEdit} />
          <div className="mb-2">
            <Button
              variant="primary"
              className="text-white me-2"
              onClick={changeUserPassword}
            >
              Changer le mot de passe
            </Button>
          </div>
        </Modal>
      )}
      <Modal show={approveModalShow} onHide={() => setApproveModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalAction[0]?.toUpperCase() + modalAction.substring(1)} un compte
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <span>Êtes-vous sûr de vouloir {modalAction} ce compte ?</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setApproveModalShow(false)}
          >
            Annuler
          </Button>
          <Button variant="primary" onClick={modalActionCallback}>
            {modalActionLoading ? (
              <span className="text-neutral-600 animate-pulse">
                Chargement...
              </span>
            ) : (
              modalAction[0]?.toUpperCase() + modalAction.substring(1)
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default AccountList
