import React from "react";
import {Button, Modal} from "react-bootstrap";
import {approveAccount} from "../actions";
import useToken from "../../../Hooks/useToken";
import {useHandleError} from "../../../Hooks/useHandleError";
import {LoadingComponent} from "../../../Components/Loading";

type Props = {
    show: boolean,
    onHide: () => void,
    userId: number,
    fireRefresh: () => void,
}

const ApproveModal: React.FC<Props> = ({show, onHide, userId, fireRefresh}) => {

    const {loginUser} = useToken();

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const handleError = useHandleError();

    const handleSubmit = () => {
        approveAccount(loginUser, userId)
            .then(() => {
                fireRefresh();
                onHide()
            })
            .catch(handleError)
            .finally(() => setIsLoading(false));
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Approuver un compte</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <span>Êtes-vous sûr de vouloir approuver ce compte ?</span>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Annuler
                </Button>
                {isLoading ? (
                    <LoadingComponent />
                ) : (
                    <Button variant="primary" onClick={handleSubmit}>
                        Approuver
                    </Button>
                )}

            </Modal.Footer>
        </Modal>
    )
}


export default ApproveModal;