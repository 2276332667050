import React, {ChangeEvent} from "react";
import {FormStepProps} from "../../provider.index";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";


const FormDispensaryPage: React.FC<FormStepProps> = ({handleChange, nextStep, prevStep, values}) => {

    const [isProviderNameValid, setProviderNameIsValid] = React.useState(true);
    const checkIsProviderNameValid = () => {
        if (values.providerName && values.providerName.length > 0) {
            return true;
        }
        setProviderNameIsValid(false);
        return false;
    }

    const [isSirenValid, setSirenIsValid] = React.useState(true);
    const checkIsSirenValid = () => {
        const sirenRegex = /(\d{9}|\d{3}[ ]\d{3}[ ]\d{3})/;

        if (values.siren && values.siren.length > 0 && sirenRegex.test(values.siren)){
            return true;
        }
        setSirenIsValid(false);
        return false;
    }

    const [isPhoneValid, setIsPhoneValid] = React.useState(true);
    const checkIsPhoneValid = () => {
        // Regex for french phone validation
        const phoneRegex = /^(0)|(\+33)[1-9](\d{2}){4}$/;

        if (values.phoneNumber && values.phoneNumber.length > 0 && phoneRegex.test(values.phoneNumber)) {
            return true;
        }
        setIsPhoneValid(false);
        return false;
    }


    const handleNext = () => {
        const booleans = [checkIsProviderNameValid(),checkIsSirenValid(), checkIsPhoneValid()];
        if (booleans.every(Boolean)) {
            nextStep();
        }
    }

    return (
        <>
            <FloatingLabel className="mb-1 login-form-label" label={"Nom de Fournisseur " + (!isProviderNameValid ? "invalide" : "")} controlId="ProviderName">
                <Form.Control
                    type="text"
                    placeholder="Nom de fournisseur"
                    autoComplete="off"
                    className="login-form-input"
                    name="providerName"
                    onChange={(e) => {
                        handleChange(e as ChangeEvent<HTMLInputElement>);
                        setProviderNameIsValid(true);
                    }}
                    value={values.nameProvider}
                    required
                    isInvalid={!isProviderNameValid}
                />
            </FloatingLabel>
            <FloatingLabel className="mb-1 login-form-label" label={"Numéro SIREN " + (!isSirenValid ? "invalide" : "")} controlId="siren">
                <Form.Control
                    type="text"
                    placeholder="Numéro SIREN"
                    autoComplete="off"
                    className="login-form-input"
                    name="siren"
                    onChange={(e) => {
                        handleChange(e as ChangeEvent<HTMLInputElement>);
                        setSirenIsValid(true);
                    }}
                    value={values.siren}
                    required
                    isInvalid={!isSirenValid}
                />
            </FloatingLabel>
            <FloatingLabel className="mb-1 login-form-label" label={"Numéro de téléphone " + (!isPhoneValid ? "invalide" : "") } controlId="phoneNumber">
                <Form.Control
                    type="text"
                    placeholder="Numéro de téléphone"
                    autoComplete="off"
                    className="login-form-input"
                    name="phoneNumber"
                    onChange={(e) => {
                        handleChange(e as ChangeEvent<HTMLInputElement>);
                        setIsPhoneValid(true);
                    }}
                    value={values.phoneNumber}
                    required
                    isInvalid={!isPhoneValid}
                />
            </FloatingLabel>
            <div className="w-100 d-flex justify-content-end">
                <button className="btn btn-secondary me-4" type="button" onClick={prevStep}>Précédent</button>
                <button className="btn btn-primary" type="button" onClick={handleNext}>Suivant</button>
            </div>
        </>

    );
}


export default FormDispensaryPage;