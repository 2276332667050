import React from 'react'
import DatePicker from 'react-date-picker'
import { Button } from 'react-bootstrap'
import {
  BasketProductWithDiscounts,
  BasketWithDiscounts,
} from '../../../Types/Basket'
import { addBusinessDays } from '../../../utils/helpers'
import { User } from '../../../Types/User'
import { useAuth } from '../../../Contexts/AuthProvider'
import Subtotal from './Subtotal'
import { getBusinessConditionSteps } from '../../ShopView/actions'
import { useHandleError } from '../../../Hooks/useHandleError'
import useToken from '../../../Hooks/useToken'

type Props = {
  handleSubmit: () => void
  date: Date
  setDate: (value: Date) => void
  basket: BasketWithDiscounts
}
type ProviderSubtotals = { [providerName: string]: string }
function GetUser(): User | null {
  const { user } = useAuth()
  return user
}

const UserDispensaryIbanChecker: React.FC = () => {
  const user = GetUser()

  if (
    !user ||
    !user.business ||
    !user.business.dispensary ||
    !user.business.dispensary.dispensaryIban ||
    user.business.dispensary.dispensaryIban.trim() === ''
  ) {
    return (
      <div>
        Il semblerait que vous n'ayez pas enregistré d'IBAN. La commande sera
        verrouillée tant que vous n'avez pas ajouté un IBAN depuis votre profil.
      </div>
    )
  }

  return <div>IBAN associé: {user.business.dispensary.dispensaryIban}</div>
}

export const CheckoutTab: React.FC<Props> = (props) => {
  const user = GetUser()
  const userDispensaryIban = user?.business?.dispensary?.dispensaryIban
  const isIbanEmpty = userDispensaryIban?.trim() === ''
  const handleError = useHandleError()
  const { loginUser } = useToken()
  const [minOrderAmounts, setMinOrderAmounts] = React.useState<{
    [providerName: string]: number
  }>({})

  const [subtotals, setSubtotals] = React.useState<ProviderSubtotals>({})
  const [allOrdersValid, setAllOrdersValid] = React.useState<boolean>(true)

  // Update the subtotals, minimum order amounts, and allOrdersValid when the basket changes
  React.useEffect(() => {
    // Group the basket products by their provider
    const providerGroups: {
      [providerName: string]: BasketProductWithDiscounts[]
    } = {}
    props.basket.basketProducts.forEach((product) => {
      const providerName = product.product.provider.name
      if (!providerGroups[providerName]) {
        providerGroups[providerName] = []
      }
      providerGroups[providerName].push(product)
    })

    // Calculate the subtotal for each provider
    const newSubtotals: ProviderSubtotals = {}
    Object.values(providerGroups).forEach((products) => {
      const subtotal = products.reduce(
        (sum, product) => sum + parseFloat(product.reducedPrice),
        0
      )
      newSubtotals[products[0].product.provider.name] = subtotal.toFixed(2)
    })
    setSubtotals(newSubtotals)

    // Retrieve the minimum order amount for each provider
    const promises = Object.values(providerGroups).map((products) => {
      const providerId = products[0].product.provider.id
      return getBusinessConditionSteps(loginUser, providerId.toString())
        .then((bcSteps) => {
          // Find the minimum order amount for the provider
          const minOrderAmount = bcSteps.reduce((min, bcStep) => {
            return Math.min(min, bcStep.minimumOrderAmount)
          }, 0)

          return {
            providerName: products[0].product.provider.name,
            minOrderAmount,
          }
        })
        .catch(handleError)
    })

    Promise.all(promises).then((results) => {
      const newMinOrderAmounts: { [providerName: string]: number } = {}
      console.log('results', results)
      results.forEach((result) => {
        if (result) {
          newMinOrderAmounts[result.providerName] = result.minOrderAmount
        }
      })
      setMinOrderAmounts(newMinOrderAmounts)

      // Check if all providers have met their minimum order amount
      const allProvidersMet = Object.entries(newMinOrderAmounts).every(
        ([providerName, minOrderAmount]) => {
          const providerSubtotal = newSubtotals[providerName]
          return parseFloat(providerSubtotal) >= minOrderAmount
        }
      )
      setAllOrdersValid(allProvidersMet)
    })
  }, [props.basket])

  console.log(allOrdersValid)
  console.log(subtotals)
  console.log(minOrderAmounts)

  return (
    <>
      <div className="text-start checkout-container">
        <div className="d-flex flex-column">
          <span className="tab-title mb-3">Date de livraison</span>
          <DatePicker
            onChange={props.setDate}
            value={props.date}
            className="date-picker"
            minDate={addBusinessDays(new Date(), 5)} // We cannot deliver before 5 business days
          />
        </div>
        <div className="mt-4 d-flex flex-column">
          <span className="tab-title mb-2">Total</span>

          <div className="flex items-center">
            {props.basket.reducedPrice < props.basket.totalPrice && (
              <span className="text-neutral-500/80 text-xl line-through mr-2">
                {props.basket.totalPrice}€
              </span>
            )}
            <span className="total-price">{props.basket.reducedPrice}€</span>
          </div>
          <div>
            <div>
              <u>Détail des prix :</u>
            </div>
            {Object.entries(subtotals).map(([providerName, subtotal]) => {
              const minOrderAmount = minOrderAmounts[providerName]

              const subtotalMet = minOrderAmount
                ? parseFloat(subtotal) >= minOrderAmount
                : true
              return (
                <div
                  key={providerName}
                  className={`d-flex justify-content-between mb-2 ${
                    subtotalMet ? '' : 'subtotal-not-met'
                  }`}
                >
                  <div>
                    <span className="text-muted small">{providerName}</span>
                    {!subtotalMet && (
                      <span className="text-danger small italic ml-1">
                        (Franco non atteint)
                      </span>
                    )}
                  </div>
                  <span className="text-muted small">{subtotal}€</span>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <UserDispensaryIbanChecker />

      <Button
        disabled={!userDispensaryIban || isIbanEmpty || !allOrdersValid}
        className="order-button mt-5 shadow"
        size="lg"
        onClick={props.handleSubmit}
      >
        Commander
      </Button>
    </>
  )
}
