import React from 'react'
import { Form } from 'react-bootstrap'
import { FiEdit } from 'react-icons/fi'

const ProfileInfo = (props: {
  header: string | React.ReactNode
  info?: any
  editable?: boolean
  children?: React.ReactNode
  error?: boolean
  handleEdit?: () => void
}) => (
  <>
    <Form.Group className="text-start text-nowrap ">
      <div className="d-flex">
        <Form.Label
          className={`text-truncate text-nowrap mb-0 ${
            props.error ? 'text-danger' : ''
          }`}
        >
          {props.header}
        </Form.Label>
        {props.editable && (
          <span className="ms-3 pointer" onClick={props.handleEdit}>
            <FiEdit />
          </span>
        )}
      </div>
      {props.children || (
        <span title={props.info}>
          <Form.Control
            size="lg"
            type="text"
            value={props.info}
            readOnly
            disabled
            plaintext
          />
        </span>
      )}
    </Form.Group>
  </>
)

export default ProfileInfo
