import React from "react";
import {Toast, ToastContainer} from "react-bootstrap";
import {ToastContext} from "./context";
import {FiX} from "react-icons/fi";

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {


    const [message, setMessage] = React.useState<{ message: string, type: string } | null>(null);

    const showError = (message: string) => {
        setMessage({message, type: "danger"});
    }

    const showSuccess = (message: string) => {
        setMessage({message, type: "success"});
    }

    const value = {showError, showSuccess};

    return (
        <ToastContext.Provider value={value}>
            {children}
            <ToastContainer position="bottom-end" className="m-5">
                <Toast onClose={() => setMessage(null)}
                       show={!!message} delay={3000}
                       autohide bg={message?.type || "danger"}>
                    <div className="d-flex justify-content-between m-2 text-white">
                        <div className="text-start" style={{fontSize: "1.1rem"}}>
                            <strong className="mr-auto">{message?.message}</strong>
                        </div>
                        <div>
                            <FiX className="text-white pointer" onClick={() => setMessage(null)}/>
                        </div>
                    </div>
                </Toast>
            </ToastContainer>
        </ToastContext.Provider>
    );

}