import React from 'react'
import ProgressBarPage from '../SignUpView/components/ProgressBarPage'
import FirstPageForm from './components/FirstPageForm'
import SecondPageForm from './components/SecondPageForm'
import { addCatalog, AddCatalogDto } from './actions'
import useToken from '../../Hooks/useToken'
import { useHandleError } from '../../Hooks/useHandleError'
import ThirdPageForm from './components/ThirdPageForm'
import useToast from '../../Hooks/useToast'
import { useNavigate } from 'react-router-dom'
import { Slugs } from '../../utils/constants'


const AddCatalogView = () => {

  const [currentStep, setCurrentStep] = React.useState(0)

  const [inputs, setInputs] = React.useState<Partial<AddCatalogDto>>({
  })

  const { loginUser } = useToken()
  const handleError = useHandleError()
  const { showSuccess } = useToast()
  const navigate = useNavigate()

  const updateInputs = (values: Partial<AddCatalogDto>) => {
    if (inputs) {
      setInputs({ ...inputs, ...values })
    } else {
      setInputs(values)
    }
  }

  const handleNext = (values: Partial<AddCatalogDto>) => {
    updateInputs(values)
    setCurrentStep(currentStep + 1)
  }

  const handleSubmit = (values: Partial<AddCatalogDto>) => {
    if (inputs) {
      updateInputs(values)
      setInputs(inputs => {
        addCatalog(loginUser, inputs as AddCatalogDto)
          .then(() => {
            showSuccess('Le fournisseur a été ajouté avec succès')
            setCurrentStep(0)
            setInputs({})
            navigate(Slugs.AJOUTER_CATALOGUE)
          })
          .catch(handleError)

        return inputs
      })
    }
  }

  return (
    <div className='w-100 d-flex justify-content-center'>
      <div className='shadow rounded p-4 w-[450px]'>
        <h1 className='page-title text-center'>Ajouter un catalogue</h1>
        <ProgressBarPage currentStep={currentStep} totalSteps={3} />
        <div className='m-4' />
        {currentStep === 0 && <FirstPageForm handleNext={handleNext} inputs={inputs} />}
        {currentStep === 1 &&
          <SecondPageForm handleNext={handleNext} handlePrevious={() => setCurrentStep(0)} inputs={inputs} />}
        {currentStep === 2 && <ThirdPageForm handleNext={handleSubmit} handlePrevious={() => setCurrentStep(1)} />}

      </div>
    </div>
  )
}


export default AddCatalogView