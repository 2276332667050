import {httpErrorHandler} from "../utils/errors";
import useToast from "./useToast";
import {useNavigate} from "react-router-dom";


export const useHandleError = () => {
    const {showError} = useToast();
    const navigate = useNavigate();

    return httpErrorHandler(showError, navigate)
}