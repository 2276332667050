import React, { useEffect, useMemo, useState } from 'react'
import { Pagination } from 'react-bootstrap'
import {
  BasketProduct,
  BasketProductWithDiscounts,
} from '../../../Types/Basket'
import { ProductCard } from './ProductCard'
import { Catalog } from '../../../Types/Catalog'
import { Roles } from '../../../Types/Role'
import {
  getBusinessConditionSteps,
  getItemsBusinessConditionSteps,
} from '../../ShopView/actions'
import {
  BusinessConditionStep,
  ProductBusinessConditionStep,
} from '../../../Types/BusinessCondition'
import { useHandleError } from '../../../Hooks/useHandleError'
import useToken from '../../../Hooks/useToken'
import useAuth from '../../../Hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { Slugs } from '../../../utils/constants'

const PaginationBar: React.FC<{
  handleChange: (a: number) => void
  basketProducts: BasketProduct[]
  active: number
}> = (props) => {
  const getItems = useMemo(() => {
    const numberOfItems = props.basketProducts.length / 6
    return Array.from({ length: Math.ceil(numberOfItems) }, (x, i) => i)
  }, [props.basketProducts.length])

  useEffect(() => {
    if (getItems.length - 1 < props.active) props.handleChange(props.active - 1)
  }, [getItems.length, props.active, props.handleChange])

  if (getItems.length === 1) return <></>

  return (
    <>
      <div className="mb-1">Page&nbsp;</div>
      <Pagination>
        {getItems.map((n) => (
          <Pagination.Item
            key={n}
            onClick={() => props.handleChange(n)}
            active={n === props.active}
          >
            {n}
          </Pagination.Item>
        ))}
      </Pagination>
    </>
  )
}

export const ProductTabProvider = (props: {
  allBasketProducts: BasketProductWithDiscounts[]
  catalog: Catalog
}) => {
  const navigate = useNavigate()
  const catalogsBasketProducts = useMemo(
    () =>
      props.allBasketProducts.filter(
        (product) =>
          product.product.provider?.catalog?.id === props.catalog.id ||
          product.product.businessId === props.catalog.business.id
      ),
    [props.allBasketProducts, props.catalog.id, props.catalog.business.id]
  )

  const [bcSteps, setBcSteps] = React.useState<BusinessConditionStep[]>([])

  const handleError = useHandleError()
  const { loginUser } = useToken()
  const { user } = useAuth()

  const [page, setPage] = useState<number>(0)

  const totalBasketProducts = useMemo(
    () => catalogsBasketProducts?.filter((b) => b.amount > 0) ?? [],
    [catalogsBasketProducts]
  )
  const basketProducts = useMemo(
    () => totalBasketProducts.slice(page * 6, page * 6 + 6),
    [totalBasketProducts, page]
  )
  const provider = basketProducts[0].product.provider
  useEffect(() => {
    if (user.role === Roles.pharma) {
      getBusinessConditionSteps(loginUser, provider.id.toString())
        .then((res) => setBcSteps(res))
        .catch(handleError)
    }
  }, [])
  return (
    <div>
      <div className="w-full flex items-center mt-5 mb-3">
        <div className="w-1/2 h-0.5 bg-neutral-200" />
        <div className="flex-shrink-0 mx-3 flex flex-col items-center">
          <span
            className="text-lg text-primary hover:font-bold pointer"
            onClick={() =>
              navigate(`${Slugs.SHOP}/${props.catalog.id}${Slugs.INFO}`)
            }
          >
            {props.catalog.business.businessName}
          </span>
          <div className="text-sm mt-1">
            {bcSteps.map((step) => (
              <div key={step.id}>
                Franco de port = <b>{step.minimumOrderAmount}€</b> HT remisé (-
                <b>{step.discountRate}%</b>)
              </div>
            ))}
          </div>
        </div>
        <div className="w-1/2 h-0.5 bg-neutral-200" />
      </div>
      <PaginationBar
        active={page}
        handleChange={(number) => setPage(number)}
        basketProducts={totalBasketProducts}
      />

      {basketProducts && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {basketProducts.map((bp) => (
            <div key={bp.id}>
              <ProductCard basketProduct={bp} />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export const ProductTab = (props: {
  basketProducts: BasketProductWithDiscounts[]
  catalogs?: Catalog[]
}) => {
  return (
    <>
      <div>
        <span className="tab-title">Articles</span>
        <div>
          {props.catalogs?.map((catalog) => (
            <ProductTabProvider
              key={catalog.id}
              allBasketProducts={props.basketProducts}
              catalog={catalog}
            />
          ))}
        </div>
      </div>
    </>
  )
}
