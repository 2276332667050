import { get } from "../../Api/api";
import {LoginUser} from "../../Types/User";
import {Provider} from "../../Types/Provider";
import { ProductWithSteps } from "../../Types/Product";
import { Catalog } from '../../Types/Catalog'

export type ProviderStats = {
    orderCount: number
    orderTotalPrice: number
    orderAveragePrice: number
    orderShopVisitCount: number
}

export type AdminStats = {
    orderCount: number
    orderTotalPrice: number
    orderAveragePrice: number
    orderLastMonthCount: number
    providerCount: number
    dispensaryCount: number
    waitingForValidationAccountCount: number
    waitingForValidationDispensaryCount: number
    waitingForValidationProviderCount: number
}

export const getAllCatalogs = (login: LoginUser) => {
    return get<Catalog[]>("/catalog", login)
}

export const getAllProviders = (login: LoginUser) => {
    return get<Provider[]>("/provider", login)
}


export const getProviderStats = (login: LoginUser) => {
    return get<ProviderStats>("/provider/stats", login)
}

export const getAdminStats = (login: LoginUser) => {
    return get<AdminStats>("/admin/stats", login)
}

export const searchProduct = (login: LoginUser, search: string) => {
    return get<ProductWithSteps[]>(`/product/dispensary/search/${encodeURIComponent(search)}`, login)
}
