import { LoginUser } from '../../Types/User'
import { get, post } from '../../Api/api'
import { Order } from '../../Types/Order'

export const sendCredentials = (
  loginUser: LoginUser,
  num_pharma: string,
  login: string,
  password: string
) => {
  return post<any>('/dispensary/apikey', loginUser, {
    num_pharma: num_pharma,
    login: login,
    password: password,
  })
}

export const getStock = (loginUser: LoginUser) => {
  return get<any>('/dispensary/stock', loginUser)
}

export const refreshStock = (loginUser: LoginUser) => {
  return post<any>('/dispensary/stock', loginUser, null)
}
