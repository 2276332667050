import React, {ChangeEvent, useEffect, useState} from "react";
import {FormStepProps} from "../../dispensary.index";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { Typeahead } from "react-bootstrap-typeahead";
import { fetchGroupements } from "../../actions";
import { useHandleError } from "../../../../Hooks/useHandleError";


const FormDispensaryPage: React.FC<FormStepProps> = ({handleChange, nextStep, prevStep, values}) => {

    const [isRPPSValid, setIsRPPSValid] = React.useState(true);
    const checkIsRPPSValid = () => {
        if (values.rpps && values.rpps.length > 0) {
            return true;
        }
        setIsRPPSValid(false);
        return false;
    }

    const handleError = useHandleError()
    const [groupements, setGroupements] = useState<string[]>([]);
    const [loadingGroupements, setLoadingGroupements] = useState(true);
    useEffect(() => {
        fetchGroupements().then(groupements => {
            setGroupements(groupements)
            setLoadingGroupements(false)
        }).catch(handleError)
    }, [])

    const [isPhoneValid, setIsPhoneValid] = React.useState(true);
    const checkIsPhoneValid = () => {
        // Regex for french phone validation
        const phoneRegex = /^(0)|(\+33)[1-9](\d{2}){4}$/;

        if (values.phoneNumber && values.phoneNumber.length > 0 && phoneRegex.test(values.phoneNumber)) {
            return true;
        }
        setIsPhoneValid(false);
        return false;
    }

    const [isBusinessNameValid, setIsBusinessNameValid] = React.useState<boolean>(true);
    const checkIsBusinessNameValid = () => {
        if (values.businessName && values.businessName.length > 0) {
            return true;
        }
        setIsBusinessNameValid(false);
        return false;
    }

    const [isNetworkNameValid, setIsNetworkNameValid] = React.useState<boolean>(true);
    const checkIsNetworkNameValid = () => {
        if (values.dispensaryNetwork && values.dispensaryNetwork.length > 0) {
            return true;
        }
        setIsNetworkNameValid(false);
        return false;
    }

    const handleNext = () => {
        const booleans = [checkIsRPPSValid(), checkIsPhoneValid(), checkIsBusinessNameValid(), checkIsNetworkNameValid()];
        if (booleans.every(Boolean)) {
            if(!groupements.includes(values.dispensaryNetwork)){
                if(!window.confirm("Êtes-vous sûr que votre groupement n'apparaît pas dans la liste des groupements existants ?\nVérifiez que vous n'avez pas fait d'erreur de frappe.\n\nS'il n'apparaît pas dans la liste, cliquez sur \"Ok\"."))
                    return;
            }
            nextStep();
        }
    }

    return (
        <>
            <FloatingLabel className="mb-1 login-form-label" label={"Numéro RPPS " + (!isRPPSValid ? "invalide" : "")} controlId="rpps">
                <Form.Control
                    type="text"
                    placeholder="Numéro RPPS"
                    autoComplete="off"
                    className="login-form-input"
                    name="rpps"
                    onChange={(e) => {
                        handleChange(e as ChangeEvent<HTMLInputElement>);
                        setIsRPPSValid(true);
                    }}
                    value={values.rpps}
                    required
                    isInvalid={!isRPPSValid}
                />
            </FloatingLabel>
            <FloatingLabel className="mb-1 login-form-label" label={"Numéro de téléphone " + (!isPhoneValid ? "invalide" : "") } controlId="phoneNumber">
                <Form.Control
                    type="text"
                    placeholder="Numéro de téléphone"
                    autoComplete="off"
                    className="login-form-input"
                    name="phoneNumber"
                    onChange={(e) => {
                        handleChange(e as ChangeEvent<HTMLInputElement>);
                        setIsPhoneValid(true);
                    }}
                    value={values.phoneNumber}
                    required
                    isInvalid={!isPhoneValid}
                />
            </FloatingLabel>
            <FloatingLabel className="mb-1 login-form-label" label={"Nom de la pharmacie " + (!isBusinessNameValid ? "invalide" : "")} controlId="businessName">
                <Form.Control
                    type="text"
                    placeholder="Nom de la pharmacie"
                    autoComplete="off"
                    className="login-form-input"
                    name="businessName"
                    onChange={(e) => {
                        handleChange(e as ChangeEvent<HTMLInputElement>);
                        setIsBusinessNameValid(true);
                    }}
                    value={values.businessName}
                    required
                    isInvalid={!isBusinessNameValid}
                />
            </FloatingLabel>

            <div>
                <span className="mb-0.5 ml-0.5">{"Nom du groupement " + (!isNetworkNameValid ? "invalide" : "")}</span>
                <Typeahead
                    placeholder="Cherchez un groupement..."
                    inputProps={{className: "login-form-input !py-3", name: "dispensaryNetwork"}}
                    onInputChange={(text, e) => {
                        handleChange(e as any);
                        setIsNetworkNameValid(true);
                    }}
                    onChange={(selected) => {
                        values.dispensaryNetwork = (selected[0] as Record<string, any>)?.label ?? (selected[0] as string) ?? "";
                        setIsNetworkNameValid(true);
                    }}
                    defaultInputValue={values.dispensaryNetwork}
                    isInvalid={!isNetworkNameValid}
                    options={groupements}
                    isLoading={loadingGroupements}
                    allowNew
                    newSelectionPrefix="Nouveau groupement : "
                    emptyLabel="Recherche..."
                />
            </div>
            <div className="w-100 d-flex justify-content-end">
                <button className="btn btn-secondary me-4" type="button" onClick={prevStep}>Précédent</button>
                <button className="btn btn-primary" type="button" onClick={handleNext} disabled={loadingGroupements}>Suivant</button>
            </div>
        </>

    );
}


export default FormDispensaryPage;