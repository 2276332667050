import React, {useCallback, useEffect, useState} from 'react';
import './style.scss';
import {BusinessCondition} from "../../Types/BusinessCondition";
import BusinessConditionDetail from "./components/BusinessConditionDetail";
import {FiPlusCircle} from "react-icons/fi";
import {getBusinessConditions} from "./actions";
import useToken from "../../Hooks/useToken";
import {NavLink, useNavigate} from "react-router-dom";
import {Slugs} from "../../utils/constants";
import {useHandleError} from "../../Hooks/useHandleError";
import { Button } from 'react-bootstrap';
import { LightDispensary } from './components/create-condition-components/selected-pharmacies/PharmacyRow';

const BusinessConditionsView: React.FC<{pharmacy?: LightDispensary, loadedBusinessConditions?: BusinessCondition[]}> = ({pharmacy, loadedBusinessConditions}) => {

    const [fetchedBusinessConditions, setFetchedBusinessConditions] = useState<BusinessCondition[]>();
    const [businessConditions, setBusinessConditions] = useState<BusinessCondition[]>([]);

    const {loginUser} = useToken();
    const navigate = useNavigate();

    const handleError = useHandleError();

    const fetchBusinessConditions = useCallback(() => {
        getBusinessConditions(loginUser)
            .then(setFetchedBusinessConditions)
            .catch(handleError);
    }, [pharmacy?.id, loginUser.access_token])

    useEffect(() => {
        setBusinessConditions((fetchedBusinessConditions ?? loadedBusinessConditions)?.filter(bc => !pharmacy || bc.networks.includes(pharmacy.dispensaryNetwork) || bc.dispensaries.find(other => other.id == pharmacy.id)) ?? [])
    }, [fetchedBusinessConditions, pharmacy?.id, loadedBusinessConditions])

    useEffect(fetchBusinessConditions, [fetchBusinessConditions]);

    return (
        <>
            <div className="text-start mb-4 d-flex flex-row justify-content-start align-items-center ">
                <span className="page-title leading-10">Conditions commerciales{pharmacy && <> appliquées à {pharmacy.business.businessName}</>}</span>
                {!pharmacy && <span className="mt-4 ms-3 pointer" onClick={() => navigate(Slugs.BUSINESS_CONDITIONS_CREATE)}>
                    <FiPlusCircle  color="#5ecc78" size={35} />
                </span>}
            </div>
            {!pharmacy && <div className="w-full text-left">
                <NavLink to={Slugs.DISPENSERS}>
                    <Button size="sm" className="text-white mb-2">Liste des pharmacies</Button>
                </NavLink>
            </div>}
            <div className="w-100">
                {
                    businessConditions.length ? (businessConditions.map(businessCondition => (
                        <BusinessConditionDetail key={businessCondition.id} businessCondition={businessCondition} onDelete={fetchBusinessConditions} />
                    ))) : (
                        <div className="text-center">
                            <span className="text-muted">Aucune condition commerciale</span>
                        </div>
                    )
                }
            </div>
        </>
    );
}


export default BusinessConditionsView;