import React from 'react'
import {NavLink} from 'react-router-dom'
import './style.scss'
import {Slugs} from "../../../utils/constants";

function Footer() {
  return (
    <footer>
      <div>
        <NavLink to={Slugs.LEGAL_MENTIONS} className="footer-links">Mentions légales</NavLink>
        <NavLink to={Slugs.CONDIFENCIALITY} className="footer-links">Politique de confidentionnalité</NavLink>
        <NavLink to={Slugs.CGU} className="footer-links">CGU</NavLink>
        <NavLink to={Slugs.SALES_TERMS} className="footer-links">CGV</NavLink>
        <NavLink to={Slugs.CONTACT} className="footer-links">Nous contacter</NavLink>
        {/* <NavLink to={Slugs.WHO_WE_ARE} className="footer-links">Qui sommes-nous?</NavLink> */}
      </div>
    </footer>
  )
}

export default Footer
