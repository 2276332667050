import React from "react";
import {Button} from "react-bootstrap";
import CreateAdminAccountModal from "./CreateAdminAccountModal";

const AdminAccountCreate = () => {

    const [show, setShow] = React.useState(false);

    return (
        <div className="text-start">
            <span className="page-title">Administrateurs</span>
            <div>
                <Button className="text-white" onClick={() => setShow(true)}>Créer un compte administrateur</Button>
            </div>
            <CreateAdminAccountModal show={show} onHide={() => setShow(false)} />
        </div>
    );
}


export default AdminAccountCreate;