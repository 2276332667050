import {LoadingComponent} from "../../../Components/Loading";
import React, {useEffect, useState} from "react";
import useAuth from "../../../Hooks/useAuth";
import useToken from "../../../Hooks/useToken";
import {useHandleError} from "../../../Hooks/useHandleError";
import {AdminStats, getAdminStats} from "../actions";
import {useNavigate} from "react-router-dom";
import {Slugs} from "../../../utils/constants";


const AdminHomeView = () => {

    const {user} = useAuth();
    const {loginUser} = useToken();
    const handleError = useHandleError();

    const [stats, setStats] = useState<AdminStats | null>(null);

    const navigate = useNavigate();

    useEffect(() => {
        getAdminStats(loginUser)
            .then(stats => setStats(stats))
            .catch(handleError);

    }, [user.id]);

    const firstRow = [
        {key: "Commandes passées", value: stats?.orderCount ?? 0},
        {key: "Montant total des commandes passées", value: (stats?.orderTotalPrice ?? 0) + "€"},
        {key: "Moyenne d'une commande", value: (stats?.orderAveragePrice ?? 0) + "€"},
        {key: "Commandes passées le dernier mois", value: stats?.orderLastMonthCount ?? 0},
    ]

    const secondRow = [
        {key: "Nombre de fournisseurs partenaires", value: stats?.providerCount ?? 0},
        {key: "Pharmacies inscrites", value: stats?.dispensaryCount ?? 0},
        {key: <span className="pointer" onClick={() => navigate(Slugs.ACCOUNT_MANAGEMENT)}>
                <u>Compte en attente de validation</u>
        </span>, value: stats?.waitingForValidationAccountCount ?? 0},
    ];



    return (
        <>
            <div className="d-flex flex-column">
                {!!stats ? (
                    <>
                        <div className="row mb-5">
                            {firstRow.map(item => (
                                <Row key={item.key} itemKey={item.key} value={item.value} />
                            ))
                            }
                        </div>
                        <div className="row">
                            {secondRow.map((item, index) => (<Row itemKey={item.key} value={item.value} key={index}/>))}
                        </div>
                    </>
                ) : (<LoadingComponent/>)}

            </div>

        </>
    )
}

const Row = (item: {itemKey: string | React.ReactNode, value: number | string}) => (
    <div className="home-card ms-2 me-2 p-4 col-sm">
        <div className="home-card-title">{item.itemKey}</div>
        <div className="home-card-value">{item.value}</div>
    </div>
)


export default AdminHomeView;