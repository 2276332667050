import { OrderProduct } from '../../../Types/OrderProduct'
import React, { useMemo } from 'react'
import productPlaceholder from '../../../assets/images/product_placeholder.png'
import { shrinkText } from '../../../utils/helpers'

const OrderDetailProduct: React.FC<{ product: OrderProduct }> = ({
  product,
}) => {
  const reduction =
    product.reducedPrice &&
    Math.abs(product.reducedPrice - product.totalPrice) > 0.01

  return (
    <div className="order-detail-product-container" key={product.id}>
      <img
        className="order-detail-product-img"
        src={product.product.photo_url || productPlaceholder}
        alt={product.product.name}
      />
      <div className="d-flex flex-column align-items-start text-start">
        <span
          style={{
            fontSize: product.product.name.length < 28 ? '1rem' : '0.8rem',
          }}
        >
          {shrinkText(product.product.name, 30)}
        </span>
        {reduction && (
          <span className="line-through !text-gray-400 text-sm">
            {product.totalPrice.toFixed(2)}€
          </span>
        )}
        <span className="text-primary">{product.reducedPrice.toFixed(2)}€</span>
        <span>{product.amount} unités</span>
        {product.freeUnitAmount ? (
          <span>
            + {product.freeUnitAmount} unité
            {product.freeUnitAmount > 1 ? 's' : ''} gratuite
            {product.freeUnitAmount > 1 ? 's' : ''}
          </span>
        ) : null}
      </div>
    </div>
  )
}

export default OrderDetailProduct
