import React, {ChangeEvent} from "react";
import {FormStepProps} from "../../dispensary.index";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import ReCaptcha from "../../../../Components/Recaptcha/ReCaptcha";


const FormAddressPage: React.FC<FormStepProps> = ({ handleChange, values, nextStep, prevStep }) => {

    const [addressIsValid, setAddressIsValid] = React.useState<boolean>(true);
    const checkIsAddressValid = () => {
        if (values.address && values.address.length > 0) {
            return true;
        }
        setAddressIsValid(false);
        return false;
    }

    const [cityIsValid, setCityIsValid] = React.useState<boolean>(true);
    const checkIsCityValid = () => {
        if (values.city && values.city.length > 0) {
            return true;
        }
        setCityIsValid(false);
        return false;
    }

    const [zipIsValid, setZipIsValid] = React.useState<boolean>(true);
    const checkIsZipValid = () => {
        // Regex for french zip code validation
        const zipRegex = /^\d{5}$/;
        if (values.zipCode && values.zipCode.length > 0 && zipRegex.test(values.zipCode)) {
            return true;
        }
        setZipIsValid(false);
        return false;
    }

    const [legalStatusIsValid, setLegalStatusIsValid] = React.useState<boolean>(true);
    const checkIsLegalStatusValid = () => {
        if (values.legalStatus && values.legalStatus.length > 0) {
            return true;
        }
        setLegalStatusIsValid(false);
        return false;
    }

    const handleNext = () => {
        const booleans = [checkIsAddressValid(), checkIsCityValid(), checkIsZipValid(), checkIsLegalStatusValid()];
        // If all booleans are true, we can go to the next step
        if (booleans.every(Boolean)) {
            nextStep();
        }
    }


    const handleCaptcha = (value: string | null) => {
        if (value) {
            values.captcha = value;
        }
    }


  return (
        <>
            <FloatingLabel className="mb-1 login-form-label" label="Adresse de la pharmacie" controlId="address">
                <Form.Control
                    type="text"
                    placeholder="Adresse de la pharmacie"
                    autoComplete="off"
                    className="login-form-input"
                    name="address"
                    onChange={(e) => {
                        handleChange(e as ChangeEvent<HTMLInputElement>);
                        setAddressIsValid(true);
                    }}
                    value={values.address}
                    isInvalid={!addressIsValid}
                />
            </FloatingLabel>
            <FloatingLabel className="mb-1 login-form-label" label="Code postal de la pharmacie" controlId="zipCode">
                <Form.Control
                    type="text"
                    placeholder="Code postal de la pharmacie"
                    autoComplete="off"
                    className="login-form-input"
                    name="zipCode"
                    onChange={(e) => {
                        handleChange(e as ChangeEvent<HTMLInputElement>);
                        setZipIsValid(true);
                    }}
                    value={values.zipCode}
                    isInvalid={!zipIsValid}
                />
            </FloatingLabel>
            <FloatingLabel className="mb-1 login-form-label" label="Ville de la pharmacie" controlId="city">
                <Form.Control
                    type="text"
                    placeholder="Ville de la pharmacie"
                    autoComplete="off"
                    className="login-form-input"
                    name="city"
                    onChange={(e) => {
                        handleChange(e as ChangeEvent<HTMLInputElement>);
                        setCityIsValid(true);
                    }}
                    value={values.city}
                    isInvalid={!cityIsValid}
                />
            </FloatingLabel>
            <FloatingLabel className="mb-1 login-form-label" label="Raison sociale de la pharmacie" controlId="legalStatus">
                <Form.Control
                    type="text"
                    placeholder="Raison sociale de la pharmacie"
                    autoComplete="off"
                    className="login-form-input"
                    name="legalStatus"
                    onChange={(e) => {
                        handleChange(e as ChangeEvent<HTMLInputElement>);
                        setLegalStatusIsValid(true);
                    }}
                    value={values.legalStatus}
                    isInvalid={!legalStatusIsValid}
                />
            </FloatingLabel>

            {/*{*/}
            {/*    process.env.NODE_ENV === "production" && (*/}
            {/*        <ReCaptcha setToken={handleCaptcha}/>*/}
            {/*    )*/}
            {/*}*/}
            <div className="w-100 d-flex justify-content-end">
                <button className="btn btn-secondary me-4" type="button" onClick={prevStep}>Précédent</button>
                <button className="btn btn-primary" type="button" onClick={handleNext}>Envoyer</button>
            </div>
        </>
    );
}


export default FormAddressPage;