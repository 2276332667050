import {LoginUser} from "../../Types/User";
import {get, post} from "../../Api/api";
import {Order} from "../../Types/Order";


export const getOrders = (login: LoginUser) => {
  return get<Order[]>("/order/own", login);
}
export const contactProblem = (user: LoginUser, problem: {object: string, message: string, order_id: number}) => {
  return post<{ok: boolean}>("/contact/problem", user, problem);
}