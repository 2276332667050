import { LoginUser, User } from '../../Types/User'
import { get, patch, post, put } from '../../Api/api'
import { Business } from '../../Types/Business'
import { login } from '../LoginView/actions'

export const patchBusiness = (login: LoginUser, body: any) => {
  return patch<Business>('/business', login, body)
}
export const patchCatalogPresentationText = (
  login: LoginUser,
  text: string
) => {
  return post<Business>('/catalog/update-presentation-text', login, {
    presentation_text: text,
  })
}

export const getOtherUser = (login: LoginUser, userId: number) => {
  return get<User>(`/user/profile?userId=${userId}`, login)
}
export const changePassword = (login: LoginUser, body: any) => {
  return put<void>('/auth/password', login, body)
}
