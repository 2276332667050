export const shrinkText = (text: string, maxLength: number) => {
  if (!text) return ''
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...'
  }
  return text
}

// Format date to dd/mm/yyyy
export const formatDate = (date: string) => {
  const d = new Date(date)
  return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
}

export const addBusinessDays = (startDate: Date, daysToAdd: number) => {
  // Get the day of the week as a number (0 = Sunday, 1 = Monday, .... 6 = Saturday)
  const dow = startDate.getDay()
  // If the current day is Sunday add one day
  if (dow === 0) daysToAdd++
  // If the start date plus the additional days falls on or after the closest Saturday calculate weekends
  if (dow + daysToAdd >= 6) {
    //Subtract days in current working week from work days
    const remainingWorkDays = daysToAdd - (5 - dow)
    //Add current working week's weekend
    daysToAdd += 2
    if (remainingWorkDays > 5) {
      //Add two days for each working week by calculating how many weeks are included
      daysToAdd += 2 * Math.floor(remainingWorkDays / 5)
      //Exclude final weekend if remainingWorkDays resolves to an exact number of weeks
      if (remainingWorkDays % 5 === 0) daysToAdd -= 2
    }
  }
  startDate.setDate(startDate.getDate() + daysToAdd)
  return startDate
}
