import React from 'react'
import Footer from './Footer'
import Header from './Header'
import './style.css'
import {BasketProvider} from "../../Contexts/basket/BasketProvider";

const Layout = (props: { children: React.ReactNode }) => (
        <BasketProvider>
            <div className="layout-wrapper" id="outer-container">
                <Header/>
                <div className="content-wrapper" id="page-wrap">{props.children}</div>
                <Footer/>
            </div>
        </BasketProvider>
);

export default Layout
