import React from "react";
import DispensaryAccountValidation from "./components/DispensaryAccountValidation";
import AccountList from "./components/AccountList";
import AdminAccountCreate from "./components/AdminAccountCreate";
import "./index.scss";
import { post } from '../../Api/api'
import useToken from '../../Hooks/useToken'
import ProductPopup from './components/ProductPopup'

const UserManagementView: React.FC = () => {
  const { loginUser } = useToken();
  const [showPopup, setShowPopup] = React.useState(false);

  const handleCreateProduct = async (productData: any) => {
    try {
      const response = await post<any>("/product/manual", loginUser, productData, true);
      alert("Le produit a été ajouté.")
      // Close the popup after successful creation
      setShowPopup(false);
    } catch (error) {
      alert("Échec de l'ajout du produit.")
    }
  };
  const togglePopup = () => {
    setShowPopup(!showPopup); //somehow reversed but works
  };

    return (

        <div>
          <div>
            <button id="productAddBtn" onClick={togglePopup}>Ajouter un produit</button>
          </div>
            <DispensaryAccountValidation />
            <br/>
            <AccountList />
            <br/>
            <AdminAccountCreate />
          {showPopup && <ProductPopup handleCreateProduct={handleCreateProduct} onCancel={togglePopup} />}
        </div>

    );
}


export default UserManagementView;