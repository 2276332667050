import React from 'react'
import {LinkProps, NavLink, useMatch, useResolvedPath,} from 'react-router-dom'
import './style.scss'

function HeaderLink({ children, to, className, ...props }: LinkProps) {
  let resolved = useResolvedPath(to)
  let match = useMatch({ path: resolved.pathname, end: true })

  return (
    <div>
      <NavLink
        className={(match ? '' : ' inactive ') + ' pl-header-link ' + className}
        to={to}
        {...props}
      >
        {children}
      </NavLink>
    </div>
  )
}

export default HeaderLink
