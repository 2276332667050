import {
  BusinessCondition,
  BusinessConditionStep,
  BusinessConditionStepDto,
  BusinessConditionType,
} from '../../Types/BusinessCondition'
import React, { Dispatch } from 'react'
import { Product } from '../../Types/Product'
import { Provider } from '../../Types/Provider'

export type BusinessConditionState = {
  type: BusinessConditionType
  steps: (BusinessConditionStep | BusinessConditionStepDto)[]
  products: Product[]
  dispensaryIds: number[]
  networks: string[]
  provider?: Provider
  freeUnitCap?: number
  providers?: Provider[]
}

export enum BusinessConditionActionType {
  SET_BUSINESS_CONDITION,
  TOGGLE_TYPE,
  ADD_STEP,
  REMOVE_STEP,
  SET_PRODUCTS,
  REMOVE_PRODUCT,
  ADD_PRODUCT,
  SELECT_DISPENSARY_ID,
  UNSELECT_DISPENSARY_ID,
  UNSELECT_ALL_DISPENSARY,
  SET_DISPENSARY_IDS,
  UPDATE_NETWORKS,
  UPDATE_PROVIDER,
  EDIT_FREE_UNIT_CAP,
  ADD_PROVIDER,
  REMOVE_PROVIDER,
}

type UpdateNetworksAction = {
  type: BusinessConditionActionType.UPDATE_NETWORKS
  payload: string[]
}
type UpdateProviderAction = {
  type: BusinessConditionActionType.UPDATE_PROVIDER
  payload: Provider
}
type SetBusinessConditionAction = {
  type: BusinessConditionActionType.SET_BUSINESS_CONDITION
  payload: BusinessCondition
}

type ToggleTypeAction = {
  type: BusinessConditionActionType.TOGGLE_TYPE
  payload: BusinessConditionType
}

type AddStepAction = {
  type: BusinessConditionActionType.ADD_STEP
  payload: BusinessConditionStepDto
}

type RemoveStepAction = {
  type: BusinessConditionActionType.REMOVE_STEP
  payload: BusinessConditionStepDto
}

type SetProductsAction = {
  type: BusinessConditionActionType.SET_PRODUCTS
  payload: Product[]
}

type AddProductAction = {
  type: BusinessConditionActionType.ADD_PRODUCT
  payload: Product
}

type RemoveProductAction = {
  type: BusinessConditionActionType.REMOVE_PRODUCT
  payload: string
}

type SelectDispensaryIdAction = {
  type: BusinessConditionActionType.SELECT_DISPENSARY_ID
  payload: number
}

type UnselectDispensaryIdAction = {
  type: BusinessConditionActionType.UNSELECT_DISPENSARY_ID
  payload: number
}

type UnselectAllDispensaryIdAction = {
  type: BusinessConditionActionType.UNSELECT_ALL_DISPENSARY
}

type SetDispensaryIdsAction = {
  type: BusinessConditionActionType.SET_DISPENSARY_IDS
  payload: number[]
}

type EditFreeUnitCapAction = {
  type: BusinessConditionActionType.EDIT_FREE_UNIT_CAP
  payload: number
}

type AddProviderAction = {
  type: BusinessConditionActionType.ADD_PROVIDER
  payload: Provider
}
type RemoveProviderAction = {
  type: BusinessConditionActionType.REMOVE_PROVIDER
  payload: Provider
}

export type BusinessConditionAction =
  | SetBusinessConditionAction
  | ToggleTypeAction
  | AddStepAction
  | SetProductsAction
  | RemoveProductAction
  | AddProductAction
  | SelectDispensaryIdAction
  | UnselectDispensaryIdAction
  | UnselectAllDispensaryIdAction
  | SetDispensaryIdsAction
  | UpdateNetworksAction
  | UpdateProviderAction
  | RemoveStepAction
  | EditFreeUnitCapAction
  | AddProviderAction
  | RemoveProviderAction

type BusinessConditionProps = {
  state: BusinessConditionState
  dispatch: Dispatch<BusinessConditionAction>
}

export const BusinessConditionContext =
  React.createContext<BusinessConditionProps>({} as BusinessConditionProps)
