import {get, put} from "../../Api/api";
import {Provider} from "../../Types/Provider";
import {LoginUser} from "../../Types/User";
import {BusinessConditionStep, ProductBusinessConditionStep} from "../../Types/BusinessCondition";
import { Catalog, MinimalCatalog } from '../../Types/Catalog'
import { Product } from '../../Types/Product'

export const getCatalog = async (login: LoginUser, id?: string ) => {
    if (id === "" || id === undefined || isNaN(Number(id))) {
        throw new Error("Invalid id");
    }
    return get<Catalog>("/catalog/" + id, login);
}

export const getManualBusinessProducts = async (login: LoginUser, id?: number ) => {
    if (id === undefined || isNaN(Number(id))) {
        throw new Error("Invalid business id");
    }
    return get<Product[]>("/product/business/" + id, login);
}

export const getMinimalCatalogs = async (login: LoginUser) => {
    return get<MinimalCatalog[]>("/catalog/minimal", login);
};

export const addViewCount = async (login: LoginUser, id: string) => {
    await put<Provider>("/provider/" + id + "/visit", login);
}

export const getBusinessConditionSteps = async (login: LoginUser, id: string) => {
    return get<BusinessConditionStep[]>("/provider/" + id + "/delivery-discount", login);
}

export const getItemsBusinessConditionSteps = async (login: LoginUser, id: string) => {
    return get<ProductBusinessConditionStep[]>("/provider/" + id + "/products-discount", login);
}