import React from "react";
import {Dispensary} from "../../../Types/Dispensary";
import {Button, Modal} from "react-bootstrap";
import {getDownloadCpsCardAdminUrl, getDownloadIdCardAdminUrl, handleDownloadCpsCardAdmin, handleDownloadIdCardAdmin} from "../actions";
import useToken from "../../../Hooks/useToken";
import DeclineModal from "./DeclineModal";
import {AccountValidation} from "../../../Types/User";
import ApproveModal from "./ApproveModal";
import "../index.scss";
import { FiEye } from "react-icons/fi";

type Props = {
    account: Dispensary,
    fireRefresh: () => void,
}

const SingleDispensaryAccountValidation: React.FC<Props> = ({account, fireRefresh}) => {

    //state
    const {loginUser} = useToken();
    const [deleteModalShow, setDeleteModalShow] = React.useState<boolean>(false);
    const [approveModalShow, setApproveModalShow] = React.useState<boolean>(false);
    const [showImageModalUrl, setShowImageModalUrl] = React.useState<string>();
    
    //behavior
    const handleStartDecline = () => {
        setDeleteModalShow(true);
    }


    //render
    return (
        <>
            <div className="row grey-bg-color rounded p-2 m-2 align-items-center" >
                <div className="col">
                {account.business.contactFirstName} {account.business.contactName}
                </div>
                <div className="col">
                    {account.rpps}
                </div>
                <div className="col">
                    {account.hasIdCardDocument ?
                        (
                            <span className="text-primary pointer flex items-center">
                                <FiEye className="mr-2 mt-0.5" onClick={() => getDownloadIdCardAdminUrl(loginUser, account.business.user.email).then(setShowImageModalUrl)} />
                                <b onClick={() => Promise.resolve(handleDownloadIdCardAdmin(loginUser, account.business.contactName, account.business.user.email))}>Télécharger</b>
                            </span>
                        ) : (
                            <span className="text-danger">Manquant</span>
                        )
                    }
                </div>
                <div className="col">
                    {account.hasCpsCardDocument ?
                        (
                            <span className="text-primary pointer flex items-center">
                                <FiEye  className="mr-2 mt-0.5" onClick={() => getDownloadCpsCardAdminUrl(loginUser, account.business.user.email).then(setShowImageModalUrl)}/>
                                <b onClick={() => Promise.resolve(handleDownloadCpsCardAdmin(loginUser, account.business.contactName, account.business.user.email))}>Télécharger</b>
                            </span>
                        ) : (
                            <span className="text-danger">Manquant</span>
                        )
                    }
                </div>
                <div className="col">
                    {account.business.user.adminValidated === AccountValidation.UNTREATED ? (
                        <span className="text-warning">En attente</span>
                    ) : (
                        <span className="text-danger">Refusé</span>
                    )}
                </div>
                <div className="col">
                    <Button variant="primary" className="text-white me-1 validate-button" onClick={() => setApproveModalShow(true)} >Valider</Button>
                    <Button variant="outline-secondary ms-1 validate-button" onClick={handleStartDecline}>Refuser</Button>
                </div>
            </div>
            <DeclineModal show={deleteModalShow} onHide={() => setDeleteModalShow(false)} userId={account.business.user.id} fireRefresh={fireRefresh} />
            <ApproveModal show={approveModalShow} onHide={() => setApproveModalShow(false)} userId={account.business.user.id} fireRefresh={fireRefresh} />
            <Modal show={!!showImageModalUrl} onHide={() => setShowImageModalUrl(undefined)}>
                <iframe src={showImageModalUrl} className="border-none w-full h-96" />
            </Modal>
        </>
    );
}


export default SingleDispensaryAccountValidation;