import React from "react";
import {Button, Modal} from "react-bootstrap";
import {LoadingComponent} from "../../../../Components/Loading";

type Props = {
    onConfirm: () => void;
    onCancel: () => void;
    isOpen: boolean;
    isLoading: boolean;
    header: string;
    body: string;
}

const ConfirmationModal: React.FC<Props> = (props) => (
        <Modal show={props.isOpen} onHide={() => props.onCancel()}>
            <Modal.Header closeButton>{props.header}</Modal.Header>
            <Modal.Body>
                <div>
                    {props.body}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onCancel}>Annuler</Button>
                <Button variant="primary" className="text-white" onClick={props.onConfirm}>{props.isLoading ? <LoadingComponent size={30} /> : "Confirmer" }</Button>
            </Modal.Footer>
        </Modal>
);


export default ConfirmationModal;