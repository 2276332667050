import React, {useCallback, useState} from 'react'
import {Alert, Button, FloatingLabel, Form} from 'react-bootstrap'
import {Variant} from 'react-bootstrap/esm/types'
import useToken from '../../Hooks/useToken'
import { changePassword as changePasswordBack } from '../../Views/ProfileView/actions'

function ChangePassword() {
    const [inputs, setInputs] = useState<{[key: string]: string}>({})
    const [alert, setAlert] = useState<string>()
    const [variant, setVariant] = useState<Variant>()
    const {loginUser} = useToken()

    const changePassword = useCallback(() => {
        setAlert(undefined)
        if(inputs.passwordConfirm == inputs.passwordNew){
            changePasswordBack(loginUser, 
                {
                    password: inputs.passwordOld,
                    newPassword: inputs.passwordConfirm
                }
            ).then(() => {
                setVariant("success")
                setAlert("Mot de passe modifié.")
            }).catch((e) => {
                setVariant("danger")
                setAlert(e.code == 403 ? e.message : "Une erreur est survenue...")
            })
        } else {
            setVariant("danger")
            setAlert("Les deux mots de passe ne correspondent pas.")
        }
        //error
    }, [inputs, loginUser])


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name
        const value = event.target.value
        setInputs((values) => ({...values, [name]: value}))
    }

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault()
    }

    return (
        <div>
            {alert && 
                <Alert variant={variant}>
                    <span>
                        {alert}
                    </span>
                </Alert>
            }
            <form onSubmit={handleSubmit} autoComplete="off">
                <FloatingLabel
                    className="mb-1"
                    label="Mot de passe actuel"
                    controlId="passwordOld"
                >
                    <Form.Control
                        type="password"
                        placeholder="mot de passe actuel"
                        name="passwordOld"
                        onChange={handleChange}
                    />
                </FloatingLabel>
                <FloatingLabel
                    className="mb-1"
                    label="Nouveau mot de passe"
                    controlId="passwordNew"
                >
                    <Form.Control
                        type="password"
                        placeholder="nouveau mot de passe"
                        name="passwordNew"
                        onChange={handleChange}
                    />
                </FloatingLabel>
                <FloatingLabel
                    className="mb-1"
                    label="Confirmer le nouveau mot de passe"
                    controlId="passwordConfirm"
                >
                    <Form.Control
                        type="password"
                        placeholder="confirmer mot de passe"
                        name="passwordConfirm"
                        onChange={handleChange}
                    />
                </FloatingLabel>
                <Button variant="primary" className="text-white" type="submit" onClick={changePassword}>
                    Changer de mot de passe
                </Button>
            </form>
        </div>
    )
}

export default ChangePassword
