import "./style.scss";
import React, {useEffect, useState} from "react";
import {Order} from "../../Types/Order";
import {httpErrorHandler} from "../../utils/errors";
import useToast from "../../Hooks/useToast";
import {getOrders} from "../DispensaryOrdersView/actions";
import useToken from "../../Hooks/useToken";
import SupplierDetailOrder from "./Components/SupplierDetailOrder";
import {useNavigate} from "react-router-dom";

const SupplierOrdersView = () => {

    const [orders, setOrders] = useState<Order[]>([]);

    const {showError} = useToast();
    const {loginUser} = useToken();
    const navigate = useNavigate();

    useEffect(() => {
        getOrders(loginUser)
            .then(orders => setOrders(orders))
            .catch(httpErrorHandler(showError, navigate));
    }, []);

    return (
        <>
            <div className="text-start mb-4">
                <span className="page-title !text-3xl sm:!text-4xl">Commandes reçues</span>
            </div>
            <div className="orders-container">
                <div className="grid grid-cols-3 text-sm sm:text-base xl:text-lg font-medium gap-2 items-center -mb-3">
                    <span>Pharmacie</span>
                    <span>Montant total HT</span>
                    <span>Date de livraison souhaitée</span>
                    <span/>
                </div>
                {orders.map(order => (
                    <div className="mt-3">
                        <SupplierDetailOrder key={order.id} order={order}/>
                    </div>
                ))}
            </div>
        </>

    );
}


export default SupplierOrdersView;