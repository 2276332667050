import React from "react";

type Props = {
    currentStep: number,
    totalSteps: number,
    className?: string
}
const ProgressBarPage: React.FC<Props> = ({currentStep, totalSteps, className = ""}) => (
    <div className={`d-flex justify-content-center align-items-center ${className}`}>
        {Array.from({length: totalSteps}, (_, i) => (
            <div key={i} className={`progress-bar-step ${i <= currentStep ? "progress-bar-step-active" : ""}`}/>
        ))}
    </div>
);


export default ProgressBarPage;