import React from 'react'

function Err404() {
  return (
    <main style={{ padding: '1rem'}}>
      <p style={{fontFamily: "Dosis"}}>There's nothing here!</p>
      <p style={{fontFamily: "Dosis"}}>404</p>
    </main>
  )
}

export default Err404
