import "../style.scss";
import {Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import useAuth from "../../../Hooks/useAuth";
import React, {useEffect, useState} from "react";
import {getProviderStats, ProviderStats} from "../actions";
import useToken from "../../../Hooks/useToken";
import {LoadingComponent} from "../../../Components/Loading";
import {Slugs} from "../../../utils/constants";
import {useHandleError} from "../../../Hooks/useHandleError";

const CatalogHomeView = () => {

    const navigate = useNavigate();

    const {user} = useAuth();
    const {loginUser} = useToken();
    const handleError = useHandleError();

    const [stats, setStats] = useState<ProviderStats | null>(null);

    useEffect(() => {
        getProviderStats(loginUser)
            .then(stats => setStats(stats))
            .catch(handleError);

    }, [user.id]);

    const list = [
        {key: "Commandes passées", value: stats?.orderCount ?? 0},
        {key: "Montant total des commandes passées", value: (stats?.orderTotalPrice ?? 0) + "€"},
        {key: "Moyenne d'une commande", value: (stats?.orderAveragePrice ?? 0) + "€"},
        {key: "Visites de page", value: stats?.orderShopVisitCount ?? 0},
    ];

    return (
        <>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
                {!!stats ? (
                    list.map(item => (
                            <div key={item.key} className="home-card ms-2 me-2 p-4 col-sm">
                                <div className="home-card-title">{item.key}</div>
                                <div className="home-card-value">{item.value}</div>
                            </div>
                        ))
                ) : (<LoadingComponent/>)}

            </div>
            <Button className="mt-5 text-white"
                    size="lg"
                    onClick={() => navigate(`${Slugs.SHOP}/${user.business.catalog.id}`)}
                    variant="primary">
                Consulter ma boutique
            </Button>
        </>
    );
}


export default CatalogHomeView;