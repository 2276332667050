import {LoginUser} from "../../Types/User";
import {BusinessCondition, CreateConditionDto} from "../../Types/BusinessCondition";
import {del, get, post, put} from "../../Api/api";
import {Dispensary} from "../../Types/Dispensary";
import {Product} from "../../Types/Product";
import {PagingQuery, PagingResponse} from "../../utils/types";
import { Provider } from '../../Types/Provider'

export const getBusinessConditions = (login: LoginUser) => {
    return get<BusinessCondition[]>("/business-conditions", login);
}

export const getOneBusinessCondition = (login: LoginUser, id: string) => {
    if (isNaN(Number(id))) {
        throw new Error("id must be a number");
    }
    return get<BusinessCondition>("/business-conditions/" + id, login);
}

export const searchPharmacies = (login: LoginUser, searchTerm: string) => {
    return get<{id: number, businessName: string, address: string, dispensaryNetwork: string}[]>(`/dispensary/search?q=${searchTerm}`, login);
}

export const getAllPharmaciesPaginated = (login: LoginUser, body: PagingQuery) => {
    return get<PagingResponse<Dispensary>>(`/dispensary/paging?page=${body.page}&pageSize=${body.pageSize}`, login);
}

export const getAllPharmacies = (login: LoginUser) => {
    return get<Dispensary[]>("/dispensary", login);
}

export const getAllOwnProducts = (login: LoginUser, providerId?:number) => {
    return get<Product[]>(`/product/own${providerId ? "/" + providerId : ""}`, login);
}

export const addBusinessCondition = (login: LoginUser, dto: CreateConditionDto) => {
    return post<BusinessCondition>("/business-conditions", login, dto);
}

export const updateBusinessCondition = (login: LoginUser, dto: CreateConditionDto, id?: string) => {
    if (id === undefined || isNaN(Number(id))) {
        throw new Error("id must be a number");
    }
    return put<BusinessCondition>("/business-conditions/", login, {...dto, id});
}

export const searchProductByName = (login: LoginUser, searchTerm: string) => {
    return get<Product[]>(`/product/own/search?q=${searchTerm}`, login);
}

export const deleteBusinessCondition = (login: LoginUser, id: number) => {
    if (isNaN(id)) {
        throw new Error("id must be a number");
    }
    return del<BusinessCondition>("/business-conditions/" + id, login);
}

export const getAllProvidersPaginated = (login: LoginUser, body: PagingQuery) => {
    return get<PagingResponse<Provider>>(`/provider/paging?page=${body.page}&pageSize=${body.pageSize}`, login);
}