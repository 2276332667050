import React from "react";
import {Dispensary} from "../../../Types/Dispensary";
import SingleDispensaryAccountValidation from "./SingleDispensaryAccountValidation";
import {getDispensaryAccountsToValidate} from "../actions";
import useToken from "../../../Hooks/useToken";
import {useHandleError} from "../../../Hooks/useHandleError";
import {getAdminStats} from "../../HomeView/actions";
import "../index.scss";


const DispensaryAccountValidation = () => {

    //state
    const [nbDispensaryToValidate, setNbDispensaryToValidate] = React.useState<number>(0);
    const [accounts, setAccounts] = React.useState<Dispensary[]>([]);
    const {loginUser} = useToken();
    const handleError = useHandleError();

    //behavior

    React.useEffect(() => {
        handleRefresh();
        getAdminStats(loginUser)
            .then(nbDispensaryToValidate => setNbDispensaryToValidate(nbDispensaryToValidate.waitingForValidationDispensaryCount))
            .catch(handleError);
    }, []);

    const handleRefresh = () => {
        getDispensaryAccountsToValidate(loginUser)
            .then(setAccounts)
            .catch(handleError);
    }

    const renderAccounts = () => {
        if (nbDispensaryToValidate != 0) {
            return (
                <div className="mt-2">
                <div className="row p-2" >
                    <div className="col">
                        Nom complet
                    </div>
                    <div className="col">
                        Numéro RPPS
                    </div>
                    <div className="col">
                        Pièce d'identité
                    </div>
                    <div className="col">
                        Carte CPS
                    </div>
                    <div className="col">
                        Statut
                    </div>
                    <div className="col">
                        Validation
                    </div>
                </div>
                {accounts.map(account => (
                    <SingleDispensaryAccountValidation key={account.id} account={account} fireRefresh={handleRefresh}/>
                ))}
            </div>
            )
        } else {
            return (
                <div className="text">
                    Pas de nouvelles demandes
                </div>
            )
        }
    }

    //render
    return (
        <div className="text-start">
            <span className="page-title" >Comptes pharmaciens à valider</span>
            {renderAccounts()}
        </div>
    );
}


export default DispensaryAccountValidation;