import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import PharmaAdvView from '../PharmaAdvView'
import SupplierAdvView from '../SupplierAdvView'

const markdown = `# QUI SOMMES NOUS ?

Le métier de pharmacien n’a fait qu’évoluer ces dernières années et désormais, avec un suivi
et un accompagnement des patients de plus en plus poussés et personnalisés, peu de
pharmaciens ou de collaborateurs ne peuvent se permettre de passer plus de temps auprès
de commerciaux et de délégués pharmaceutiques, pour des réassorts ou des commandes
qui pourraient être traitées en quelques minutes voire quelques secondes parfois.

PHILAB a ainsi été créé afin de vous proposer une nouvelle alternative, claire et autonome,
afin de passer vos commandes rapidement et efficacement, de telle sorte que vous puissiez
maîtriser totalement votre stock et surtout votre temps. La plateforme dispose d’une
interface claire et ludique qui vous permettra d’avoir accès de façon directe à l’ensemble du
catalogue pharmaceutique disponible.

Aujourd’hui complémentaire au passage du délégué pharmaceutique, la plateforme PHILAB
pourrait devenir à moyen et à long terme l’outil de commande numéro 1 du pharmacien
d’officine.
`

function InfoView() {
  return (
    <>
      <ReactMarkdown
        className="staticText"
        children={markdown}
        remarkPlugins={[remarkGfm]}
      />
      <PharmaAdvView />
      <SupplierAdvView />
    </>
  )
}

export default InfoView
