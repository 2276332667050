import React from "react";
import {Button, FloatingLabel, Form, Modal} from "react-bootstrap";
import { LoadingComponent } from "../../../Components/Loading";
import {registerAdmin} from "../actions";
import useToken from "../../../Hooks/useToken";
import {useHandleError} from "../../../Hooks/useHandleError";

type Props = {
    show: boolean,
    onHide: () => void,
}

const CreateAdminAccountModal: React.FC<Props> = ({show, onHide}) => {

    const [email, setEmail] = React.useState("");
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");

    const [isLoading, setIsLoading] = React.useState(false);

    const handleError = useHandleError();

    const {loginUser} = useToken();

    const handleSubmit = () => {
        if (email.length > 0 || firstName.length > 0 || lastName.length > 0) {
            setIsLoading(true);
            registerAdmin(loginUser, {email, firstName, lastName})
                .then(() => onHide())
                .catch(handleError)
                .finally(() => setIsLoading(false));
        }
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Créer un compte administrateur</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <FloatingLabel className="mb-1 login-form-label" label="Email" controlId="username">
                        <Form.Control
                            type="text"
                            placeholder="email"
                            autoComplete="off"
                            className="login-form-input"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            required
                        />
                    </FloatingLabel>
                    <FloatingLabel className="mb-1 login-form-label" label="Prénom" controlId="firstName">
                        <Form.Control
                            type="text"
                            placeholder="prénom"
                            autoComplete="off"
                            className="login-form-input"
                            onChange={(e) => setFirstName(e.target.value)}
                            value={firstName}
                            required
                        />
                    </FloatingLabel>
                    <FloatingLabel className="mb-1 login-form-label" label="Nom" controlId="firstName">
                        <Form.Control
                            type="text"
                            placeholder="nom"
                            autoComplete="off"
                            className="login-form-input"
                            onChange={(e) => setLastName(e.target.value)}
                            value={lastName}
                            required
                        />
                    </FloatingLabel>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Fermer
                </Button>
                {!isLoading ? (
                    <Button variant="primary" onClick={handleSubmit}>
                        Créer
                    </Button>
                ) : (
                    <Button variant="primary" disabled>
                        <LoadingComponent />
                    </Button>
                    )
                }

            </Modal.Footer>
        </Modal>
    )
}


export default CreateAdminAccountModal;
