import React, { useCallback, useEffect, useState } from 'react'
import { Document, Page } from 'react-pdf'
import { pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `/scripts/pdf.worker.min.js`

const CGVView = () => {
  const [numPages, setNumPages] = useState<number>()

  const onDocumentLoadSuccess = useCallback(
    ({ numPages }: { numPages: number }) => setNumPages(numPages),
    []
  )
  const [width, setWidth] = useState<number>()

  useEffect(() => {
    const handleResize = () => {
      setWidth(Math.min(window.innerWidth - 70, 800))
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <>
      <div
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <object
          id="pdf-object"
          data={'pdf/' + window.location.pathname.substring(1) + '.pdf'}
          type="application/pdf"
          width="100%"
          height="100%"
        >
          <p>
            <a href="pdf/' + window.location.pathname.substring(1) + '.pdf'">
              to the PDF!
            </a>
          </p>
        </object>
      </div>
      <Document
        file={'pdf/' + window.location.pathname.substring(1) + '.pdf'}
        onLoadSuccess={onDocumentLoadSuccess}
        className="w-full h-full flex justify-center flex-col max-sm:scale-[119%] origin-top overflow-hidden"
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            pageNumber={index + 1}
            className="mx-auto scale-[109%] origin-center"
            width={width}
          />
        ))}
      </Document>
    </>
  )
}

export default CGVView
