import {LoginUser} from "../../Types/User";
import {Product} from "../../Types/Product";
import {patch} from "../../Api/api";


export const toggleVisibility = (login: LoginUser, product: Product) => {
    return patch<void>(`/product/${product.id}/visibility`, login);
};

export const updatePas = (user: LoginUser, product: Product) => {
  return patch<Product>(`/product/${product.id}/gap`, user, { gap: product.pas });
}

export const updatePrice = (user: LoginUser, product: Product) => {
  return patch<Product>(`/product/${product.id}/price`, user, { price: product.price });
}