import {NavigateFunction} from "react-router-dom";


export const httpErrorHandler = (showError: Function, navigate: NavigateFunction) => (error: any) => {
    switch (error.code) {
        case 401:
            if(error.message && error.message != "Unauthorized")
                showError(error.message);

            navigate("/login");
            break;
        case 403:
            showError('Vous n\'avez pas les droits pour effectuer cette action');
            break;
        case 404:
            showError('Page non trouvée');
            break;
        case 500:
            showError('Une erreur est survenue');
            break;
        case 429:
            showError('Captcha invalide');
            break;
        case 400:
            if (error.errorMessage) {
                showError(error.errorMessage);
                break;
            }
            showError('Requête invalide');
            break;
        default:
            showError('Une erreur est survenue');
            break;
    }
}