import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const markdown = `# AVANTAGES FOURNISSEUR

Plus de pharmacies partenaires ! Toutes les pharmacies en France pourront accéder à votre
catalogue produit via cette plateforme, et y passer commande directement. Il s’agit donc
d’une énorme porte d’entrée du laboratoire au sein de chaque pharmacie française.
Plus aucune contrainte de commande ! Chaque pharmacien pourra donc passer commande
à n’importe quel moment, et ces commandes arriveront directement sur un espace dédié au
sein de votre structure.

Un nombre plus important de commandes ! Le pharmacien ayant un accès facilité à votre
catalogue, la quantité de commandes et de réassorts sera bien supérieure.
Optimisation des coûts. Ouvrir un marché peut nécessiter une discussion entre le
pharmacien et le fournisseur. Passer un réassort de façon plus rapide et efficace permettra
au laboratoire d’économiser de l’argent, notamment sur sa force commerciale, parfois
inutile.

Cependant, tous les pharmaciens auront potentiellement accès à des conditions de marché,
afin de garantir des conditions, et ce, toute l’année.
Les remises et accords groupements seront directement intégrés par nos soins selon vos
informations.

Nous vous proposons donc d’ouvrir votre catalogue produit à toutes les pharmacies
partenaires, ou le cas échéant à toutes les pharmacies ayant déjà établi un partenariat avec
vous. Certains laboratoires ayant un droit de regard sur les potentiels partenaires, le
catalogue ne sera ouvert que si le laboratoire le souhaite.

`

function SupplierAdvView() {
  return (
    <ReactMarkdown
      className="staticText"
      children={markdown}
      remarkPlugins={[remarkGfm]}
    />
  )
}

export default SupplierAdvView
