import React, { useEffect } from 'react'
import SearchBox from '../../../../Components/SearchBox/SearchBox'
import { Dispensary } from '../../../../Types/Dispensary'
import { getAllPharmaciesPaginated, searchPharmacies } from '../../actions'
import useToken from '../../../../Hooks/useToken'
import AdvancedSelect from './selected-pharmacies/AdvancedSelect'
import PharmaciesTable from './selected-pharmacies/PharmaciesTable'
import { Business } from '../../../../Types/Business'
import { LightDispensary } from './selected-pharmacies/PharmacyRow'
import { useHandleError } from '../../../../Hooks/useHandleError'

const SelectedPharmacies = () => {
  const [pharmacies, setPharmacies] = React.useState<
    (Dispensary | LightDispensary)[]
  >([])

  const [currentPage, setCurrentPage] = React.useState<number>(1)
  const [totalPages, setTotalPages] = React.useState<number>(0)

  const [previousSearchTerm, setPreviousSearchTerm] = React.useState<string>('')

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    setPharmacies([])
    getAllPharmaciesPaginated(loginUser, {
      page,
      pageSize: 6,
    }).then((r) => {
      setPharmacies(r.data)
      setTotalPages(Math.ceil(r.total / 6))
      setCurrentPage(r.page)
    })
  }

  const { loginUser } = useToken()
  const handleError = useHandleError()

  useEffect(() => {
    handlePageChange(1)
  }, [])

  const handleSearch = (search: string) => {
    if (search.length > 0 && search.length > previousSearchTerm.length) {
      searchPharmacies(loginUser, search)
        .then((r) => {
          setCurrentPage(1)
          setTotalPages(1)
          setPharmacies(
            r.map((p) => ({
              id: p.id,
              dispensaryNetwork: p.dispensaryNetwork,
              business: {
                businessName: p.businessName,
                address: p.address,
              } as Business,
            }))
          )
        })
        .catch(handleError)
    } else if (search.length === 0) {
      handlePageChange(1)
    }
    setPreviousSearchTerm(search)
  }

  return (
    <div className="text-start">
      <span className="section-title">Pharmacies concernées</span>
      <div className="max-w-[500px]">
        <SearchBox
          size={'100%'}
          handleSearch={handleSearch}
          title="Rechercher une pharmacie"
        />
      </div>
      <PharmaciesTable
        pharmacies={pharmacies}
        page={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
    </div>
  )
}

export default SelectedPharmacies
