import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import CatalogDisplay from '../../../Components/FournisseurDisplay'
import { getCatalog } from '../../ShopView/actions'
import useToken from '../../../Hooks/useToken'
import { Catalog } from '../../../Types/Catalog'

type Props = {
  show: boolean
  onHide: () => void
  catalog: Catalog
}

const PreviewCoverPhotoModal: React.FC<Props> = ({ show, onHide, catalog }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Prévisualiser la photo de couverture</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="pb-3">
          Ceci est un apercu de la photo de couverture de votre entreprise. Elle
          sera affichée exactement comme celle-ci sur la page d'accueil des
          pharmacies.
        </div>
        {catalog && !isLoading && (
          <CatalogDisplay catalog={catalog} isPreview={true} />
        )}
      </Modal.Body>
    </Modal>
  )
}

export default PreviewCoverPhotoModal
