import React from 'react'
import './style.scss'
import StockDetail from './components/StockDetail'
import { Order } from '../../Types/Order'

import useToken from '../../Hooks/useToken'
import { useHandleError } from '../../Hooks/useHandleError'
import useAuth from '../../Hooks/useAuth'
import ConnectWinpharma from './components/ConnectWinpharma'

const StockView = () => {
  const { user } = useAuth()

  return (
    <div className="">
      <span className="page-title !text-3xl md:!text-5xl">Votre stock</span>
      <div className="orders-container">
        {user.business.dispensary?.winpharmaId ? (
          <StockDetail />
        ) : (
          <ConnectWinpharma />
        )}
      </div>
    </div>
  )
}

export default StockView
