import jsPDF from 'jspdf'
import React, { useLayoutEffect } from 'react'
import { Order } from '../../Types/Order'
import { formatDate } from '../../utils/helpers'
import { User } from '../../Types/User'
import { useAuth } from '../../Contexts/AuthProvider'
import { get } from '../../Api/api'
import useToken from '../../Hooks/useToken' // Import the API functions

interface FactureViewProps {
  order: Order
}
function GetUser(): User | null {
  //needed to get the IBAN, because it doesnt seem to be accessible via order object
  const { user } = useAuth()
  return user
}

const FactureView: React.FC<FactureViewProps> = ({ order }) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const user = GetUser() //to retrieve IBAN
  const { loginUser } = useToken()

  const [fetchedIBAN, setFetchedIBAN] = React.useState<string | null>(null) // State to store fetched IBAN
  const [tvaDetails, setTvaDetails] = React.useState<{ [key: string]: number }>(
    {}
  ) // State to store fetched TVA details

  // Function to fetch IBAN from the API
  React.useEffect(() => {
    const fetchIBAN = async () => {
      try {
        const response = await get<{ iban: string }>(
          `/dispensary/ibansearch/${order.dispensary.id}`,
          loginUser,
          true,
          true,
          false
        )
        setFetchedIBAN(response.iban) // Set the fetched IBAN in state
      } catch (error) {
        console.error('Error fetching IBAN:', error)
      }
    }
    parseTVA()

    fetchIBAN() // Call the function to fetch IBAN when component mounts
  }, []) // Empty dependency array ensures the effect runs only once

  useLayoutEffect(() => {
    const report = new jsPDF({
      unit: 'px',
    })
    report.html(ref.current!).then(() => {
      report.save('report.pdf')
    })
  }, [ref.current])

  const business = order?.provider.catalog.business
  const dispensaryBusiness = order?.dispensary.business

  function parseTVA() {
    let tvaDetails: { [key: string]: number } = {}
    order?.orderProducts.forEach((product) => {
      if (product.product.vatRate) {
        if (tvaDetails[product.product.vatRate]) {
          tvaDetails[product.product.vatRate] +=
            product.reducedPrice * (product.product.vatRate / 100)
        } else {
          tvaDetails[product.product.vatRate] =
            product.reducedPrice * (product.product.vatRate / 100)
        }
      }
    })
    setTvaDetails(tvaDetails)
  }
  return (
    <div className="fixed -bottom-52 translate-y-full">
      <div
        ref={ref}
        className="w-[900px] text-left scale-[48%] origin-top-left ml-2"
      >
        <div className="text-center text-xl">
          <div>Confirmation de Commande</div>
        </div>
        <div className="text-center w-full text-base mb-5">
          Passée sur Philab
        </div>
        <div className="flex justify-between mb-3">
          <div>
            <div className="font-bold underline">Simulation Financière</div>
            <div>Date: {formatDate(order?.requestedDate!)}</div>
            <div>Numéro de Commande : {order?.id}</div>
          </div>
          <div>
            <div className="font-bold underline">
              Commande passée pour le compte de :{' '}
            </div>
            <div>{dispensaryBusiness?.businessName}</div>
            <div>{dispensaryBusiness?.legalStatus}</div>
            <div>{dispensaryBusiness?.address}</div>
            <div>{`${dispensaryBusiness?.city} ${dispensaryBusiness?.zipCode}`}</div>
            <div>{dispensaryBusiness?.siren}</div>
            <div>{dispensaryBusiness?.phoneNumber}</div>
            <div>
              <b>
                IBAN :{' '}
                {order?.dispensary.dispensaryIban || fetchedIBAN || 'N/A'}
              </b>
            </div>
          </div>
        </div>
        <div className="my-1">
          Réduction apportée aux frais de port : {order?.fdpReduction || '0'}%
        </div>
        <div className="border-2 border-black">
          <table className="w-full">
            <thead className="border-b-2 border-black">
              <tr>
                <th>Code EAN13/ACL13 ou Ref.</th>
                <th>Désignation</th>
                <th>Qté</th>
                <th>Px unitaire</th>
                <th>Remise</th>
                <th>Montant HT</th>
              </tr>
              <div className="h-4" />
            </thead>
            <tbody>
              {order?.orderProducts.map((product) => (
                <>
                  <tr>
                    {/*We do a check for empty or null ean/acl values*/}
                    <td>
                      {product.product.ean13 || product.product.acl13 ? (
                        <>
                          {product.product.ean13 || '-'} /{' '}
                          {product.product.acl13 || '-'}
                        </>
                      ) : (
                        <>Ref. : {product.product.id}</>
                      )}
                    </td>
                    <td>{product.product.name}</td>
                    <td>{product.amount}</td>
                    <td>{product.product.price} €</td>
                    <td>
                      {Math.max(
                        0,
                        +(
                          ((product.totalPrice - product.reducedPrice) /
                            product.totalPrice) *
                          100
                        ).toFixed(1)
                      )}
                      %
                    </td>
                    <td>{product.reducedPrice.toFixed(2)} €</td>
                  </tr>
                  {product.freeUnitAmount ? (
                    <tr>
                      <td>
                        {product.product.ean13 || product.product.acl13 ? (
                          <>
                            {product.product.ean13 || '-'} /{' '}
                            {product.product.acl13 || '-'}
                          </>
                        ) : (
                          <>Ref : {product.product.id}</>
                        )}
                      </td>
                      <td>{product.product.name}</td>
                      <td>{product.freeUnitAmount}</td>
                      <td>{product.product.price} €</td>
                      <td>100%</td>
                      <td>0 €</td>
                    </tr>
                  ) : null}
                </>
              ))}
              <div className="h-4" />
            </tbody>
          </table>
        </div>
        <div className="flex justify-end font-bold text-lg mt-4">
          <div className="border-2 border-black grid grid-cols-2 p-2">
            <div>
              <div>Total HT</div>
              {Object.keys(tvaDetails).map((key) => (
                <div key={key}>TVA {key}%</div>
              ))}
              <div>Total TTC</div>
              <div>Net à payer</div>
            </div>
            <div className="text-end">
              <div>
                {order?.orderProducts
                  .reduce((val, product) => val + product.reducedPrice, 0)
                  .toFixed(2)}{' '}
                €
              </div>
              {Object.keys(tvaDetails).map((key) => (
                <div key={key}>{tvaDetails[key].toFixed(2)} €</div>
              ))}
              {/* {order?.orderProducts
                  .reduce(
                    (val, product) =>
                      val +
                      (product.reducedPrice * product.product.vatRate) / 100,
                    0
                  )
                  .toFixed(2)}{' '} */}
              {/* €</div> */}
              <div>
                {order?.orderProducts
                  .reduce(
                    (val, product) =>
                      val +
                      product.reducedPrice *
                        (1 + product.product.vatRate / 100),
                    0
                  )
                  .toFixed(2)}{' '}
                €
              </div>
              <div>
                {order?.orderProducts
                  .reduce(
                    (val, product) =>
                      val +
                      product.reducedPrice *
                        (1 + product.product.vatRate / 100),
                    0
                  )
                  .toFixed(2)}{' '}
                €
              </div>
            </div>
            <div className="h-4" />
          </div>
        </div>

        <div className="mt-3">
          Ceci n’est pas une facture et ne constitue en aucun cas une demande ni
          une preuve de règlement.
          <br />
          Le fournisseur se réserve le droit d’éditer et d’envoyer les factures
          selon le schéma habituel.
        </div>
      </div>
    </div>
  )
}
export default FactureView
