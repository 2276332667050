import React, { useCallback, useContext, useMemo } from "react";
import PharmacyRow, {LightDispensary} from "./PharmacyRow";
import {FiArrowRight, FiArrowLeft} from "react-icons/fi";
import { Form } from "react-bootstrap";
import { BusinessConditionActionType, BusinessConditionContext } from "../../../../../Contexts/business-condition/context";
import { Dispensary } from "../../../../../Types/Dispensary";

type Props = {
    pharmacies: LightDispensary[];
    page: number;
    totalPages: number;
    handlePageChange: (page: number) => void;
    row?: React.FC<{pharmacy: LightDispensary}>
    noCheck?: boolean
    onClick?: (pharmacy: LightDispensary) => void
}

const PharmaciesTable: React.FC<Props> = ({pharmacies, page, totalPages, handlePageChange, row: CustomRow = PharmacyRow, noCheck, onClick}) => {
    const currentPageRangeMin = ((page - 1) * 6) + 1
    const currentPageRangeMax = currentPageRangeMin + pharmacies.length - 1
    const {state, dispatch} = useContext(BusinessConditionContext);

    const totalCheck = useMemo(() => noCheck || !pharmacies.find(pharmacy => !state.dispensaryIds.includes(pharmacy.id)), [pharmacies, state?.dispensaryIds, noCheck])

    const toggleTotalChecked = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if(totalCheck)
            pharmacies.forEach(pharma => 
                dispatch({
                    type: BusinessConditionActionType.UNSELECT_DISPENSARY_ID,
                    payload: pharma.id
                })
            )
        else
            pharmacies.forEach(pharma => 
                dispatch({
                    type: BusinessConditionActionType.SELECT_DISPENSARY_ID,
                    payload: pharma.id
                })
            )
    }, [totalCheck, pharmacies])

    return (
        <div className="ms-2 me-2 mt-2">
            {
                !noCheck && <>
                    <div className="flex">
                        <Form.Check type="checkbox"
                            checked={totalCheck}
                            onChange={toggleTotalChecked}
                        />&nbsp;Sélectionner la page
                    </div>
                    <div className="grid grid-cols-4 text-xs sm:text-lg items-center my-1 sm:my-0">
                        <span>Sélectionné</span>
                        <span>Pharmacie</span>
                        <span>Adresse</span>
                        <span>Groupement</span>
                    </div>
                </>
            }
            {pharmacies.map(pharmacy => (
                <div key={pharmacy.id} className={onClick ? "cursor-pointer" : ""} onClick={() => onClick?.(pharmacy)}>
                    {pharmacy.business && <CustomRow key={pharmacy.id} pharmacy={pharmacy}/>}
                </div>
            ))}
            <div className="d-flex justify-content-end mt-2">
                <span>
                    {
                        page > 1 && (
                            <FiArrowLeft onClick={() => handlePageChange(+page - 1)} className="ms-4"/>
                        )
                    }
                    {currentPageRangeMin} - {currentPageRangeMax} sur {totalPages}
                    {
                        totalPages > page && (
                            <FiArrowRight onClick={() => handlePageChange(+page + 1)} className="ms-4"/>
                        )
                    }
                </span>

            </div>

        </div>
    );
}


export default PharmaciesTable;