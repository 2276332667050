import { Order } from '../../../Types/Order'
import React, { useCallback, useState } from 'react'
import {
  FiAlertTriangle,
  FiChevronDown,
  FiChevronUp,
  FiDownload,
} from 'react-icons/fi'
import { formatDate } from '../../../utils/helpers'
import { Button, Collapse, FloatingLabel, Form, Modal } from 'react-bootstrap'
import OrderDetailProduct from './OrderDetailProduct'
import { contactProblem } from '../actions'
import useToken from '../../../Hooks/useToken'
import FactureView from '../../FactureView'

const OrderDetail: React.FC<{ order: Order }> = ({ order }) => {
  const { loginUser } = useToken()

  const [open, setOpen] = useState<boolean>(false)

  const collapsedClassName = 'collapsed-order-detail'

  const [problemOpen, setProblemOpen] = useState<boolean>(false)
  const closeProblemModal = useCallback(() => setProblemOpen(false), [])
  const [modalObject, setModalObject] = useState('')
  const [modalMessage, setModalMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorContact, setErrorContact] = useState(false)

  const handleContactSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true)

    contactProblem(loginUser, {
      object: modalObject,
      message: modalMessage,
      order_id: order.id,
    })
      .then(({ ok }) => {
        setErrorContact(!ok)
        if (ok) {
          setProblemOpen(false)
          setModalObject('')
          setModalMessage('')
        }
      })
      .catch(() => setErrorContact(true))
      .finally(() => setLoading(false))
  }
  const [generatePdf, setGeneratePdf] = useState<React.ReactElement[]>([])

  const generateOrderPdf = useCallback(
    () => setGeneratePdf((list) => [...list, <FactureView order={order} />]),
    [order]
  )

  return (
    <div className="order-detail-container">
      <div onClick={() => setOpen(!open)} className="order-detail-header">
        <span>{order.provider.catalog.business.businessName}</span>
        <span>{formatDate(order.createdAt)}</span>
        <span
          className="pointer"
          aria-expanded={open}
          aria-controls={collapsedClassName}
        >
          {open ? <FiChevronUp /> : <FiChevronDown />}
        </span>
      </div>
      <Collapse in={open}>
        <div className={collapsedClassName}>
          <div className="grid grid-cols-2 sm:grid-cols-5 md:grid-cols-6 lg:grid-cols-9 xl:grid-cols-11 px-3">
            {order.orderProducts.map((product) => (
              <OrderDetailProduct key={product.id} product={product} />
            ))}
          </div>
          <div className="grid sm:flex justify-between">
            <button
              onClick={generateOrderPdf}
              className="text-green-600 w-100 d-flex align-items-center px-3 py-1 sm:py-3"
            >
              <FiDownload className="me-1" />
              <span className="text-decoration-underline pointer">
                Télécharger la facture
              </span>
            </button>

            <button
              onClick={() => setProblemOpen(true)}
              className="w-100 d-flex align-items-center px-3 py-1 pb-3 sm:py-3"
            >
              <FiAlertTriangle className="text-warning me-1" />
              <span className="text-warning text-decoration-underline pointer text-left">
                J'ai un problème avec cette commande
              </span>
            </button>
          </div>
        </div>
      </Collapse>
      <Modal show={problemOpen} onHide={closeProblemModal}>
        <form className="login-form" onSubmit={handleContactSubmit}>
          {errorContact && (
            <div className="rounded mb-2 w-full px-3 py-1.5 bg-red-500 text-center text-white font-medium">
              Le message n'a pas pu être envoyé
            </div>
          )}
          <FloatingLabel
            className="mb-1 mt-3 login-form-label"
            label="Object"
            controlId="object"
          >
            <Form.Control
              type="text"
              placeholder="votre objet"
              autoComplete="off"
              className="login-form-input"
              onChange={(e) => setModalObject(e.target.value)}
              value={modalObject}
              required
            />
          </FloatingLabel>
          <FloatingLabel
            className="mb-1 login-form-label"
            label="Votre message"
            controlId="message"
          >
            <Form.Control
              as="textarea"
              placeholder="votre contenu"
              autoComplete="off"
              className="login-form-input !h-52"
              onChange={(e) => setModalMessage(e.target.value)}
              value={modalMessage}
              required
            />
          </FloatingLabel>
          <Button
            variant="primary text-white mt-3"
            size="lg"
            type="submit"
            disabled={loading}
          >
            Envoyer
          </Button>
        </form>
      </Modal>
      {generatePdf}
    </div>
  )
}

export default OrderDetail
