import React from 'react'
import {Button, FormControl, InputGroup} from 'react-bootstrap'

function Counter({ count, setCount, gap }: any) {
  return (
    <InputGroup size="sm" className="flex-nowrap">
      <Button
        variant="outline-secondary"
        onClick={() => {
          if (count == 0) {
            return
          }
          setCount(Math.max(0, count - gap))
        }}
      >
        -
      </Button>
      <FormControl
        className="fw-bold text-center"
        onChange={(e) => {
          setCount(Math.max(0, parseInt(e.target.value)))
        }}
        type="number"
        value={count}
        min={0}
        
      />
      <Button variant="outline-secondary" onClick={() => setCount(count + gap - ((count + gap) % gap) )}>
        +
      </Button>
    </InputGroup>
  )
}

export default Counter
