import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { Product } from '../../Types/Product'
import useToken from '../../Hooks/useToken'
import { addToBasket, getProductById } from './actions'
import { Button } from 'react-bootstrap'
import productPlaceholder from '../../assets/images/product_placeholder.png'
import { LoadingComponent } from '../../Components/Loading'
import Counter from '../../Components/ItemCard/Counter'
import './style.css'
import { shrinkText } from '../../utils/helpers'
import { BasketActionType, BasketContext } from '../../Contexts/basket/context'
import { BusinessConditionType } from '../../Types/BusinessCondition'
import BusinessConditionsByType from './components/BusinessConditionsByType'
import useAuth from '../../Hooks/useAuth'
import { Roles } from '../../Types/Role'
import { removeBasketProduct } from '../CartView/actions'

const ProductView = () => {
  const [product, setProduct] = React.useState<Product>({} as Product)
  const [isLoading, setLoading] = React.useState(true)
  const [isAddingToBasket, setAddingToBasket] = React.useState(false)
  const [count, setCount] = React.useState<number>(0)

  const { dispatch, state } = useContext(BasketContext)

  const { user } = useAuth()

  const [description, setDescription] = React.useState<string>('')

  const { productId } = useParams()
  const { loginUser } = useToken()
  const navigate = useNavigate()

  const maxDescriptionLength = 420

  useEffect(() => {
    getProductById(productId, loginUser)
      .then((res) => {
        setProduct(res)
        setLoading(false)
        setDescription(shrinkText(res.description, maxDescriptionLength))
      })
      .catch(() => navigate('/404'))
  }, [productId])

  useEffect(() => {
    if (state.basket) {
      const basketProduct = state.basket.basketProducts.find(
        (item) => item.product.id === productId
      )
      if (basketProduct) {
        setCount(basketProduct.amount)
      }
    }
  }, [productId, state.basket?.id])

  const currentlyInBasket = useMemo(
    () =>
      state.basket?.basketProducts.find(
        (item) => item.product.id === productId
      ),
    [state.basket?.basketProducts, productId]
  )

  const seeMore = () => {
    if (description.length === maxDescriptionLength + 3) {
      return (
        <span
          role="button"
          className="product-see-more"
          onClick={() => setDescription(product.description)}
        >
          Voir plus
        </span>
      )
    } else {
      return (
        <span
          role="button"
          className="product-see-more"
          onClick={() =>
            setDescription(
              shrinkText(product.description, maxDescriptionLength)
            )
          }
        >
          Voir moins
        </span>
      )
    }
  }

  const handleAddToBasket = () => {
    setAddingToBasket(true)
    if (count == 0 && currentlyInBasket) {
      const basketProduct = state.basket?.basketProducts.find(
        (basketProduct) => basketProduct.product.id == product.id
      )
      if (basketProduct)
        removeBasketProduct(basketProduct.id, loginUser)
          .then((basket) =>
            dispatch({
              type: BasketActionType.GET_BASKET,
              payload: basket,
            })
          )
          .then(() => setAddingToBasket(false))
    } else if (count != 0)
      addToBasket(product, loginUser, count).then((basket) => {
        dispatch({ type: BasketActionType.GET_BASKET, payload: basket })
        setAddingToBasket(false)
      })
  }

  const removeFromCart = useCallback(() => {}, [
    dispatch,
    product.id,
    loginUser,
  ])

  return !isLoading ? (
    product.id ? (
      <>
        <div className="text-2xl lg:text-3xl font-semibold mb-2">
          {product.name}
        </div>
        <h3 className="product-price !text-2xl mb-3 lg:mb-5">
          {product.price}€
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
          <div className="border rounded-xl p-1">
            <img
              src={product.photo_url || productPlaceholder}
              alt={product.name}
              style={{ objectFit: 'contain' }}
              className="w-full h-full max-h-48 sm:max-h-full"
            />
          </div>
          <div className="border rounded-xl p-3 pt-2 text-left">
            <div className="text-xl lg:text-2xl lg:mb-2 font-semibold mb-2">
              Description
            </div>
            <span dangerouslySetInnerHTML={{ __html: description }} />
            {product.description.length > maxDescriptionLength
              ? seeMore()
              : null}
          </div>
          {!!product.usage?.length && (
            <div className="border rounded-xl p-3 pt-2 text-left">
              <div className="text-xl lg:text-2xl lg:mb-2 font-semibold mb-2">
                Usage
              </div>
              <span dangerouslySetInnerHTML={{ __html: product.usage }} />
            </div>
          )}
          {!!product.composition?.length && (
            <div className="border rounded-xl p-3 pt-2 text-left">
              <div className="text-xl lg:text-2xl lg:mb-2 font-semibold">
                Composition
              </div>
              <span dangerouslySetInnerHTML={{ __html: product.composition }} />
            </div>
          )}
          {!!product.properties?.length && (
            <div className="border rounded-xl p-3 pt-2 text-left">
              <div className="text-xl lg:text-2xl lg:mb-2 font-semibold mb-2">
                Propriétés
              </div>
              <span dangerouslySetInnerHTML={{ __html: product.properties }} />
            </div>
          )}
          {!!product.legal?.length && (
            <div className="border rounded-xl p-3 pt-2 text-left">
              <div className="text-xl lg:text-2xl lg:mb-2 font-semibold mb-2">
                Informations légales
              </div>
              <span dangerouslySetInnerHTML={{ __html: product.legal }} />
            </div>
          )}
          {product.businessConditions &&
            product.businessConditions.length > 0 && (
              <div className="border rounded-xl p-3 pt-2 text-left">
                <div className="text-xl lg:text-2xl lg:mb-2 font-semibold mb-2">
                  Conditions commerciales
                </div>
                <div className="w-full">
                  <div className="p-1">
                    <BusinessConditionsByType
                      product={product}
                      type={BusinessConditionType.PRODUCT_AMOUNT}
                    />
                  </div>
                  <div className="p-1">
                    <BusinessConditionsByType
                      product={product}
                      type={BusinessConditionType.SHIPPING_COST}
                    />
                  </div>
                </div>
              </div>
            )}
          {user.role === Roles.pharma && (
            <div className="border rounded-xl p-3 pt-2 text-left">
              <div className="text-xl lg:text-2xl lg:mb-2 font-semibold mb-2">
                Panier
              </div>
              <div className="flex items-center justify-between flex-col sm:flex-row">
                <Counter count={count} setCount={setCount} gap={product.pas} />
                <Button
                  style={{ width: '70%' }}
                  className="py-3 mt-[1rem] sm:!mt-0 sm:ml-3 !text-lg lg:!text-xl text-white shadow b-3"
                  variant="primary"
                  size="lg"
                  onClick={handleAddToBasket}
                >
                  {isAddingToBasket ? (
                    <LoadingComponent color="white" size={20} />
                  ) : currentlyInBasket ? (
                    count ? (
                      'Modifier'
                    ) : (
                      'Supprimer'
                    )
                  ) : (
                    'Ajouter au panier'
                  )}
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className="text-md lg:text-lg mt-3 lg:mt-5">
          {product.acl13 && `ACL13 : ${product.acl13}`}{' '}
          {product.ean13 && product.acl13 && '- '}
          {product.ean13 && `EAN13 : ${product.ean13}`}
        </div>
      </>
    ) : (
      <Navigate to={'/404'} />
    )
  ) : (
    <LoadingComponent />
  )
}

export default ProductView
