import React, {ReactNode, useEffect, useReducer} from "react";
import { BasketContext } from "./context";
import {basketReducer} from "./reducer";
import {getBasket} from "./actions";
import useToken from "../../Hooks/useToken";
import useAuth from "../../Hooks/useAuth";
import {Roles} from "../../Types/Role";


export const BasketProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(basketReducer,
        { basket: null, basketCount: 0, basketWithDiscounts: undefined});

    const { loginUser } = useToken();

    const { user } = useAuth();

    useEffect(() => {
        if (loginUser && user && user.role === Roles.pharma) {
            getBasket(loginUser)(dispatch);
        }
    }, [loginUser?.access_token, user?.role]);

    return (
        <BasketContext.Provider value={{ state, dispatch }}>
            {children}
        </BasketContext.Provider>
    );

}
