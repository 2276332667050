import { Button, FloatingLabel, Form } from "react-bootstrap";
import React from "react";
import { AddCatalogDto } from '../actions';

type Props = {
    handleNext: (values: Partial<AddCatalogDto>) => void;
    handlePrevious: () => void;
    inputs: any;
}

const FirstPageForm: React.FC<Props> = ({ handleNext, handlePrevious, inputs }) => {

    const [email, setEmail] = React.useState<string>(inputs.email || "");
    const [lastName, setLastName] = React.useState<string>(inputs.lastName || "");
    const [firstName, setFirstName] = React.useState<string>(inputs.firstName || "");
    const [phoneNumber, setPhoneNumber] = React.useState<string>(inputs.phoneNumber || "");
    const [emailError, setEmailError] = React.useState<string | null>(null);

    const validateEmail = (email: string) => {
        //LGBT colored RegEX to validate email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const handleSubmit = () => {
        // check validity of email, to have a way to contact user
        if (email.length > 0 && validateEmail(email)) {
            handleNext({
                email,
                lastName: lastName || "n/a",
                firstName: firstName || "n/a",
                phoneNumber: phoneNumber || "n/a"
            }); //assume value is n/a if nothing inserted by user
        } else {
            setEmailError("Veuillez fournir un e-mail valide.");
        }
    }

    return (
      <div>
          <div className={`p-2 ${email.length === 0 || !validateEmail(email) ? 'border border-danger' : 'border border-success'} rounded-3`}>
              <FloatingLabel
                className="mb-1 grey-form-label"
                label="Email du contact (obligatoire)"
                controlId="email"
              >
                  <Form.Control
                    type="text"
                    className={`grey-form-input ${email.length === 0 || !validateEmail(email) ? 'is-invalid' : ''}`}
                    placeholder="Email du contact"
                    name="email"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailError(null);
                    }}
                  />
                  {emailError && <Form.Control.Feedback type="invalid">{emailError}</Form.Control.Feedback>}
              </FloatingLabel>
          </div>

          <div className="p-2">
              <FloatingLabel
                className="mb-1 grey-form-label"
                label="Nom du contact"
                controlId="businessName"
              >
                  <Form.Control
                    type="text"
                    className="grey-form-input"
                    placeholder="Nom du contact"
                    name="name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
              </FloatingLabel>
          </div>

          <div className="p-2">
              <FloatingLabel
                className="mb-1 grey-form-label"
                label="Prénom du contact"
                controlId="firstName"
              >
                  <Form.Control
                    type="text"
                    className="grey-form-input"
                    placeholder="Prénom du contact"
                    name="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
              </FloatingLabel>
          </div>

          <div className="p-2">
              <FloatingLabel
                className="mb-1 grey-form-label"
                label="Numéro de téléphone"
                controlId="phoneNumber"
              >
                  <Form.Control
                    type="text"
                    className="grey-form-input"
                    placeholder="Numéro de téléphone"
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
              </FloatingLabel>
          </div>

          <div className="text-end">
              <Button variant="secondary me-3" onClick={handlePrevious}>
                  Précédent
              </Button>
              <Button className="text-white" variant="primary" onClick={handleSubmit}>
                  Suivant
              </Button>
          </div>
      </div>
    );
};

export default FirstPageForm;
