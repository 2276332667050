import {AccountValidation, User} from "../../../Types/User";
import React, {ReactNode} from "react";
import {Alert} from "react-bootstrap";


type Props = {
    user: User
}

const AccountNotValidatedBanner: React.FC<Props> = ({user}) => {

    const getWarnMessage = () : string | ReactNode => {
        if (user.adminValidated === AccountValidation.UNTREATED) {
            if (user.business.dispensary.hasIdCardDocument && user.business.dispensary.hasCpsCardDocument)
                return "Votre compte est actuellement en attente de validation";
            return "Votre compte est actuellement en attente de validation. Pour être valider, un document d'identité ainsi qu'une carte CPS doivent être téléchargés.";
        }
        if (user.adminValidated === AccountValidation.REJECTED) {
            return (
                <>
                    Votre compte a été refusé.<br /><br/>
                    {
                        (user.declinedReason && user.declinedReason.length > 0) && (
                            <>
                                <b>Raison: </b>{user.declinedReason}
                            </>
                        )
                    }
                </>
                    );
        }
        return user.adminValidated.toString();
    }

    const getVariant = () : "danger" | "success" | "warning" => {
        if (user.adminValidated === AccountValidation.UNTREATED) {
            return "warning";
        }
        return "danger";
    }

    return (
        <Alert variant={getVariant()} >
            <span>
                {getWarnMessage()}
            </span>
        </Alert>
    )
}


export default AccountNotValidatedBanner;