import deepEqual from 'deep-equal'
import {
  BusinessConditionAction,
  BusinessConditionState,
  BusinessConditionActionType,
} from './context'

export const businessConditionReducer = (
  state: BusinessConditionState,
  action: BusinessConditionAction
) => {
  switch (action.type) {
    case BusinessConditionActionType.SET_BUSINESS_CONDITION:
      console.log('default Provider', action.payload.provider)
      return {
        type: action.payload.type,
        steps: action.payload.steps,
        products: action.payload.products,
        dispensaryIds: action.payload.dispensaries.map(
          (dispensary) => dispensary.id
        ),
        networks: action.payload.networks,
        provider: action.payload.provider,
        freeUnitCap: action.payload.freeUnitCap,
      }
    case BusinessConditionActionType.TOGGLE_TYPE:
      return {
        ...state,
        steps: [],
        products: [],
        type: action.payload,
      }
    case BusinessConditionActionType.ADD_STEP:
      return {
        ...state,
        steps: [...state.steps, action.payload],
      }
    case BusinessConditionActionType.REMOVE_STEP:
      return {
        ...state,
        steps: state.steps.filter((other) => !deepEqual(other, action.payload)),
      }
    case BusinessConditionActionType.EDIT_FREE_UNIT_CAP:
      return {
        ...state,
        freeUnitCap: action.payload,
      }
    case BusinessConditionActionType.SET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      }
    case BusinessConditionActionType.REMOVE_PRODUCT:
      return {
        ...state,
        products: state.products.filter(
          (product) => product.id !== action.payload
        ),
      }
    case BusinessConditionActionType.ADD_PRODUCT:
      return {
        ...state,
        products: [
          ...state.products.filter((p) => p.id !== action.payload.id),
          action.payload,
        ],
      }
    case BusinessConditionActionType.SELECT_DISPENSARY_ID:
      return {
        ...state,
        dispensaryIds: [...state.dispensaryIds, action.payload],
      }
    case BusinessConditionActionType.UNSELECT_DISPENSARY_ID:
      return {
        ...state,
        dispensaryIds: state.dispensaryIds.filter(
          (id) => id !== action.payload
        ),
      }
    case BusinessConditionActionType.UNSELECT_ALL_DISPENSARY:
      return {
        ...state,
        dispensaryIds: [],
      }
    case BusinessConditionActionType.SET_DISPENSARY_IDS:
      return {
        ...state,
        dispensaryIds: action.payload,
      }
    case BusinessConditionActionType.UPDATE_NETWORKS:
      return {
        ...state,
        networks: action.payload,
      }
    case BusinessConditionActionType.UPDATE_PROVIDER:
      console.log(state.products)
      return {
        ...state,
        provider: action.payload,
        products: state.products.filter(
          (product) => product.provider.id === action.payload.id
        ),
      }
    case BusinessConditionActionType.ADD_PROVIDER:
      const providers = state.providers || []
      return {
        ...state,
        providers: [...providers, action.payload],
        // products: state.products.filter(
        //   (product) => product.provider.id === action.payload.id
        // ),
      }
    case BusinessConditionActionType.REMOVE_PROVIDER:
      return {
        ...state,
        providers: state.providers?.filter(
          (provider) => provider.id !== action.payload.id
        ),
        products: state.products.filter(
          (product) => product.provider.id !== action.payload.id
        ),
      }
  }
}
