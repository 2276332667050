import React, { useState, useEffect, useCallback } from 'react'
import {Button, FormControl, InputGroup} from 'react-bootstrap'
import useToken from '../../Hooks/useToken'
import { Product } from '../../Types/Product'
import { updatePas } from './actions'

function Counter({ product }: { product: Product }) {
  const [count, setCount] = useState(product.pas);
  const {loginUser} = useToken();
  useEffect(() => 
    setCount(product.pas)
  , [product.pas]);

  const updateCount = useCallback((pas: number) => {
    setCount(product.pas = pas);
    updatePas(loginUser, product);
  }, []);

  return (
    <InputGroup size="sm" className="flex-nowrap">
      <Button
        variant="outline-secondary"
        onClick={() => {
          if (count == 0) {
            return
          }
          updateCount(count - 1)
        }}
      >
        -
      </Button>
      <FormControl
        className="fw-bold text-center"
        onChange={e => 
          updateCount(count <= 0 ? 0 : +e.target.value)
        }
        type="number"
        value={count}
        min={1}
      />
      <Button variant="outline-secondary" onClick={() => updateCount(count + 1)}>
        +
      </Button>
    </InputGroup>
  )
}

export default Counter
