import { LoginUser, User } from '../../Types/User'
import { Roles } from '../../Types/Role'
import { get } from '../../Api/api'
import { getUser } from '../LoginView/actions'

const getFileNameFromHeader = (header?: string) => {
  if (!header) {
    return undefined
  }
  const matches = header.match(/filename="(.*)"/)
  return matches ? matches[1] : undefined
}

const getFileExtension = (fileName?: string) => {
  if (!fileName) {
    return undefined
  }

  const matches = fileName.match(/\.([^.]+)$/)
  return matches ? matches[1] : undefined
}

const handleSpecificDownload = async (
  login: LoginUser,
  user: User,
  currentUser: User,
  documentType: string,
  slug?: string
) => {
  const fileBlob = await get<{ file: Blob; contentDisposition: string }>(
    slug ||
      `/user/download/${
        user.id != currentUser.id ? 'admin' : 'dispensary'
      }/${documentType}${
        user.id != currentUser.id ? `?username=${user.email}` : ''
      }`,
    login,
    true,
    false,
    true
  )

  const fileName = getFileNameFromHeader(fileBlob.contentDisposition)
  const fileExtension = getFileExtension(fileName)

  const url = window.URL.createObjectURL(new Blob([fileBlob.file]))

  downloadFile(
    url,
    `${documentType}-${user.business.contactName}.${fileExtension}`
  )

  window.URL.revokeObjectURL(url)
}

export const downloadFile = (url: string, fileName?: string) => {
  if (!url) return
  // check if http
  if (!url.startsWith('http')) {
    url = 'http://' + url
  }
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  // the filename you want
  if (fileName) a.download = fileName
  document.body.appendChild(a)
  a.click()
}

export const handleDownloadIdCard = async (
  login: LoginUser,
  user: User,
  currentUser = user
) => {
  return handleSpecificDownload(login, user, currentUser, 'id-card')
}

export const handleDownloadCpsCard = async (
  login: LoginUser,
  user: User,
  currentUser = user
) => {
  return handleSpecificDownload(login, user, currentUser, 'cps-card')
}

export const handleDownloadRibDocs = async (
  login: LoginUser,
  user: User,
  currentUser = user
) => {
  return handleSpecificDownload(login, user, currentUser, 'rib-docs')
}

export const handleUploadIdCard = async (
  login: LoginUser,
  user: User,
  file: File,
  setUser: Function
) => {
  return handleUpload(login, user, file, setUser, 'id-card')
}

export const handleUploadCpsCard = async (
  login: LoginUser,
  user: User,
  file: File,
  setUser: Function
) => {
  return handleUpload(login, user, file, setUser, 'cps-card')
}

export const handleUploadRibDocs = async (
  login: LoginUser,
  user: User,
  file: File,
  setUser: Function
) => {
  return handleUpload(login, user, file, setUser, 'rib-docs') //see
}

export const handleUploadProviderCoverPhoto = async (
  login: LoginUser,
  user: User,
  file: File,
  setUser: Function
) => {
  return handleUpload(
    login,
    user,
    file,
    setUser,
    undefined,
    '/catalog/upload-cover-photo'
  )
}
export const handleUploadProviderLogo = async (
  login: LoginUser,
  user: User,
  file: File,
  setUser: Function
) => {
  return handleUpload(
    login,
    user,
    file,
    setUser,
    undefined,
    '/catalog/upload-provider-logo'
  )
}
export const handleUploadProviderPdfPresentation = async (
  login: LoginUser,
  user: User,
  file: File,
  setUser: Function
) => {
  return handleUpload(
    login,
    user,
    file,
    setUser,
    'pdf',
    '/catalog/upload-presentation-doc'
  )
}

export const handleUploadPDF = async (
  login: LoginUser,
  user: User,
  file: File,
  setUser: Function
) => {
  return handleUpload(login, user, file, setUser, 'pdf', '/url/upload-pdf')
}

const handleUpload = async (
  login: LoginUser,
  user: User,
  file: File,
  setUser: Function,
  type?: string,
  slug?: string
) => {
  const data = new FormData()
  data.append('file', file)

  const headers = new Headers()
  headers.append('Authorization', `Bearer ${login.access_token}`)

  await fetch(slug ? '/api' + slug : `/api/user/upload/dispensary/${type}`, {
    method: 'POST',
    headers,
    body: data,
  })

  const updatedUser = await getUser(login.access_token)
  setUser(updatedUser)
}

export interface UserInformation {
  header: string
  info: string
  isEditable: boolean
  id: string
}

export const getInfosForRole = (user: User): UserInformation[] => {
  const baseInfos = [
    {
      header: 'Nom du contact',
      info: user.business.contactName,
      isEditable: true,
      id: 'name',
    },
    {
      header: 'Prénom du contact',
      info: user.business.contactFirstName,
      isEditable: true,
      id: 'firstName',
    },
    {
      header: 'Email',
      info: user.email,
      isEditable: user.role == Roles.provider,
      id: 'email',
    },
    {
      header: 'Numéro de téléphone',
      info: user.business.phoneNumber,
      isEditable: true,
      id: 'phoneNumber',
    },
  ]

  const businessInfos = [
    ...baseInfos,
    {
      header: 'Nom',
      info: user.business.businessName,
      isEditable: true,
      id: 'businessName',
    },
    {
      header: 'Adresse',
      info: user.business.address,
      isEditable: true,
      id: 'address',
    },
    {
      header: 'Code postal',
      info: user.business.zipCode,
      isEditable: true,
      id: 'zipCode',
    },
    { header: 'Ville', info: user.business.city, isEditable: true, id: 'city' },
  ]

  switch (user.role) {
    case Roles.admin:
      return baseInfos
    case Roles.pharma:
      return [
        { ...businessInfos[0], isEditable: true },
        { ...businessInfos[1], isEditable: true },
        { ...businessInfos[2], isEditable: true },
        { ...businessInfos[3], isEditable: true },
        {
          header: 'Numéro RPPS',
          info: user.business.dispensary.rpps,
          isEditable: true,
          id: 'rpps',
        },
        {
          header: 'Nom du groupement',
          info: user.business.dispensary.dispensaryNetwork,
          isEditable: true,
          id: 'dispensaryNetwork',
        },
        {
          header: 'IBAN associé',
          info: user.business.dispensary.dispensaryIban,
          isEditable: true,
          id: 'dispensaryIban',
        },
        {
          header: 'Raison sociale',
          info: user.business.legalStatus,
          isEditable: true,
          id: 'legalStatus',
        },
      ]
    case Roles.provider:
      const medipimId =
        user.business.catalog.providers &&
        user.business.catalog.providers.length > 0
          ? user.business.catalog.providers.reduce(
              (acc, provider) =>
                acc + (acc === '' ? '' : ', ') + provider.medipimId,
              ''
            )
          : 'none'

      return [
        ...businessInfos,
        {
          header: 'Numéro de SIREN',
          info: user.business.siren,
          isEditable: true,
          id: 'siren',
        },
        {
          header: 'Numéros MEDIPIM',
          info: medipimId,
          isEditable: false,
          id: 'medipimId',
        },
        {
          header: 'E-mail de commande',
          info: user.business.orderMail,
          isEditable: true,
          id: 'orderMail',
        },
      ]
    default:
      return baseInfos
  }
}
