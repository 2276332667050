import jwtDecode from "jwt-decode";
import React, { useCallback, useMemo } from "react";
import { Button } from "react-bootstrap";
import { useHandleError } from "../../Hooks/useHandleError";
import useToken from "../../Hooks/useToken";
import { Slugs } from "../../utils/constants";
import { unimpersonateUser } from "../../Views/UserManagementView/actions";

const Impersonate: React.FC = () => {
    
    const {token, loginUser} = useToken();

    const loggedAs = useMemo(() => {
       if(token){
            const jwt = jwtDecode<{username: string, originalId: number}>(token);
            if(jwt.originalId !== undefined)
                return jwt.username;
       }
    }, [token])

    const {setToken} = useToken();
    const handleError = useHandleError();

    const unimpersonate = useCallback(() => {
        unimpersonateUser(loginUser).then(({access_token}) => {
            setToken({token: access_token});
            window.location.href = Slugs.ACCOUNT_MANAGEMENT;
        }).catch(handleError)
    }, [loginUser]);

    return loggedAs ? <div className='fixed top-20 z-50 left-0 w-full flex justify-center'>
        <div className="rounded-lg bg-neutral-600 text-white shadow max-w-[80%] text-center p-1.5 pl-2 select-none opacity-60 hover:!opacity-80 transition-opacity">
            Vous êtes connecté avec le compte de <span className='font-semibold'>{loggedAs}</span>
            <Button size="sm" className="text-white ml-3" style={{height: "fit-content"}} onClick={unimpersonate}>Se déconnecter</Button>
        </div>
    </div> : <></>
}

export {Impersonate}