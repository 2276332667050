import React, { ReactNode, useReducer } from 'react'
import { businessConditionReducer } from './reducer'
import { BusinessConditionType } from '../../Types/BusinessCondition'
import { BusinessConditionContext } from './context'

export const BusinessConditionProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [state, dispatch] = useReducer(businessConditionReducer, {
    products: [],
    steps: [],
    networks: [],
    provider: undefined,
    type: BusinessConditionType.SHIPPING_COST,
    dispensaryIds: [],
  })

  return (
    <BusinessConditionContext.Provider value={{ state, dispatch }}>
      {children}
    </BusinessConditionContext.Provider>
  )
}
