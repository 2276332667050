import { FiDownload, FiUpload } from 'react-icons/fi'
import { FiEdit } from 'react-icons/fi'
import React from 'react'

type Props = {
  isExisting: boolean
  handleUpload?: Function
  handleDownload?: Function
  children?: React.ReactNode
}

export const ProfileDocument: React.FC<Props> = (props) => {
  return (
    <div
      style={{
        maxHeight: '100%',
        paddingLeft: '2vw',
        paddingRight: '1vw',
        paddingTop: '1vw',
        maxWidth: '8vw',
      }}
    >
      {props.isExisting ? (
        <div className="d-flex justify-content-around">
          {props?.handleDownload ? (
            <span className="pointer">
              <FiDownload
                onClick={() => props?.handleDownload && props.handleDownload()}
              />
            </span>
          ) : null}
          {props.handleUpload && (
            <span className="pointer">
              <FiEdit onClick={() => props.handleUpload!()} />
            </span>
          )}
          {props.children}
        </div>
      ) : (
        <div className="d-flex justify-content-center pointer">
          {props.handleUpload && (
            <span onClick={() => props.handleUpload!()}>
              <FiUpload size={16} />
            </span>
          )}
        </div>
      )}
    </div>
  )
}
