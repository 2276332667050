import React from "react";
import {FiSearch} from "react-icons/fi";
import {FloatingLabel, Form} from "react-bootstrap";

type Props = {
    handleSearch: (search: string) => void;
    title: string;
    size?: number | string;
    ref?: React.Ref<HTMLInputElement>;
}

const SearchBox: React.FC<Props> = ({handleSearch, title, size, ref}) => {

    const [search, setSearch] = React.useState<string>("");

    const label = (
        <span className="d-flex align-items-center">
            <FiSearch className="me-2" />
            {title}
        </span>
    )

    const handleChange = (event: React.ChangeEvent) => {
        const {value} = event.target as HTMLInputElement;
        setSearch(value);
        handleSearch(value);
    }

    return (
        <div style={{width: size || "30vw"}} ref={ref}>
            <FloatingLabel
                className="grey-form-label"
                label={label}
                controlId="search"
            >
                <Form.Control
                    className="grey-form-input"
                    type="text"
                    size="sm"
                    placeholder="Rechercher une pharmacie"
                    autoComplete="off"
                    onChange={handleChange}
                    value={search}
                />
            </FloatingLabel>

        </div>
    )
}


export default SearchBox;