import React, { useState } from 'react'
import './style.scss'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/images/Philab-logo.png'
import pharmacyPicture from '../../assets/images/pharmacy.jpg'
import { Slugs } from '../../utils/constants'
import ProgressBarPage from './components/ProgressBarPage'
import FormProviderNamePage from './components/SignUpProviderView/FormProviderNamePage'
import FormAddressProviderPage from './components/SignUpProviderView/FormProviderAddressPage'
import { signUpProvider, SignUpProviderDto } from './actions'
import useToken from '../../Hooks/useToken'
import { useHandleError } from '../../Hooks/useHandleError'
import FormProviderPage from './components/SignUpProviderView/FormProviderPage'

const SignUpProviderView = () => {
  //state
  const [inputs, setInputs] = useState<FormValues>({})
  const navigate = useNavigate()

  const { loginUser } = useToken()
  const handleError = useHandleError()

  const [currentStep, setCurrentStep] = useState(0)

  //behavior
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const value = event.target.value
    setInputs((values) => ({ ...values, [name]: value }))
  }

  const handleSubmit = () => {
    signUpProvider(loginUser, inputs as SignUpProviderDto)
      .then(() => navigate(Slugs.HOME))
      .catch(handleError)
  }

  const getCurrentStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <FormProviderNamePage
            handleChange={handleChange}
            nextStep={() => setCurrentStep(1)}
            values={inputs}
          />
        )
      case 1:
        return (
          <FormProviderPage
            handleChange={handleChange}
            nextStep={() => setCurrentStep(2)}
            prevStep={() => setCurrentStep(0)}
            values={inputs}
          />
        )
      case 2:
        return (
          <FormAddressProviderPage
            handleChange={handleChange}
            nextStep={handleSubmit}
            prevStep={() => setCurrentStep(1)}
            values={inputs}
          />
        )
    }
  }

  //render
  return (
    <div className="d-flex main-container-login">
      <div className="form-container">
        <img src={logo} alt="logo" className="logo-login" />
        <div>
          <span className="title">
            <b>Inscription</b>
          </span>
          <ProgressBarPage currentStep={currentStep} totalSteps={3} />
          <form className="login-form">{getCurrentStep()}</form>
          <p className="text">
            {'Déjà un compte ? '}
            <span
              className="link pointer text-primary"
              onClick={() => navigate(Slugs.LOGIN)}
            >
              Connectez-vous
            </span>
          </p>
        </div>
      </div>
      <div className="img-container">
        <img src={pharmacyPicture} alt="pharmacy" className="img-pharmacy" />
      </div>
    </div>
  )
}

export default SignUpProviderView

export type FormStepProps = {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  nextStep: () => void
  prevStep?: () => void
  values: FormValues
}

export type FormValues = {
  [key: string]: string
}
