import {get, post} from "../../Api/api";
import {Product} from "../../Types/Product";
import {LoginUser} from "../../Types/User";
import {Basket} from "../../Types/Basket";

export const getProductById = async (id: string | undefined, login: LoginUser) => {
    if (!id || !id.length)
        throw new Error('Product id is required');
        
    return get<Product>("/product/detail/" + id, login);
}


export const addToBasket = async (product: Pick<Product, "id">, login: LoginUser, amount: number) => {
    return post<Basket>("/basket/add", login, {productId: product.id, amount});
}
