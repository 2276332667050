import React from "react";
import {BounceLoader} from "react-spinners";
import {LengthType} from "react-spinners/helpers/props";


export const LoadingComponent: React.FC<{color?: string; size?: LengthType}> = (props) => {
    return (
        <div className="d-flex justify-content-center">
            <BounceLoader color={props.color || "#5ecc78"} size={props.size}/>
        </div>
    )
}
