import React from "react";
import {FormSelect} from "react-bootstrap";
import { MinimalCatalog } from '../../Types/Catalog'

type Props = {
    catalogs: MinimalCatalog[],
    currentCatalog?: MinimalCatalog,
    updateCatalog: (catalogId: number) => void
}

export const SelectCatalog: React.FC<Props> = (props) => {

    // Find the current provider in the list of catalogs and put it first in the list
    const currentCatalog = props.catalogs.find(p => p.id === props.currentCatalog?.id);
    const catalogs = currentCatalog ? [currentCatalog, ...props.catalogs.filter(p => p.id !== currentCatalog.id)] : props.catalogs;


    return props.currentCatalog ? (
        <FormSelect aria-label={props.currentCatalog.name} size="lg" onChange={(value) => props.updateCatalog(Number(value.target.value))}>
            {catalogs.map((catalog: MinimalCatalog) => (
                <option key={catalog.id} value={catalog.id}>
                    {catalog.name}
                </option>
            ))}
        </FormSelect>
    ) : <></>;
}
