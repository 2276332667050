import { Product } from './Product'
import { Dispensary } from './Dispensary'
import { Provider } from './Provider'

export enum BusinessConditionType {
  SHIPPING_COST = 0,
  PRODUCT_AMOUNT = 1,
  FREE_UNIT = 2,
}

export type BusinessCondition = {
  id: number
  steps: BusinessConditionStep[]
  type: BusinessConditionType
  products: Product[]
  dispensaries: Dispensary[]
  networks: string[]
  provider: Provider
  freeUnitCap?: number
}

export type BusinessConditionStep = {
  discountRate: number
  minimumOrderAmount: number
  id: number
  products?: string[]
}
export type ProductBusinessConditionStep = BusinessConditionStep & {
  products: string[]
}

export type BusinessConditionStepDto = Omit<BusinessConditionStep, 'id'>

export type CreateConditionDto = {
  productIds: string[]
  steps?: BusinessConditionStepDto[]
  freeUnitCap?: number
  dispensaryIds: number[]
  type: BusinessConditionType
  providerId: number
  networks: string[]
}
