import React, {useEffect} from "react";
import SearchBox from "../../../../Components/SearchBox/SearchBox";
import {searchPharmacies} from "../../actions";
import useToken from "../../../../Hooks/useToken";
import AdvancedSelect from "./selected-pharmacies/AdvancedSelect";
import {useHandleError} from "../../../../Hooks/useHandleError";
import { fetchGroupements } from "../../../SignUpView/actions";
import NetworksTable from "./selected-pharmacies/NetworksTable";


const SelectedNetworks = () => {

    const [networks, setNetworks] = React.useState<string[]>([]);
    const [shownNetworks, setShownNetworks] = React.useState<string[]>([]);

    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [totalPages, setTotalPages] = React.useState<number>(0);

    const [previousSearchTerm, setPreviousSearchTerm] = React.useState<string>("");

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    }


    const {loginUser} = useToken();
    const handleError = useHandleError();

    useEffect(() => {
        setCurrentPage(1)
        setNetworks([]);
        fetchGroupements()
            .then(networks => {
                setNetworks(networks);
                setShownNetworks(networks);
                setTotalPages(Math.ceil(networks.length/6));
                setCurrentPage(1);
            })
            .catch(handleError);
    }, []);

    const handleSearch = (search: string) => {
        if (search.length > 0 && search.length > previousSearchTerm.length) {
            searchPharmacies(loginUser, search)
                .then(() => {
                    setCurrentPage(1);
                    const filtered = networks.filter(network => network.toLowerCase().includes(search.toLowerCase()))
                    setShownNetworks(filtered)
                    setTotalPages(Math.ceil(filtered.length / 6));
                })
                .catch(handleError);
        } else if (search.length === 0) {
            handlePageChange(1);
            setShownNetworks(networks)
            setTotalPages(Math.ceil(networks.length / 6));
        }
        
        setPreviousSearchTerm(search);
    }


    return (
        <div className="text-start">
            <span className="section-title">Groupements concernés</span>
            <div className="max-w-[500px]">
                <SearchBox size={"100%"} handleSearch={handleSearch} title="Rechercher un groupement" />
            </div>
            <AdvancedSelect />
            <NetworksTable handlePageChange={handlePageChange} networks={networks} shownNetworks={shownNetworks} page={currentPage} totalPages={totalPages} />
        </div>
    )
}





export default SelectedNetworks;
