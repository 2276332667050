import {BasketAction, BasketActionType, BasketContextState} from "./context";


export const basketReducer = (state: BasketContextState, action: BasketAction): BasketContextState => {
    switch (action.type) {
        case BasketActionType.GET_BASKET:
            return {
                basket: action.payload,
                basketWithDiscounts: state.basketWithDiscounts,
                basketCount: action.payload.basketProducts.reduce((acc, cur) => acc + cur.amount, 0),
            }
        case BasketActionType.UPDATE_DISCOUNT_BASKET:
            return {
                ...state,
                basketWithDiscounts: action.payload,
                basket: action.payload,
                basketCount: action.payload.basketProducts.reduce((acc, cur) => acc + cur.amount, 0),
            }
    }
};
