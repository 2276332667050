import {Role} from './Role'
import {Business} from './Business'

export type User = {
  id: number
  email: string
  passwordHash: string
  createdAt: Date
  adminValidated: AccountValidation
  mailValidated: boolean
  activationToken: string
  resetPasswordToken: string
  declinedReason?: string
  role: Role
  business: Business
  locked: boolean
}

export type LoginUser = {
  access_token: string;
}


export enum AccountValidation {
    UNTREATED,
    VALIDATED,
    REJECTED,
}