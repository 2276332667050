import {LoginUser} from "../../Types/User";
import {get, post} from "../../Api/api";

export type AddCatalogDto = {
    lastName: string;
    firstName: string;
    phoneNumber: string;
    email: string;
    businessAddress: string;
    businessCity: string;
    businessName: string;
    businessZipCode: string;
    siren: string;
    providers: { id: string, name: string }[];
}


export const addCatalog = (login: LoginUser, body: AddCatalogDto) => {
    return post<void>("/auth/register/catalog", login, body);
}

export const searchMedipimProvider = (login: LoginUser, medipimUsername: string) => {
    return get<{id: number, name: string}[]>(`/medipim/search?q=${medipimUsername}`, login);
}