import React, {useCallback, useMemo, useState} from "react";
import {Order} from "../../../Types/Order";
import {formatDate, shrinkText} from "../../../utils/helpers";
import {FiChevronDown, FiChevronUp} from "react-icons/fi";
import {Collapse} from "react-bootstrap";
import OrderDetailProduct from "../../DispensaryOrdersView/components/OrderDetailProduct";
import FactureView from "../../FactureView";


const SupplierDetailOrder: React.FC<{ order: Order }> = ({order}) => {
    const [open, setOpen] = useState<boolean>(false);

    const collapsedClassName = "collapsed-order-detail";

    const getTotalPrice = useMemo(() => {
        return Math.round(
            order.orderProducts.reduce((acc, product) => acc +
            product.reducedPrice
            , 0) * 100) / 100;
    }, [order.orderProducts.length]);

    const [generatePdf, setGeneratePdf] = useState<React.ReactElement[]>([])

    const generateOrderPdf = useCallback(() => 
        setGeneratePdf(list => [...list, <FactureView order={order} />])
    , [order])

    return (

        <div className="order-detail-container">
            <div onClick={() => setOpen(!open)} className="grid grid-cols-3 gap-1 relative px-2 py-1 cursor-pointer">
                <span className="text-base xl:text-lg">{shrinkText(order.dispensary.business.businessName, 20)}</span>
                <span className="text-base xl:text-lg">{getTotalPrice}€</span>
                <span className="text-base xl:text-lg">{formatDate(order.requestedDate)}</span>
                <span className="pointer absolute right-1 bottom-0.5"
                      aria-expanded={open}
                      aria-controls={collapsedClassName}
                >
                    {open ? <FiChevronUp/> : <FiChevronDown/>}
                </span>
            </div>
            <Collapse in={open}>
                <div className={collapsedClassName}>
                    <div className="order-detail-body">
                        {order.orderProducts.map(product => (
                            <OrderDetailProduct key={order.id+"-"+product.product.id} product={product}/>
                        ))}
                    </div>
                    <div>
                        <div className="w-full flex justify-end py-1 px-3">
                            <button onClick={generateOrderPdf} className="px-3 rounded-md bg-black/[8%] hover:bg-black/10 transition-colors py-0.5">Créer une facture</button>
                        </div>
                        {generatePdf}
                        <div>Commande passée le {formatDate(order.createdAt)}</div>
                    </div>
                </div>
            </Collapse>
        </div>

    );
}


export default SupplierDetailOrder;