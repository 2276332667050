import {LoginUser} from "../../Types/User";
import {get, HTTPRequestError, post} from "../../Api/api";

export type SignUpDispensaryDto = {
    firstName: string;
    name: string;
    businessName: string;
    email: string;
    password: string;
    phoneNumber: string;
    address: string;
    city: string;
    zipCode: string;
    legalStatus: string;
    rpps: string;
    dispensaryNetwork: string;
    dispensaryIban: string;
    captcha?: string;
}

export type SignUpProviderDto = {
    firstName: string;
    name: string;
    email: string;
    phoneNumber: string;
    providerName: string;
    password: string;
    address: string;
    city: string;
    zipCode: string;
    siren: string;
    captcha?: string;
}

export const signUpDispensary = (login: LoginUser, body: SignUpDispensaryDto) => {
    let captcha = body.captcha;
    if (body["captcha"]) {
        delete body["captcha"];
    }
    return post<void>(`/auth/register/dispensary${captcha ? `?recaptcha=${captcha}` : ""}`, login, body, false);
}

export const uploadSignupRibDoc = async (login:LoginUser, file: File, username:string) => {
    const data = new FormData();

    data.append('file', file);
    data.append('username',username)

    return post<void>('/user/upload/dispensary/rib-docs-signup',login,data, false)
};

export const signUpProvider = (login: LoginUser, body: SignUpProviderDto) => {
    let captcha = body.captcha;
    if (body["captcha"]) {
        delete body["captcha"];
    }
    return post<void>(`/auth/register/provider${captcha ? `?recaptcha=${captcha}` : ""}`, login, body, false);
}

const GROUPEMENTS_URL = '/api/auth/networks'

export const fetchGroupements = async () => {
    const res = await fetch(GROUPEMENTS_URL, {
        method: 'GET',
    })

    if (!res.ok) {
        try {
            throw new HTTPRequestError(res.status, await res.json());
        } catch {
            throw new HTTPRequestError(res.status, res.statusText);
        }
    }

    return await res.json() as string[];
}