import React from 'react';
import "./style.scss";
import OrderDetail from "./components/OrderDetail";
import {Order} from "../../Types/Order";
import {getOrders} from "./actions";
import useToken from "../../Hooks/useToken";
import {useHandleError} from "../../Hooks/useHandleError";


const DispensaryOrdersView = () => {

    const {loginUser} = useToken();
    const [orders, setOrders] = React.useState<Order[]>([]);
    const handleError = useHandleError();


    React.useEffect(() => {
        getOrders(loginUser)
            .then(orders => setOrders(orders))
            .catch(handleError);
    }, []);

    return (
        <div className="">
            <span className="page-title !text-3xl md:!text-5xl">Mes commandes</span>
            <div className="orders-container">
                {orders.map(order => (
                    <div className="mt-3">
                        <OrderDetail key={order.id} order={order}/>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default DispensaryOrdersView
