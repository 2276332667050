import {BounceLoader} from "react-spinners";
import React from "react";


export const LoadingView = () => {
    return (
        <div className="spinner-page d-flex justify-content-center align-items-center">
            <BounceLoader color="#5ecc78" />
        </div>
    )
}
