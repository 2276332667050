import {LoginUser} from "../Types/User";

export class HTTPRequestError extends Error {
    code: number;
    errorMessage: string;
    constructor(code: number, message: string) {
        super(message);
        this.code = code;
        this.name = "HTTP request error";
        this.errorMessage = message;
    }
}

type Method = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

async function api<T>(path: string, method: Method = "GET", user: LoginUser, body?: any, authorize: boolean = true, empty = false, json: boolean = true, fileName = false): Promise<T> {


    const headers = new Headers();

    if (authorize) {
        if (!user.access_token) {
            throw new Error("Token not found");
        }

        headers.append("Authorization", `Bearer ${user.access_token}`);
    }
    if (json) {
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
    }

    const res = await fetch("/api" + path, {
        method,
        headers,
        body: body ? JSON.stringify(body) : undefined,
    });
    if (!res.ok) {
        const json = await res.json();
        const errorMessage = json["message"] || res.statusText;
        throw new HTTPRequestError(res.status, errorMessage);
    }
    // handling void result
    if (res.headers.get("content-length") === "0" || res.status === 204 || empty) {
        return {} as T;
    }
    if (json) {
        return res.json();
    }
    if (!!res.headers.get("content-disposition") && fileName) {
        return {
            file: await res.blob(),
            contentDisposition: res.headers.get("content-disposition")
        } as any;
    }
    return (res.blob() as unknown) as T;
}

export async function get<T>(path: string, user: LoginUser, authorize: boolean = true, json: boolean = true, fileName: boolean = false): Promise<T> {
    return api<T>(path, "GET", user,undefined, authorize, false, json, fileName);
}

export async function del<T>(path: string, user: LoginUser, authorize: boolean = true, empty = false): Promise<T> {
    return api<T>(path, "DELETE", user,undefined, authorize, empty);
}

export async function post<T>(path: string, user: LoginUser, body: any, authorize: boolean = true): Promise<T> {
    return api<T>(path, "POST", user, body, authorize);
}

export async function put<T>(path: string, user: LoginUser, body: any = undefined, authorize: boolean = true): Promise<T> {
    return api<T>(path, "PUT", user, body, authorize);
}

export async function patch<T>(path: string, user: LoginUser, body: any = undefined, authorize: boolean = true): Promise<T> {
    return api<T>(path, "PATCH", user, body, authorize);
}



