import React, {Dispatch} from "react";
import {Basket, BasketProduct, BasketWithDiscounts} from "../../Types/Basket";


export type BasketContextState = {
    basket: Basket|null;
    basketWithDiscounts?: BasketWithDiscounts;
    basketCount: number;
}


export enum BasketActionType {
    GET_BASKET = "GET_BASKET",
    UPDATE_DISCOUNT_BASKET = "UPDATE_DISCOUNT_BASKET",
}

type GetBasketAction = {
    type: BasketActionType.GET_BASKET;
    payload: Basket;
}
type UpdateDiscountBasketAction = {
    type: BasketActionType.UPDATE_DISCOUNT_BASKET;
    payload: BasketWithDiscounts;
}

export type BasketAction = GetBasketAction | UpdateDiscountBasketAction

type BasketContextProps = {
    state: BasketContextState;
    dispatch: Dispatch<BasketAction>;
}

export const BasketContext = React.createContext<BasketContextProps>({} as BasketContextProps);
