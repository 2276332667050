import React from 'react'
import {User} from "../Types/User";
import useToken from "../Hooks/useToken";

interface AuthContextType {
  user: User
  setUser: React.Dispatch<any>
  signOut: () => void
}

export let AuthContext = React.createContext<AuthContextType>(null!)

export function useAuth() {
  return React.useContext(AuthContext)
}

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = React.useState<any>(null);

  const {setToken} = useToken();


  const signOut = () => {
    setUser(null);
    setToken(null);
  }

  let value = { user, setUser, signOut }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthProvider
