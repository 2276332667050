import {
  BasketProduct,
  BasketProductWithDiscounts,
} from '../../../Types/Basket'
import { Card } from 'react-bootstrap'
import productPlaceholder from '../../../assets/images/product_placeholder.png'
import { shrinkText } from '../../../utils/helpers'
import React, { useCallback, useContext, useMemo } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import {
  BasketActionType,
  BasketContext,
} from '../../../Contexts/basket/context'
import { Slugs } from '../../../utils/constants'
import { FiDelete, FiX } from 'react-icons/fi'
import { removeBasketProduct } from '../actions'
import useToken from '../../../Hooks/useToken'

export const ProductCard = (props: {
  basketProduct: BasketProduct | BasketProductWithDiscounts
}) => {
  const navigate = useNavigate()
  const { dispatch } = useContext(BasketContext)
  const { loginUser } = useToken()

  const removeFromCart = useCallback(() => {
    removeBasketProduct(props.basketProduct.id, loginUser).then((basket) =>
      dispatch({
        type: BasketActionType.UPDATE_DISCOUNT_BASKET,
        payload: basket,
      })
    )
  }, [dispatch, props.basketProduct, loginUser])

  const price = useMemo(
    () =>
      Math.round(
        parseFloat(props.basketProduct.product.price) *
          props.basketProduct.amount *
          100
      ) / 100,
    [props.basketProduct.product.price, props.basketProduct.amount]
  )

  const priceComponent = useMemo(() => {
    if (
      'reducedPrice' in props.basketProduct &&
      Number(props.basketProduct.reducedPrice) <
        Number(props.basketProduct.totalPrice)
    ) {
      return (
        <Card.Text className="product-price m-0">
          <div className="text-dark">
            <del>{props.basketProduct.totalPrice}€</del>
          </div>
          <div>{props.basketProduct.reducedPrice}€</div>
        </Card.Text>
      )
    }
    return <Card.Text className="product-price m-0">{price}€</Card.Text>
  }, [props.basketProduct, price])

  return (
    <div className="product-card">
      <Card className="text-start m-3 border-0">
        <NavLink to={`/product/${props.basketProduct.product.id}`}>
          <Card.Img
            variant="top"
            src={props.basketProduct.product.photo_url || productPlaceholder}
            height={80}
            className="shadow rounded bg-white"
            style={{ objectFit: 'cover' }}
          />
        </NavLink>
        <Card.Body className="product-card-body">
          <Card.Subtitle className="line-clamp-3">
            <NavLink to={`${Slugs.PRODUCT}/${props.basketProduct.product.id}`}>
              <span className="pointer">
                {shrinkText(props.basketProduct.product.name, 60)}{' '}
              </span>
            </NavLink>
          </Card.Subtitle>
          <div className="d-flex align-items-center">{priceComponent}</div>

          <Card.Text
            className="m-O p-0 flex items-center"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              {props.basketProduct.amount} unité
              {props.basketProduct.amount > 1 && 's'}
              <br />
              {props.basketProduct.freeUnitAmount > 0 &&
                ` + ${props.basketProduct.freeUnitAmount} gratuite${
                  props.basketProduct.freeUnitAmount > 1 ? 's' : ''
                }`}
            </div>
            <button
              className="ml-2 cursor-pointer hover:scale-110"
              onClick={removeFromCart}
            >
              <FiX />
            </button>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  )
}
