import React, { useEffect, useState } from 'react'
import useToken from '../../../Hooks/useToken'
import { getStock, refreshStock } from '../actions'
import { Button } from 'react-bootstrap'
import useToast from '../../../Hooks/useToast'
import { ProductCard } from '../../CartView/Components/ProductCard'
import ItemCard from '../../../Components/ItemCard'
import { LoadingComponent } from '../../../Components/Loading'

const StockDetail: React.FC = () => {
  const { loginUser } = useToken()

  const [stock, setStock] = useState([])
  const { showError, showSuccess } = useToast()

  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    setIsLoading(true)
    getStock(loginUser).then((res) => {
      console.log('stock', res)
      setStock(res)
      setIsLoading(false)
    })
  }, [])

  const actualiseStock = () => {
    setIsLoading(true)
    refreshStock(loginUser).then((res) => {
      console.log('stock', res)
      if (typeof res === 'number') {
        showError(
          `Vous ne pourrez actualiser vos produit que dans ${
            res / 1000
          } secondes`
        )
        // wait for the time to refresh
        setTimeout(() => {
          setIsLoading(false)
        }, res / 2)
      } else {
        showSuccess('Stock actualisé')
        setStock(res)
        setTimeout(() => {
          setIsLoading(false)
        }, 2000)
      }
    })
  }

  return (
    <div className="flex gap-2 flex-col items-center">
      {isLoading ? (
        <Button
          variant="primary"
          className="text-white w-48 mt-2 mb-2"
          disabled
        >
          <LoadingComponent size={20} color="white" />
        </Button>
      ) : (
        <Button
          variant="primary"
          className="text-white w-48 mt-2 mb-2"
          onClick={actualiseStock}
        >
          Actualiser le stock
        </Button>
      )}

      <div className="order-detail-container w-100">
        {stock && stock.length > 0 ? (
          <div className="flex flex-wrap gap-5 justify-between">
            {stock.map((product: any) => (
              <div className="w-64" key={product.id}>
                <ItemCard product={product.product} stock={product.stock} />
              </div>
            ))}
          </div>
        ) : (
          <>
            <div className="empty-orders">Aucun produit en stock</div>
          </>
        )}
      </div>
    </div>
  )
}

export default StockDetail
