import React from 'react';
import "./style.scss";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import {submitForm} from "./actions";
import useToken from "../../../Hooks/useToken";
import useToast from "../../../Hooks/useToast";
import {httpErrorHandler} from "../../../utils/errors";
import {Button} from "react-bootstrap";
import {LoadingComponent} from "../../../Components/Loading";
import {useNavigate} from "react-router-dom";

const ContactView = () => {

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [surname, setSurname] = React.useState("");
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [subject, setSubject] = React.useState("");
    const [message, setMessage] = React.useState("");

    const {loginUser} = useToken();
    const {showError, showSuccess} = useToast();
    const navigate = useNavigate();


    const handleSubmit = () => {
        setIsLoading(true);
        submitForm(loginUser, {surname, name, email, subject, message})
            .then(() => showSuccess("Votre message a bien été envoyé"))
            .catch(httpErrorHandler(showError, navigate))
            .finally(() => setIsLoading(false));
    }

    return (
        <>
            <div className="text-start mb-4">
                <span className="page-title">Nous contacter</span>
            </div>
            <div className="d-flex w-100">
                <div className="col-md-4 h-25">
                    <FloatingLabel className="mb-3 grey-form-label" label="Nom" controlId="name">
                        <Form.Control
                            type="text"
                            placeholder="nom"
                            aria-required
                            name="name"
                            className="grey-form-input"
                            onChange={e => setName(e.target.value)}
                        />
                    </FloatingLabel>
                    <FloatingLabel className="mb-3 grey-form-label" label="Prénom" controlId="surname">
                        <Form.Control
                            type="text"
                            placeholder="prénom"
                            name="surname"
                            aria-required
                            className="grey-form-input"
                            onChange={e => setSurname(e.target.value)}
                        />
                    </FloatingLabel>
                    <FloatingLabel className="mb-3 grey-form-label" label="Email" controlId="email">
                        <Form.Control
                            type="email"
                            placeholder="email"
                            name="email"
                            aria-required
                            className="grey-form-input"
                            onChange={e => setEmail(e.target.value)}
                        />
                    </FloatingLabel>

                </div>
                {/* Vertical divider */}
                <div className="m-4">
                    <div className="vertical-divider"></div>
                </div>
                <div className="col-md-8">
                    <FloatingLabel className="mb-3 grey-form-label" label="Sujet" controlId="subject">
                        <Form.Control
                            type="text"
                            placeholder="subject"
                            name="subject"
                            aria-required
                            className="grey-form-input"
                            onChange={e => setSubject(e.target.value)}
                        />
                    </FloatingLabel>

                    <FloatingLabel className="mb-3 grey-form-label" label="Message" controlId="message">
                        <Form.Control
                            as="textarea"
                            rows={15}
                            aria-required
                            placeholder="message"
                            draggable={false}
                            name="message"
                            className="grey-form-input h-100 resize-none"
                            onChange={e => setMessage(e.target.value)}
                        />
                    </FloatingLabel>
                    <div className="text-end">
                        {isLoading ? <Button variant="primary" disabled><LoadingComponent size={20}/></Button> :
                            <Button variant="primary"
                                    size="lg"
                                    className="text-white"
                                    onClick={handleSubmit}>Envoyer</Button>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}


export default ContactView
