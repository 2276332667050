import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const markdown = `# AVANTAGES PHARMACIEN

Ils sont nombreux.

Passation de commandes 24/24 et 7/7 : Philab permet tout d’abord au pharmacien de
gagner du temps. Il est désormais possible de passer une commande à n’importe quel
moment de la journée et en quelques minutes voire quelques secondes. Les horaires
d’ouverture des laboratoires ainsi que les horaires de travail des délégués pharmaceutiques
pouvant différer des vôtres, vous passerez votre commande quand vous le souhaiterez.
Philab calculera aussi pour vous le montant total et vous dira bien évidemment si le franco
est atteint.

PhiLab, c’est pratique. Ne vous trompez plus de produit ou de code. L’ensemble des
catalogues disposent des photos et des codes produits, mis à jour automatiquement et de
façon régulière. Vous ne recevrez donc plus ce que vous n’aurez pas commandé ! Ca paraît
évident, et pourtant ...

Vous avez l’habitude de déléguer ? C’est encore mieux ! N’importe quel de vos
collaborateurs pourra utiliser la plateforme sans aucun problème. PhiLab est accessible à
tous et pour tous (et quel que soit le niveau en informatique).

Vous entendez parler d’un produit ? Vous souhaitez le vendre ? Et vous ne savez comment
faire ni qui contacter ? Le produit sera sur PhiLab. Simple et facile.
N’attendez plus d’être en rupture ... Optimisez ! PhiLab est là pour vous permettre de passer
les réassorts dont vous avez besoin en quelques clics. N’attendez pas de retour. La
commande est reçue directement et sans intermédiaire au laboratoire, dès l’instant où vous
l’envoyez.

Achetez au bon prix ! Les remises négociées en début d’année par vos groupements (ou par
vous-même pour les indépendants) apparaissent sur PhiLab telles que négociées. Vous savez
ce que vous achetez, et surtout à quel prix. Pour les laboratoires dont vous n’êtes pas
(encore) partenaires et en fonction des conditions de chaque fournisseur, vous pourrez
passer commande directement au prix remisé, sans passer par un quelconque revendeur.

Découvrez et faîtes découvrir ! Chaque jour, de nouveaux laboratoires deviennent
partenaires de PhiLab et sont intégrés à la plateforme. Ainsi, vous pourrez découvrir de
nouvelles marques et de nouveaux produits à faire découvrir à vos patients.

`

function PharmaAdvView() {
  return (
    <ReactMarkdown
      className="staticText"
      children={markdown}
      remarkPlugins={[remarkGfm]}
    />
  )
}

export default PharmaAdvView
