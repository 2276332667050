import {LoginUser} from "../Types/User";
import {get} from "./api";

export const handleDownload = async (login: LoginUser, documentName: string, slug: string) => {
    const fileBlob = await get<{ file: Blob, contentDisposition: string }>(slug, login, true, false, true);

    const fileName = getFileNameFromHeader(fileBlob.contentDisposition);
    const fileExtension = getFileExtension(fileName);

    const url = window.URL.createObjectURL(
        new Blob([fileBlob.file]),
    );

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = `${documentName}.${fileExtension}`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
}

export const getFileNameFromHeader = (header?: string) => {
    if (!header) {
        return undefined;
    }
    const matches = header.match(/filename="(.*)"/);
    return matches ? matches[1] : undefined;
}

export const getFileExtension = (fileName?: string) => {
    if (!fileName) {
        return undefined;
    }

    const matches = fileName.match(/\.([^.]+)$/);
    return matches ? matches[1] : undefined;
}