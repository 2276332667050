import {useState} from 'react'

export default function useToken() {
    const getToken = () => {
        const tokenString = localStorage.getItem('token')
        const userToken = JSON.parse(tokenString as string)
        return userToken?.token
    }

    const [token, setToken] = useState(getToken())

    const saveToken = (userToken?: { token: string }|null) => {
        if (userToken) {
            localStorage.setItem('token', JSON.stringify(userToken))
            setToken(userToken.token)
        } else {
            setToken(null)
            localStorage.removeItem('token')
        }
    }

    return {token, setToken: saveToken, loginUser: {access_token: token}}
}
