import React, {useMemo} from "react";
import {Form} from "react-bootstrap";
import {
    BusinessConditionActionType,
    BusinessConditionContext
} from "../../../../../Contexts/business-condition/context";

type Props = {
    pharmacy: LightDispensary;
}

export type LightDispensary = {
    id: number;
    business: {
        businessName: string;
        address: string;
    }
    dispensaryNetwork: string;
}

const PharmacyRow: React.FC<Props> = ({pharmacy}) => {

    const {state, dispatch} = React.useContext(BusinessConditionContext);

    const isChecked = useMemo<boolean>(() => state.dispensaryIds.includes(pharmacy.id), [state.dispensaryIds.length]);

    const toggleChecked = () => {
        if (isChecked) {
            dispatch({type: BusinessConditionActionType.UNSELECT_DISPENSARY_ID, payload: pharmacy.id});
        } else {
            dispatch({type: BusinessConditionActionType.SELECT_DISPENSARY_ID, payload: pharmacy.id});
        }
    }

    return (
        <div className="pharmacy-row row w-100 d-flex">
            <span className="col">
                <Form.Check type="checkbox"
                            checked={isChecked}
                            onChange={toggleChecked}
                />
            </span>
            <span className="col">{pharmacy.business.businessName}</span>
            <span className="col">{pharmacy.business.address}</span>
            <span className="col">{pharmacy.dispensaryNetwork}</span>
        </div>
    );
}


export default PharmacyRow;