import React, { useState } from 'react'
import { Button, FloatingLabel, Form, ListGroup } from 'react-bootstrap'
import { AddCatalogDto, searchMedipimProvider } from '../actions'
import useToken from '../../../Hooks/useToken'
import { useHandleError } from '../../../Hooks/useHandleError'
import { AiOutlineCloseCircle } from 'react-icons/ai'

type Props = {
  handleNext: (values: Partial<AddCatalogDto>) => void;
  handlePrevious: () => void;
}


const ThirdPageForm: React.FC<Props> = ({ handlePrevious, handleNext }) => {

  const [selectedProviders, setSelectedProviders] = React.useState<{ id: string, name: string }[]>([])
  const [providers, setProviders] = React.useState<{ id: string, name: string }[]>([])
  const [searchingProvider, setSearchingProvider] = useState('')

  const searchBarRef = React.useRef<HTMLInputElement>(null)


  const { loginUser } = useToken()
  const handleError = useHandleError()

  const handleSubmit = () => {
    if (!!selectedProviders) {
      handleNext({ providers: selectedProviders });

    }
  }

  const handleSearchingProviderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setSearchingProvider(e.target.value)
    if (e.target.value.length >= 3) {
      const alreadySelectedIds = selectedProviders.map(p => p.id)
      searchMedipimProvider(loginUser, e.target.value)
        .then(results => setProviders(
          results
            // Filter already selected providers
            .filter(result => !alreadySelectedIds.includes(result.id.toString()))
            .map(result => {
              return { id: result.id.toString(), name: result.name }
            })
        ))
        .catch(handleError)
    }
  }

  const handleSelectProvider = (provider: { id: string, name: string }) => {
    if (!selectedProviders.map(p => p.id).includes(provider.id)) {
      setSelectedProviders(prevState => [...prevState, {
        id: provider.id,
        name: provider.name
      }])
    }
    setProviders([])
    setSearchingProvider('')
  }

  const handleDeleteProvider = (providerId: string) => {
    setSelectedProviders(prevState => [
      ...prevState.filter(provider => provider.id !== providerId)
    ])
  }

  return (
    <div>
      <div className='p-2 w-xl'>
        <FloatingLabel
          className='mb-1 grey-form-label'
          label='Identifiant Medipim'
          controlId='medipimUsername'
        >
          <Form.Control
            type='text'
            ref={searchBarRef}
            className='grey-form-input'
            placeholder='Identifiant Medipim'
            name='searchingProvider'
            value={searchingProvider}
            onChange={handleSearchingProviderChange}
          />
        </FloatingLabel>

        <p className="mt-2 text-muted">Pas encore d'identifiant ? Vous pourrez en rajouter un par la suite.</p>
        <ListGroup className='text-center'>
          {providers.map(provider => (
            <ListGroup.Item key={provider.id} className='pointer' onClick={() => {
              handleSelectProvider(provider)
            }}>
              {provider.name}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
      <div className='flex mt-2 px-1 mb-4 flex-wrap'>
        {selectedProviders.map(provider => (
          <Button
            className='m-1 !flex items-center space-x-1'
            onClick={() => handleDeleteProvider(provider.id)}
            key={provider.id}
          >
            <span>{provider.name}</span>
            <AiOutlineCloseCircle className='stroke-40' />
          </Button>
        ))}
      </div>
      <div className='text-end'>
        <Button variant='secondary me-3' onClick={handlePrevious}>
          Précédent
        </Button>
        <Button className='text-white' variant='primary' onClick={handleSubmit}>
          Suivant
        </Button>
      </div>
    </div>
  )
}

export default ThirdPageForm;