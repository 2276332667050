import {LoginUser} from "../../Types/User";
import {get, post} from "../../Api/api";
import {Order} from "../../Types/Order";
import {BasketProduct, BasketWithDiscounts} from "../../Types/Basket";

type CreateOrderDto = {
    basketProducts: BasketProduct[];
    requestedDate: Date;
}

export const submitOrder = async (payload: CreateOrderDto, login: LoginUser) => {
    // Date to YYYY-MM-DD
    const requestedDate = payload.requestedDate.toISOString().split('T')[0];


    return await post<Order>("/order", login, {
        requestedDate,
        orderProducts: payload.basketProducts.map(bp => ({
            productId: bp.product.id,
            amount: bp.amount
        }))
    });
}

export const getBasketWithDiscounts = async (basketId: number, login: LoginUser) => {
    return get<BasketWithDiscounts>("/basket/calculate/" + basketId, login);
}
export const removeBasketProduct = async (productId: number, login: LoginUser) => {
    return post<BasketWithDiscounts>("/basket/remove", login, {productId});
}