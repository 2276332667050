import React, { useEffect, useMemo, useState } from 'react'
import SearchBox from '../../../../Components/SearchBox/SearchBox'
import ProductBadge from './selected-product/ProductBadge'
import { Button, ListGroup, Overlay } from 'react-bootstrap'
import useToken from '../../../../Hooks/useToken'
import { getAllOwnProducts, searchProductByName } from '../../actions'
import { LoadingComponent } from '../../../../Components/Loading'
import {
  BusinessConditionActionType,
  BusinessConditionContext,
} from '../../../../Contexts/business-condition/context'
import { useHandleError } from '../../../../Hooks/useHandleError'
import { Product } from '../../../../Types/Product'
import ItemCard from '../../../../Components/ItemCard'

const SelectedProduct = ({ isEdition }: { isEdition: boolean }) => {
  const { loginUser } = useToken()

  const handleError = useHandleError()

  const { state, dispatch } = React.useContext(BusinessConditionContext)

  const [isLoading, setIsLoading] = React.useState(false)
  const [previousSearchTerm, setPreviousSearchTerm] = React.useState('')

  const [products, setProducts] = useState<Product[]>([])

  useEffect(() => {
    if (!isEdition) {
      let products: Product[] = []

      if (state.providers && state.providers.length) {
        console.log('searching all products')
        state.providers.forEach((provider) => {
          getAllOwnProducts(loginUser, provider.id)
            .then((providerProducts) => {
              products = [...products, ...providerProducts]
              setProducts(products)
            })
            .catch(handleError)
        })
      } else {
        console.log('no provider')
        setProducts([])
      }
    } else {
      getAllOwnProducts(loginUser, state.provider?.id)
        .then(setProducts)
        .catch(handleError)
    }
  }, [state.provider, state.providers])

  const handleAddProduct = (product: Product) =>
    dispatch({
      type: BusinessConditionActionType.ADD_PRODUCT,
      payload: product,
    })

  const handleRemoveProduct = (id: string) =>
    dispatch({ type: BusinessConditionActionType.REMOVE_PRODUCT, payload: id })

  const unselectedProducts = useMemo(
    () =>
      products.filter(
        (product) => !state.products.find((other) => other.id == product.id)
      ),
    [state.products, products]
  )

  return (
    <div>
      <div className="text-start">
        <span className="section-title">Produit</span>
      </div>
      <div className="grid md:grid-cols-2 gap-5">
        <div>
          <div className="w-full flex items-center mt-5 mb-3">
            <div className="w-1/2 h-0.5 bg-neutral-200" />
            <span className="flex-shrink-0 mx-3 text-lg">
              Produits sélectionnés ({state.products.length})
            </span>
            <div className="w-1/2 h-0.5 bg-neutral-200" />
          </div>
          {state.products.length ? (
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4">
              {state.products.map((product) => (
                <div
                  key={product.id}
                  className="cursor-pointer select-none"
                  onClick={() => handleRemoveProduct(product.id)}
                >
                  <ItemCard product={product} hideParams noLink />
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center">Aucun produit sélectionné</div>
          )}
        </div>
        <div>
          <div className="w-full flex items-center mt-5 mb-3">
            <div className="w-1/2 h-0.5 bg-neutral-200" />
            <span className="flex-shrink-0 mx-3 text-lg">
              Autres produits ({unselectedProducts.length})
            </span>
            <div className="w-1/2 h-0.5 bg-neutral-200" />
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4">
            {unselectedProducts.map((product) => (
              <div
                key={product.id}
                className="cursor-pointer select-none"
                onClick={() => handleAddProduct(product)}
              >
                <ItemCard product={product} hideParams noLink />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectedProduct
