import React, { useCallback, useState } from 'react'
import './style.scss'
import { FiUser } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'
import useAuth from '../../../Hooks/useAuth'
import HeaderLink from '../../../Components/Header/HeaderLink'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { Roles } from '../../../Types/Role'
import { HeaderCart } from '../../../Components/Header/HeaderCart'
import { Slugs } from '../../../utils/constants'
import logo from '../../../assets/images/Philab-logo.png'
import { AccountValidation } from '../../../Types/User'

const HeaderPharma = () => {
  return (
    <>
      {useAuth().user.adminValidated === AccountValidation.VALIDATED && (
        <>
          <HeaderLink
            className="!text-white sm:!text-inherit block my-3.5 sm:my-0"
            to={Slugs.HOME}
          >
            Accueil
          </HeaderLink>
          <HeaderLink
            className="!text-white sm:!text-inherit block my-3.5 sm:my-0"
            to={Slugs.ORDERS_DISPENSARY}
          >
            Mes commandes
          </HeaderLink>
          <HeaderLink
            className="!text-white sm:!text-inherit block my-3.5 sm:my-0"
            to={Slugs.STOCK}
          >
            Mon Stock
          </HeaderLink>
          <div className="hidden sm:block">
            <HeaderCart />
          </div>
        </>
      )}
      <HeaderLink
        className="!text-white sm:!text-inherit block my-3.5 sm:my-0"
        to={Slugs.PROFILE}
      >
        <FiUser className="hidden sm:block" size={24} />
        <div className="sm:hidden">Mon compte</div>
      </HeaderLink>
    </>
  )
}

function HeaderAdmin() {
  return (
    <>
      <HeaderLink
        className="!text-white sm:!text-inherit block my-3.5 sm:my-0"
        to={Slugs.HOME}
      >
        Accueil
      </HeaderLink>
      <HeaderLink
        className="!text-white sm:!text-inherit block my-3.5 sm:my-0"
        to={Slugs.ACCOUNT_MANAGEMENT}
      >
        Gérer les comptes
      </HeaderLink>
      <HeaderLink
        className="!text-white sm:!text-inherit block my-3.5 sm:my-0"
        to={Slugs.AJOUTER_CATALOGUE}
      >
        Ajouter un catalogue
      </HeaderLink>
      <HeaderLink
        className="!text-white sm:!text-inherit block my-3.5 sm:my-0"
        to={Slugs.ORDERS_ADMIN}
      >
        Commandes passées
      </HeaderLink>
      <HeaderLink
        className="!text-white sm:!text-inherit block my-3.5 sm:my-0"
        to={Slugs.PROFILE}
      >
        <FiUser className="cart-icon hidden sm:block" size={24} />
        <div className="sm:hidden">Mon compte</div>
      </HeaderLink>
    </>
  )
}

function HeaderSupplier() {
  return (
    <>
      <HeaderLink
        className="!text-white sm:!text-inherit block my-3.5 sm:my-0"
        to={Slugs.HOME}
      >
        Accueil
      </HeaderLink>
      <HeaderLink
        className="!text-white sm:!text-inherit block my-3.5 sm:my-0"
        to={Slugs.BUSINESS_CONDITIONS}
      >
        Conditions Commerciales
      </HeaderLink>
      <HeaderLink
        className="!text-white sm:!text-inherit block my-3.5 sm:my-0"
        to={Slugs.ORDERS_PROVIDER}
      >
        Commandes
      </HeaderLink>
      <HeaderLink
        className="!text-white sm:!text-inherit block my-3.5 sm:my-0"
        to={Slugs.PROFILE}
      >
        <FiUser className="cart-icon hidden sm:block" size={24} />
        <div className="sm:hidden">Mon compte</div>
      </HeaderLink>
    </>
  )
}

function HeaderDefault() {
  return (
    <HeaderLink
      className="!text-white sm:!text-inherit block my-3.5 sm:my-0"
      to={Slugs.LOGIN}
    >
      Se connecter
    </HeaderLink>
  )
}

function RenderHeader() {
  const auth = useAuth()
  return (
    <Nav className="!block sm:!flex justify-center">
      {(
        {
          [Roles.admin]: HeaderAdmin,
          [Roles.pharma]: HeaderPharma,
          [Roles.provider]: HeaderSupplier,
        }[auth?.user?.role] ?? HeaderDefault
      )()}
    </Nav>
  )
}

function HamburgerMenu() {
  const [open, setOpen] = useState(false)
  const toggleMenu = useCallback(() => {
    setOpen((open) => !open)
  }, [])
  return (
    <>
      <button onClick={toggleMenu}>
        <img src="/more.svg" alt="Menu" className="w-8 h-8" />
      </button>
      <div
        className={`fixed inset-0 z-50 w-screen h-screen bg-black/30 ${
          open ? 'opacity-100' : 'pointer-events-none opacity-0'
        } transition-all`}
        onClick={toggleMenu}
      ></div>
      <div
        className={`flex flex-col text-xl fixed inset-0 pl-3 z-50 w-[70vw] max-w-[300px] bg-neutral-700 h-screen text-left ${
          open
            ? 'opacity-100'
            : 'pointer-events-none opacity-0 -translate-x-full'
        } transition-all`}
        onClick={toggleMenu}
      >
        <NavLink to="/" className="mt-[11.5px]">
          <img src={logo} alt="Logo Philab" style={{ height: 70 }} />
        </NavLink>

        <RenderHeader />
      </div>
    </>
  )
}

function Header() {
  const auth = useAuth()
  return (
    <>
      <Navbar>
        <Container className="relative">
          <Navbar.Brand className="!text-base flex justify-between w-full sm:w-auto items-center">
            <NavLink to="/">
              <img src={logo} alt="Logo Philab" style={{ height: 70 }} />
            </NavLink>

            <div className="sm:hidden grid gap-3 grid-cols-2 place-items-center">
              {auth?.user?.role == Roles.pharma && (
                <HeaderLink to={Slugs.CART}>
                  <HeaderCart />
                </HeaderLink>
              )}
              <HamburgerMenu />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <div className="hidden sm:block">
            <Navbar.Collapse
              className="justify-content-end pl-header-menu !hidden sm:block"
              id="basic-navbar-nav"
            >
              <RenderHeader />
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>
    </>
  )
}

export default Header
