import React, { useCallback, useContext, useMemo } from "react";
import {FiArrowRight, FiArrowLeft} from "react-icons/fi";
import { Form } from "react-bootstrap";
import { BusinessConditionActionType, BusinessConditionContext } from "../../../../../Contexts/business-condition/context";

type Props = {
    networks: string[];
    shownNetworks: string[];
    page: number;
    totalPages: number;
    handlePageChange: (page: number) => void;
}

const NetworksTable: React.FC<Props> = ({networks, shownNetworks, page, totalPages, handlePageChange}) => {
    const currentPageRangeMin = (page - 1) * 6 + 1
    const currentPageRangeMax = Math.min(page * 6 + 1, shownNetworks.length)
    const {state, dispatch} = useContext(BusinessConditionContext);

    const totalCheck = useMemo(() => !shownNetworks.find(network => !state.networks.includes(network)), [shownNetworks, state.networks])

    const toggleTotalChecked = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if(totalCheck)
            dispatch({
                type: BusinessConditionActionType.UPDATE_NETWORKS,
                payload: networks.length == shownNetworks.length ? [] : state.networks.filter(oldNetworks => !shownNetworks.includes(oldNetworks))
            })
        else {
            dispatch({
                type: BusinessConditionActionType.UPDATE_NETWORKS,
                payload: networks.length == shownNetworks.length ? networks : Array.from(new Set([...shownNetworks, ...state.networks]))
            })
        }
    }, [totalCheck, state.networks, networks, shownNetworks])

    return (
        <div className="ms-2 me-2 mt-2">
            <div className="flex">
                <Form.Check type="checkbox"
                    checked={totalCheck}
                    onChange={toggleTotalChecked}
                />&nbsp;{networks.length == shownNetworks.length ? "Tout sélectionner" : "Sélectionner la page"}
            </div>
            <div className="row">
                <span className="col">Sélectionné</span>
                <span className="col">Groupement</span>
            </div>
            {shownNetworks.slice((page - 1) * 6, page * 6).map(network => (
                <div key={network}>
                    <NetworkRow network={network} />
                </div>
            ))}
            <div className="d-flex justify-content-end mt-2">
                <span>
                    {
                        page > 1 && (
                            <FiArrowLeft onClick={() => handlePageChange(+page - 1)} className="ms-4"/>
                        )
                    }
                    {currentPageRangeMin} - {currentPageRangeMax} sur {totalPages}
                    {
                        totalPages > page && (
                            <FiArrowRight onClick={() => handlePageChange(+page + 1)} className="ms-4"/>
                        )
                    }
                </span>

            </div>

        </div>
    );
}
const NetworkRow: React.FC<{network: string}> = ({network}) => {

    const {state, dispatch} = React.useContext(BusinessConditionContext);

    const isChecked = useMemo<boolean>(() => state.networks.includes(network), [state.networks, network]);

    const toggleChecked = () => {
        if (!isChecked)
            dispatch({type: BusinessConditionActionType.UPDATE_NETWORKS, payload: Array.from(new Set([...state.networks, network]))});
        else
            dispatch({type: BusinessConditionActionType.UPDATE_NETWORKS, payload: state.networks.filter(other => other != network)});
    }

    return (
        <div className="pharmacy-row row w-100 d-flex">
            <span className="col">
                <Form.Check type="checkbox"
                            checked={isChecked}
                            onChange={toggleChecked}
                />
            </span>
            <span className="col">{network}</span>
        </div>
    );
}


export default NetworksTable;