import React from 'react'
import { BusinessConditionStepDto } from '../../../../../Types/BusinessCondition'
import { Form } from 'react-bootstrap'
import { FiPlusCircle } from 'react-icons/fi'

type Props = {
  handleSubmit: (payload: BusinessConditionStepDto) => void
  unitaire: boolean
}

const DiscountStepForm: React.FC<Props> = ({ handleSubmit, unitaire }) => {
  const [discountValue, setDiscountValue] = React.useState<number>(0)
  const [minValue, setMinValue] = React.useState<number>(0)

  const handleSubmitStep = () => {
    handleSubmit({
      discountRate: discountValue,
      minimumOrderAmount: minValue,
    })
    setDiscountValue(0)
    setMinValue(0)
  }

  return (
    <div className="d-flex align-items-center">
      <div className="d-flex flex-column align-items-start me-4">
        <Form.Label>Réduction (%)</Form.Label>
        <Form.Control
          className="grey-form-input discount-step-form"
          type="number"
          size="sm"
          placeholder="Pourcentage de réduction"
          autoComplete="off"
          onChange={(e) => setDiscountValue(Number(e.target.value))}
          value={discountValue}
        />
      </div>
      <div className="d-flex flex-column align-items-start ms-4">
        <Form.Label>
          {unitaire ? 'Quantité minimum' : 'Montant minimum (€)'}
        </Form.Label>
        <Form.Control
          className="grey-form-input discount-step-form"
          type="number"
          size="sm"
          placeholder={
            (unitaire ? 'Quantité minimum' : 'Montant minimum (€)') +
            ' pour la réduction'
          }
          autoComplete="off"
          onChange={(e) => setMinValue(Number(e.target.value))}
          value={minValue}
        />
      </div>
      <span onClick={handleSubmitStep} className="ms-3 pointer">
        <FiPlusCircle />
      </span>
    </div>
  )
}

export default DiscountStepForm
