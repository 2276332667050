import React, { useState } from 'react'
import { get } from '../../../Api/api'
import useToken from '../../../Hooks/useToken'

interface ProductPopupProps {
  handleCreateProduct: (productData: any) => void
  onCancel: () => void
}

const ProductPopup: React.FC<ProductPopupProps> = ({
  handleCreateProduct,
  onCancel,
}) => {
  const [productData, setProductData] = useState({
    medipimId: '',
    name: '',
    description: '',
    usage: '',
    composition: '',
    properties: '',
    legal: '',
    ean13: '',
    acl13: '',
    photo_url: '',
    price: '',
    prescription_needed: false,
    is_visible: true,
    pas: '',
    providerId: '',
    vat_rate: '',
  })

  const { loginUser } = useToken()

  const [businesses, setBusinesses] = useState<any[]>([])
  React.useEffect(() => {
    // Fetch businesses from the backend when the component mounts
    const fetchBusinesses = async () => {
      try {
        const businessesData = await get<any[]>(
          '/provider/namelist',
          loginUser,
          true,
          true
        )
        setBusinesses(businessesData)
      } catch (error) {
        console.error('Error fetching businesses:', error)
      }
    }
    fetchBusinesses()
  }, [])

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target
    setProductData({ ...productData, [name]: value })
  }

  const handleBusinessSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    setProductData({ ...productData, providerId: value })
  }

  const handleCheckboxChange = (e: { target: { name: any; checked: any } }) => {
    const { name, checked } = e.target
    setProductData({ ...productData, [name]: checked })
  }

  const handleSubmit = () => {
    if (
      !productData.name ||
      !productData.description ||
      !productData.price ||
      !productData.pas ||
      !productData.vat_rate ||
      productData.providerId.trim() === ''
    ) {
      alert('Veuillez remplir tous les champs obligatoires.')
      return // Exit the function if any required field is empty
    }
    handleCreateProduct(productData)
  }

  return (
    <>
      <div className="popupOverlay" onClick={onCancel}></div>
      <div className="popup">
        <h1>Information du produit</h1>
        <div className="popup-inner">
          {/* Input fields for product attributes */}
          <h3>L'identifiant du produit sera généré automatiquement.</h3>
          <div className="select-wrapper">
            <select
              name="providerId"
              value={productData.providerId}
              onChange={handleBusinessSelect}
              // placeholder="Sélectionner une entité"
            >
              <option value="">Sélectionner une entité</option>
              {businesses.map((business) => (
                <option key={business.id} value={business.id}>
                  {`${business.id} - ${business.name}`}
                </option>
              ))}
            </select>
          </div>

          <input
            type="text"
            name="name"
            value={productData.name}
            onChange={handleChange}
            placeholder="Nom"
          />
          <textarea
            name="description"
            value={productData.description}
            onChange={handleChange}
            placeholder="Description"
          />
          <textarea
            name="usage"
            value={productData.usage}
            onChange={handleChange}
            placeholder="Usage"
          />
          <textarea
            name="composition"
            value={productData.composition}
            onChange={handleChange}
            placeholder="Composition"
          />
          <textarea
            name="properties"
            value={productData.properties}
            onChange={handleChange}
            placeholder="Propriétés"
          />
          <textarea
            name="legal"
            value={productData.legal}
            onChange={handleChange}
            placeholder="Légal"
          />
          <input
            type="text"
            name="ean13"
            value={productData.ean13}
            onChange={handleChange}
            placeholder="EAN13"
          />
          <input
            type="text"
            name="acl13"
            value={productData.acl13}
            onChange={handleChange}
            placeholder="ACL13"
          />
          <input
            type="text"
            name="photo_url"
            value={productData.photo_url}
            onChange={handleChange}
            placeholder="Photo URL"
          />
          <input
            type="text"
            name="price"
            value={productData.price}
            onChange={handleChange}
            placeholder="Prix (euros)"
          />
          <input
            type="number"
            name="pas"
            value={productData.pas}
            onChange={handleChange}
            placeholder="Pas"
          />
          <input
            type="number"
            name="vat_rate"
            value={productData.vat_rate}
            onChange={handleChange}
            placeholder="Taux TVA (%)"
          />

          {/* Checkbox for prescription_needed */}

          <div id="checkboxContainer">
            Ordonnance requise :
            <input
              type="checkbox"
              name="prescription_needed"
              checked={productData.prescription_needed}
              onChange={handleCheckboxChange}
            />
          </div>

          {/* Buttons */}
          <button className="validate" onClick={handleSubmit}>
            Valider
          </button>
          <button className="cancel" onClick={onCancel}>
            Annuler
          </button>
        </div>
      </div>
    </>
  )
}

export default ProductPopup
