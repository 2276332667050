import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { BounceLoader } from 'react-spinners'
import SearchBox from '../../Components/SearchBox/SearchBox'
import { useHandleError } from '../../Hooks/useHandleError'
import useToken from '../../Hooks/useToken'
import { Business } from '../../Types/Business'
import { BusinessCondition } from '../../Types/BusinessCondition'
import { Dispensary } from '../../Types/Dispensary'
import {
  getAllPharmaciesPaginated,
  getBusinessConditions,
  searchPharmacies,
} from './actions'
import BusinessConditionsView from './BusinessConditionsView'
import PharmaciesTable from './components/create-condition-components/selected-pharmacies/PharmaciesTable'
import { LightDispensary } from './components/create-condition-components/selected-pharmacies/PharmacyRow'

const BusinessConditionsDispensariesList: React.FC = () => {
  const [businessConditions, setBusinessConditions] = useState<
    BusinessCondition[]
  >([])
  const [bcLoading, setBCLoading] = useState(true)
  const [pharmaciesLoading, setPharmaciesLoading] = useState(true)
  const loading = useMemo(
    () => bcLoading && pharmaciesLoading,
    [bcLoading, pharmaciesLoading]
  )

  const { loginUser } = useToken()
  const handleError = useHandleError()

  useEffect(() => {
    getBusinessConditions(loginUser)
      .then(setBusinessConditions)
      .then(() => setBCLoading(false))
      .catch(handleError)
  }, [loginUser.access_token])

  const [pharmacies, setPharmacies] = React.useState<
    (Dispensary | LightDispensary)[]
  >([])

  const [currentPage, setCurrentPage] = React.useState<number>(1)
  const [totalPages, setTotalPages] = React.useState<number>(0)

  const [previousSearchTerm, setPreviousSearchTerm] = React.useState<string>('')

  const [selectedPharmacy, setSelectedPharmacy] = useState<LightDispensary>()
  const [pharmacyModalOpen, setPharmacyModalOpen] = useState(false)

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    setPharmacies([])
    getAllPharmaciesPaginated(loginUser, {
      page,
      pageSize: 6,
    })
      .then((r) => {
        setPharmacies(r.data)
        setTotalPages(Math.ceil(r.total / 6))
        setCurrentPage(r.page)
      })
      .then(() => setPharmaciesLoading(false))
  }

  useEffect(() => {
    handlePageChange(1)
  }, [])

  const handleSearch = (search: string) => {
    if (search.length > 0 && search.length > previousSearchTerm.length) {
      searchPharmacies(loginUser, search)
        .then((r) => {
          setCurrentPage(1)
          setTotalPages(1)
          setPharmacies(
            r.map((p) => ({
              id: p.id,
              dispensaryNetwork: p.dispensaryNetwork,
              business: {
                businessName: p.businessName,
                address: p.address,
              } as Business,
            }))
          )
        })
        .catch(handleError)
    } else if (search.length === 0) {
      handlePageChange(1)
    }
    setPreviousSearchTerm(search)
  }

  const line = useCallback(
    (props: any) => (
      <PharmacyBusinessConditionLine {...props} bcs={businessConditions} />
    ),
    [businessConditions]
  )

  const openPharmacyModal = useCallback((pharmacy: LightDispensary) => {
    setSelectedPharmacy(pharmacy)
    setPharmacyModalOpen(true)
  }, [])

  return loading ? (
    <div className="flex justify-center">
      <BounceLoader color="#5ecc78" />
    </div>
  ) : (
    <div className="text-start">
      <span className="section-title">Pharmacies concernées</span>
      <div className="max-w-[500px]">
        <SearchBox
          size={'100%'}
          handleSearch={handleSearch}
          title="Rechercher une pharmacie"
        />
      </div>
      <div className="ms-2 me-2 mt-2">
        <div className="grid grid-cols-4 text-xs sm:text-lg items-center">
          <span>Pharmacie</span>
          <span>Adresse</span>
          <span>Groupement</span>
          <span>Conditions appliquées</span>
        </div>
      </div>

      <PharmaciesTable
        row={line}
        pharmacies={pharmacies}
        page={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        noCheck
        onClick={openPharmacyModal}
      />

      <Modal
        contentClassName="p-3 py-2"
        dialogClassName="sm:!w-[80vw] !max-w-[900px]"
        show={pharmacyModalOpen}
        onHide={() => setPharmacyModalOpen(false)}
      >
        <BusinessConditionsView
          pharmacy={selectedPharmacy}
          loadedBusinessConditions={businessConditions}
        />
      </Modal>
    </div>
  )
}

const PharmacyBusinessConditionLine: React.FC<{
  pharmacy: LightDispensary
  bcs: BusinessCondition[]
}> = ({ pharmacy, bcs }) => {
  const applied = useMemo(
    () =>
      bcs.reduce(
        (prev, bc) =>
          prev +
          +!!(
            bc.networks.includes(pharmacy.dispensaryNetwork) ||
            bc.dispensaries.find((pharma) => pharma.id == pharmacy.id)
          ),
        0
      ),
    [pharmacy, bcs]
  )
  return (
    <div className="pharmacy-row row w-100 d-flex hover:bg-gray-300">
      <span className="col">{pharmacy.business.businessName}</span>
      <span className="col">{pharmacy.business.address}</span>
      <span className="col">{pharmacy.dispensaryNetwork}</span>
      <span className="col font-semibold">{applied}</span>
    </div>
  )
}

export default BusinessConditionsDispensariesList
