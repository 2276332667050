import {LoginUser} from "../../../Types/User";
import {post} from "../../../Api/api";

type ContactForm = {
    email: string;
    message: string;
    subject: string;
    name: string;
    surname: string;
}

export const submitForm = (login: LoginUser, dto: ContactForm) => {
    return post<void>("/contact/", login, dto);
}