import { Button, Form, ListGroup, Modal } from 'react-bootstrap'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { BasketContext } from '../../../Contexts/basket/context'
import { LoadingComponent } from '../../../Components/Loading'
import { submitOrder } from '../actions'
import useToken from '../../../Hooks/useToken'
import { getBasket } from '../../../Contexts/basket/actions'
import useToast from '../../../Hooks/useToast'
import { httpErrorHandler } from '../../../utils/errors'
import { useNavigate } from 'react-router-dom'
import { Catalog } from '../../../Types/Catalog'
import { Provider } from '../../../Types/Provider'
import { BasketProductWithDiscounts } from '../../../Types/Basket'

type Props = {
  show: boolean
  handleClose: () => void
  requestedDate: Date
  catalogs?: Catalog[]
}

export const SubmitModal: React.FC<Props> = (props) => {
  const { state, dispatch } = useContext(BasketContext)
  const basket = state.basketWithDiscounts!
  const { loginUser } = useToken()

  const { showError } = useToast()
  const navigate = useNavigate()

  const pickedForOrder = basket.basketProducts

  const totalPrice = useMemo(
    () =>
      pickedForOrder
        .map((i) => i.totalPrice)
        .reduce((a, b) => a + parseFloat(b), 0)
        .toFixed(2),
    [pickedForOrder]
  )

  const reducedPrice = useMemo(
    () =>
      pickedForOrder
        .map((i) => i.reducedPrice)
        .reduce((a, b) => a + parseFloat(b), 0)
        .toFixed(2),
    [pickedForOrder]
  )

  const priceComponent = useMemo(() => {
    if (reducedPrice >= totalPrice)
      return <span className="tab-title">Total: {totalPrice}€</span>

    return (
      <span className="tab-title">
        Total: <del className="text-dark">{totalPrice}€</del> {reducedPrice}€
      </span>
    )
  }, [totalPrice, reducedPrice])

  const refreshBasket = useCallback(
    async () => await getBasket(loginUser)(dispatch),
    [loginUser, dispatch]
  )

  const onSubmit = () => {
    setIsOrderSubmitted(true)
    submitOrder(
      {
        requestedDate: props.requestedDate,
        basketProducts: state.basket?.basketProducts ?? [],
      },
      loginUser
    )
      .then(() =>
        refreshBasket().then(() => {
          setIsOrderSubmitted(false)
          props.handleClose()
        })
      )
      .catch((err) => {
        httpErrorHandler(showError, navigate)(err)
        setIsOrderSubmitted(false)
        props.handleClose()
      })
  }

  const catalogProvidersProducts = useMemo(() => {
    if (props.catalogs) {
      const catalogProvidersProducts: {
        catalog: Catalog
        providersProducts: {
          provider: Provider | null // Allow null for products without a provider
          products: BasketProductWithDiscounts[]
        }[]
      }[] = []
      for (const catalog of props.catalogs) {
        const providersProducts: {
          provider: Provider | null // Allow null for products without a provider
          products: BasketProductWithDiscounts[]
        }[] = []
        // Push products without a provider
        const productsWithoutProvider = basket.basketProducts.filter(
          (product) =>
            product.product.provider === null &&
            product.product.businessId === catalog.business.id
        )
        if (productsWithoutProvider.length > 0) {
          providersProducts.push({
            provider: null,
            products: productsWithoutProvider,
          })
        }
        // Push products with a provider
        catalog.providers?.forEach((provider) =>
          providersProducts.push({
            provider,
            products: basket.basketProducts.filter(
              (product) =>
                product.product.provider &&
                product.product.provider.id === provider.id
            ),
          })
        )
        catalogProvidersProducts.push({ catalog, providersProducts })
      }
      return catalogProvidersProducts
    }
  }, [props.catalogs, basket.basketProducts])

  const [isOrderSubmitted, setIsOrderSubmitted] = useState<boolean>(false)
  const [cguCheck, setCguCheck] = useState<boolean>(false)

  const toggleCguCheck = useCallback(
    () => setCguCheck((cguCheck) => !cguCheck),
    []
  )

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmer la commande</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {catalogProvidersProducts?.map(({ catalog, providersProducts }) => (
          <div key={catalog.id}>
            <div className="mb-0.5 font-medium text-lg">
              {catalog.business.businessName}
            </div>
            {providersProducts.map((providerProducts) => (
              <div key={providerProducts.provider?.id || 'null'}>
                {providerProducts.products.length > 0 && (
                  <div className="mb-0.5 text-start font-medium">
                    {providerProducts.provider?.name || 'Non attribué'}
                  </div>
                )}
                <ListGroup className="mb-3">
                  {providerProducts.products.map((item) => (
                    <>
                      <ListGroup.Item key={item.id}>
                        <div className="d-flex justify-content-between text-start">
                          <span className="flex items-center">
                            {item.product.name}
                          </span>
                          <div className="text-right">
                            <div className="text-green-400 text-xs">
                              {item.totalPrice !== item.reducedPrice && (
                                <span className="line-through text-green-600 mr-1">
                                  {(+item.totalPrice / item.amount).toFixed(2)}€
                                </span>
                              )}
                              <span>
                                {(+item.reducedPrice / item.amount).toFixed(2)}€
                              </span>
                            </div>
                            <div className="text-sm">x{item.amount}</div>
                          </div>
                        </div>
                      </ListGroup.Item>
                      {item.freeUnitAmount ? (
                        <ListGroup.Item key={item.id}>
                          <div className="d-flex justify-content-between text-start">
                            <span className="flex items-center">
                              {item.product.name}
                            </span>
                            <div className="text-right">
                              <div className="text-green-400 text-xs">
                                {item.totalPrice !== item.reducedPrice && (
                                  <span className="line-through text-green-600 mr-1">
                                    {(+item.totalPrice / item.amount).toFixed(
                                      2
                                    )}
                                    €
                                  </span>
                                )}
                                <span>0€</span>
                              </div>
                              <div className="text-sm">
                                x{item.freeUnitAmount}
                              </div>
                            </div>
                          </div>
                        </ListGroup.Item>
                      ) : null}
                    </>
                  ))}
                </ListGroup>
              </div>
            ))}
          </div>
        ))}
        <div className="text-end">{priceComponent}</div>
        <div className="flex">
          <Form.Check
            type="checkbox"
            checked={cguCheck}
            onChange={toggleCguCheck}
          />
          &nbsp; J'ai lu et j'accepte les&nbsp;
          <a href="/cgu" target="_blank">
            CGU
          </a>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {isOrderSubmitted ? (
          <LoadingComponent />
        ) : (
          <>
            <Button variant="secondary" onClick={props.handleClose}>
              Annuler
            </Button>
            <Button
              disabled={!cguCheck}
              variant="primary"
              color="white"
              className="text-white"
              onClick={onSubmit}
            >
              Valider la commande
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  )
}
