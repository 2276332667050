import React from 'react'

interface ClickableBannerProps {
  imageUrl?: string
  onClick: () => void
  title?: string
  buttonLabel?: string
}

const ClickableBanner: React.FC<ClickableBannerProps> = ({
  imageUrl,
  onClick,
  title,
  buttonLabel,
}) => {
  const urlPlaceholder =
    'https://images.unsplash.com/photo-1628088061698-e4f4cd2969bb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'

  imageUrl = imageUrl
    ? imageUrl.startsWith('http')
      ? imageUrl
      : `http://${imageUrl}`
    : urlPlaceholder

  const backgroundImage = {
    // backgroundImage: `url(${imageUrl})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover', // 'cover' makes the image cover the entire element
    backgroundRepeat: 'no-repeat',
    borderRadius: '10px',
  }

  return (
    <div>
      <div
        onClick={onClick}
        className="h-24 mb-2 pointer flex  items-center  items-center"
        style={backgroundImage}
      >
        <div className=" flex gap-10">
          <img src={imageUrl} alt="banner" className="h-24" />
          <h1 className="text-3xl font-bold pt-8 ">{title}</h1>
        </div>
        {/* <div className="text-white text-right text-bottom absolute top-0 bottom-0 right-0 left-0 hover:mb-2 ease-in duration-100">
        <h2 className=" absolute text-xl font-bold ease-in duration-100 bottom-0 right-3 ">
          {buttonLabel}
        </h2>
      </div> */}
      </div>
    </div>
  )
}

export default ClickableBanner
