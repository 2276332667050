import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Catalog, MinimalCatalog } from '../../Types/Catalog'
import {
  addViewCount,
  getCatalog,
  getMinimalCatalogs,
} from '../ShopView/actions'
import useToken from '../../Hooks/useToken'
import { Slugs } from '../../utils/constants'
import useAuth from '../../Hooks/useAuth'
import { useHandleError } from '../../Hooks/useHandleError'
import ClickableBanner from '../ShopView/ClickableBanner'
import { Document, Page } from 'react-pdf'
import { downloadFile } from '../ProfileView/methods'

const ProviderPublicInfoView = () => {
  const [catalog, setCatalog] = useState<Catalog>({} as Catalog)
  const [allCatalogs, setAllCatalogs] = useState<MinimalCatalog[]>([])

  const navigate = useNavigate()
  const { user } = useAuth()

  const { catalogId } = useParams()
  const { loginUser } = useToken()
  const handleError = useHandleError()
  const [numPages, setNumPages] = useState<number>()
  const onDocumentLoadSuccess = useCallback(
    ({ numPages }: { numPages: number }) => setNumPages(numPages),
    []
  )
  const [width, setWidth] = useState<number>()

  useEffect(() => {
    if (catalogId) {
      refresh()
    }
  }, [catalogId])

  const refresh = async () => {
    try {
      const fetchedCatalog = await getCatalog(loginUser, catalogId)
      setCatalog(fetchedCatalog)
      addViewCount(loginUser, fetchedCatalog.id.toString())
    } catch (error) {
      handleError(error)
      navigate('/404')
    }
  }

  useEffect(() => {
    if (catalog && user) {
      const businessId =
        user.business?.id !== catalog.business?.id
          ? catalog.business?.id
          : user.business?.id
    }
  }, [catalog, user])

  useEffect(() => {
    const fetchAllCatalogs = async () => {
      try {
        const fetchedCatalogs = await getMinimalCatalogs(loginUser)
        setAllCatalogs(fetchedCatalogs)
      } catch (error) {
        handleError(error)
      }
    }

    fetchAllCatalogs()
  }, [])

  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const handleResize = () => {
      setWidth(Math.min(window.innerWidth - 70, 800))
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    adjustPDFHeight()

    // wait 1 sec then  remove loading
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  function adjustPDFHeight() {
    var pdfObject = document.getElementById('pdf-object')
    if (pdfObject) pdfObject.style.height = window.innerHeight + 'px'
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
      }}
    >
      <ClickableBanner
        imageUrl={catalog.providerLogo}
        onClick={() => {
          navigate(Slugs.SHOP + '/' + catalog.id)
        }}
        buttonLabel="Voir les produits"
        title={allCatalogs.find((c) => c.id === Number(catalogId))?.name}
      />
      <div className="text-wrap">{catalog.presentationText}</div>
      {/* Two button */}
      <div className="flex gap-5 justify-center mt-10">
        <button
          className="btn btn-primary"
          onClick={() => {
            navigate(Slugs.SHOP + '/' + catalog.id)
          }}
        >
          Voir les produits
        </button>
        {catalog?.presentationDoc && (
          <button
            className=" btn btn-primary"
            onClick={() => {
              catalog.presentationDoc && downloadFile(catalog.presentationDoc)
            }}
          >
            Document de présentation
          </button>
        )}
      </div>

      {/* <div
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <object
          id="pdf-object"
          data="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
          type="application/pdf"
          width="100%"
          height="100%"
        >
          <p>
            <a href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf">
              to the PDF!
            </a>
          </p>
        </object>
      </div>

      <Document
        file={
          'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'
        }
        onLoadSuccess={onDocumentLoadSuccess}
        onError={(error) => console.error(error)}
        className="w-full h-full flex justify-center flex-col max-sm:scale-[119%] origin-top overflow-hidden"
      >
        <Page pageNumber={1} />
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            className="mx-auto scale-[109%] origin-center"
            width={width}
          />
        ))}
      </Document> */}
    </div>
  )
}

export default ProviderPublicInfoView
