import React, { useEffect, useState } from "react"
import FactureView from "../FactureView"
import { fetchFacture } from "./actions"

export const DownloadFactureView: React.FC = () => {
    const uuid = new URLSearchParams(window.location.search).get("uuid")
    const [factureView, setFactureView] = useState<React.ReactElement>()

    useEffect(() => {
        if(uuid)
            fetchFacture(uuid).then(order => setFactureView(() => <FactureView order={order} />))
    }, [uuid])
    return <>
        Téléchargement...
        {factureView}
    </>
}