import {LoginUser, User} from "../../Types/User";
import {get, post} from "../../Api/api";
import {getFileNameFromHeader, handleDownload} from "../../Api/download";
import {Dispensary} from "../../Types/Dispensary";
import {Provider} from "../../Types/Provider";
import * as mime from "mime";
import { UpdateCatalogDto } from '../../Types/Catalog'

export const getDispensaryAccountsToValidate = (login: LoginUser) => {
    return get<Dispensary[]>("/user/dispensary-to-validate", login);
}

export const getVerifiedAccounts = (login: LoginUser) => {
    return get<User[]>("/user/verified", login);
}
export const updatePassword = (login: LoginUser, userId: number, password: string) => {
    return post("/user/change_password", login, {
        id: userId,
        password,
    });
}
export const getOrders = (login: LoginUser) => {
    return get<{ id: number, businessId: number, providerBusinessId: number }[]>("/user/orders", login);
}

export const getProviderAccountsToValidate = (login: LoginUser) => {
    return get<Provider[]>("/user/provider-to-validate", login);
}

export const handleDownloadIdCardAdmin = async (login: LoginUser, contactName: string, email: string) => {
    const fileName = `id-card-${contactName}`
    return handleDownload(login, fileName, `/user/download/admin/id-card?username=${email}`)
}

export const impersonateUser = async (login: LoginUser, id: number) => {
    return post<{access_token: string}>("/auth/impersonate", login, {userId: id});
}

export const unimpersonateUser = async (login: LoginUser) => {
    return post<{access_token: string}>("/auth/unimpersonate", login, {});
}

export const handleDownloadCpsCardAdmin = async (login: LoginUser, contactName: string, email: string) => {
    const fileName = `cps-card-${contactName}`
    return handleDownload(login, fileName, `/user/download/admin/cps-card?username=${email}`)
}

export const getDownloadCpsCardAdminUrl = async (login: LoginUser, email: string) => {
    const fileBlob = await get<{ file: Blob, contentDisposition: string }>(`/user/download/admin/cps-card?username=${email}`, login, true, false, true)
    const fileName = getFileNameFromHeader(fileBlob.contentDisposition);

    return URL.createObjectURL(fileBlob.file.slice(0, fileBlob.file.size, (mime as any).getType(fileName)))
}
export const getDownloadIdCardAdminUrl = async (login: LoginUser, email: string) => {
    const fileBlob = await get<{ file: Blob, contentDisposition: string }>(`/user/download/admin/id-card?username=${email}`, login, true, false, true)
    const fileName = getFileNameFromHeader(fileBlob.contentDisposition);
    console.log((mime as any).getType(fileName), fileName)
    return URL.createObjectURL(fileBlob.file.slice(0, fileBlob.file.size, (mime as any).getType(fileName)))
}


export const declineAccount = (login: LoginUser, userId: number, reason: string) => {
    return post<void>("/user/decline", login, {userId, reason})
}

export const approveAccount = (login: LoginUser, userId: number) => {
    return post<void>(`/user/validate?id=${userId}`, login, null)
}

export const registerAdmin = (login: LoginUser, body: {email: string, firstName: string, lastName: string}) => {
    return post<void>("/auth/register/admin", login, body)
}

export const deleteAccount = (login: LoginUser, userId: number) => {
    return post<void>("/user/delete", login, {userId})
}

export const lockAccount = (login: LoginUser, userId: number, lock: boolean) => {
    return post<void>("/user/lock", login, {userId, lock})
}

export const updateCatalogPriority = (login: LoginUser, businessId: number, newPriority: number) => {
    const requestBody = { newPriority };

    return post<void>(
      `/catalog/update-priority/${businessId}`,
      login,
      requestBody
    );
};

