import React, { useEffect, useState } from 'react'
import '../style-create.scss'
import SelectedPharmacies from './create-condition-components/SelectedPharmacies'
import SelectedProduct from './create-condition-components/SelectedProduct'
import DiscountForm from './create-condition-components/DiscountForm'
import { BusinessConditionProvider } from '../../../Contexts/business-condition/BusinessConditionProvider'
import ConfirmationModal from './create-condition-components/ConfirmationModal'
import {
  BusinessConditionType,
  CreateConditionDto,
} from '../../../Types/BusinessCondition'
import {
  BusinessConditionActionType,
  BusinessConditionContext,
  BusinessConditionState,
} from '../../../Contexts/business-condition/context'
import {
  addBusinessCondition,
  getOneBusinessCondition,
  updateBusinessCondition,
} from '../actions'
import useToken from '../../../Hooks/useToken'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Slugs } from '../../../utils/constants'
import { useHandleError } from '../../../Hooks/useHandleError'
import { Button, Form } from 'react-bootstrap'

import deepEqual from 'deep-equal'
import SelectedNetworks from './create-condition-components/SelectedNetworks'
import { BounceLoader } from 'react-spinners'
import SelectedProviders from './create-condition-components/SelectedProviders'
import useToast from '../../../Hooks/useToast'

const ContextWrapper = () => {
  return (
    <BusinessConditionProvider>
      <CreateBusinessConditionView />
    </BusinessConditionProvider>
  )
}

const CreateBusinessConditionView = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [isLoading, setLoading] = React.useState<boolean>(false)

  const [freeUnitCap, setFreeUnitCap] = useState<number>(1)
  const { showError } = useToast()
  const { state, dispatch } = React.useContext(BusinessConditionContext)
  const { loginUser } = useToken()
  const navigate = useNavigate()
  const handleError = useHandleError()

  const location = useLocation()

  const { businessConditionId } = useParams()

  const isCreate = location.pathname.includes(Slugs.BUSINESS_CONDITIONS_CREATE)

  const [originalState, setOriginalState] = useState<BusinessConditionState>(
    () => JSON.parse(JSON.stringify(state))
  )
  const [changed, setChanged] = useState(false)
  useEffect(() => setChanged(!deepEqual(state, originalState)), [state])

  const handleSubmit = () => {
    setLoading(true)

    if (state.provider || (state.providers && state.providers.length)) {
      const dto: CreateConditionDto = {
        steps:
          state.type !== BusinessConditionType.FREE_UNIT ? state.steps : [],
        type: state.type,
        dispensaryIds: state.dispensaryIds,
        networks: state.networks,
        freeUnitCap:
          state.type === BusinessConditionType.FREE_UNIT
            ? freeUnitCap
            : undefined,
        productIds: state.products.map((product) => product.id),
        providerId: 0,
      }
      if (state.provider) dto.providerId = state.provider.id
      console.log(dto)

      if (isCreate) {
        // if(dto.products) delete dto.products
        if (state.providers && state.providers.length) {
          state.providers.forEach((provider) => {
            addBusinessCondition(loginUser, {
              ...dto,
              productIds: state.products
                .filter((product) => product.providerId === provider.id)
                .map((product) => product.id),
              providerId: provider.id,
            })
              .then(() => {
                navigate(Slugs.BUSINESS_CONDITIONS)
              })
              .catch(handleError)
              .finally(() => {
                setLoading(false)
              })
          })
        } else {
          addBusinessCondition(loginUser, dto)
            .then(() => {
              navigate(Slugs.BUSINESS_CONDITIONS)
            })
            .catch(handleError)
            .finally(() => {
              setLoading(false)
            })
        }
      } else {
        updateBusinessCondition(loginUser, dto, businessConditionId)
          .then(() => {
            navigate(Slugs.BUSINESS_CONDITIONS)
          })
          .catch(handleError)
          .finally(() => {
            setLoading(false)
          })
      }
    } else {
      setLoading(false)
      setIsOpen(false)
      showError('Vous devez sélectionner une marque')
    }
  }

  const [generalLoading, setGeneralLoading] = useState(
    !isCreate && businessConditionId && !isNaN(Number(businessConditionId))
  )

  useEffect(() => {
    if (
      !isCreate &&
      businessConditionId &&
      !isNaN(Number(businessConditionId))
    ) {
      setGeneralLoading(true)
      getOneBusinessCondition(loginUser, businessConditionId)
        .then((bc) => {
          dispatch({
            type: BusinessConditionActionType.SET_BUSINESS_CONDITION,
            payload: { ...bc },
          })

          setGeneralLoading(false)
          setOriginalState({
            type: bc.type,
            steps: bc.steps,
            products: bc.products,
            dispensaryIds: bc.dispensaries.map((dispensary) => dispensary.id),
            networks: bc.networks,
            provider: bc.provider,
            freeUnitCap: bc.freeUnitCap,
          })
          if (bc.type === 2) setFreeUnitCap(bc.freeUnitCap || 1)
        })
        .catch(handleError)
    }
  }, [location.pathname])

  const updateType = (type: BusinessConditionType) => {
    dispatch({ type: BusinessConditionActionType.TOGGLE_TYPE, payload: type })
  }

  return generalLoading ? (
    <div className="w-full flex justify-center">
      <BounceLoader color="#5ecc78" />
    </div>
  ) : (
    <div className="overflow-hidden">
      <div className="d-flex justify-content-between align-items-center w-100 mb-3">
        <span className="!text-3xl sm:!text-5xl page-title">
          {isCreate
            ? 'Créer une condition commerciale'
            : 'Modifier une condition commerciale'}
        </span>
      </div>
      <div className="flex justify-center">
        <div className="discount-type-selector">
          <span
            className={`p-2 pointer rounded ${
              state.type == BusinessConditionType.SHIPPING_COST &&
              ' type-selected'
            }`}
            onClick={() =>
              isCreate
                ? updateType(BusinessConditionType.SHIPPING_COST)
                : alert(
                    'Vous ne pouvez pas modifier le type de condition commerciale'
                  )
            }
          >
            Frais de livraison
          </span>

          <span
            className={`p-2 pointer rounded ml-1 ${
              state.type == BusinessConditionType.PRODUCT_AMOUNT &&
              ' type-selected'
            }`}
            onClick={() =>
              isCreate
                ? updateType(BusinessConditionType.PRODUCT_AMOUNT)
                : alert(
                    'Vous ne pouvez pas modifier le type de condition commerciale'
                  )
            }
          >
            Prix unitaire
          </span>
          <span
            className={`p-2 pointer rounded ml-1 ${
              state.type == BusinessConditionType.FREE_UNIT && ' type-selected'
            }`}
            onClick={() =>
              isCreate
                ? updateType(BusinessConditionType.FREE_UNIT)
                : alert(
                    'Vous ne pouvez pas modifier le type de condition commerciale'
                  )
            }
          >
            Unité gratuite
          </span>
        </div>
      </div>

      <SelectedPharmacies />
      <div className="horizontal-divider-3" />

      <SelectedNetworks />
      <div className="horizontal-divider-3" />

      <SelectedProviders isEdition={!isCreate} />
      <div className="horizontal-divider-3" />
      {state.type == BusinessConditionType.FREE_UNIT ? (
        <div className="d-flex flex-column align-items-start me-4">
          <div className="text-start">
            <span className="section-title">
              Unité gratuite (1 produit offert pour X acheté)
            </span>
          </div>
          <Form.Group className="mb-3">
            <Form.Label>Palier d'unité gratuite</Form.Label>
            <Form.Control
              className="grey-form-input"
              type="number"
              placeholder="Nombre d'unités à acheter"
              autoComplete="off"
              value={freeUnitCap}
              onChange={(e) => {
                const value = e.target.value
                if (/^[0-9]*$/.test(value)) {
                  setFreeUnitCap(+value)
                  dispatch({
                    type: BusinessConditionActionType.EDIT_FREE_UNIT_CAP,
                    payload: +value,
                  })
                  console.log(value)
                }
              }}
            />
          </Form.Group>
        </div>
      ) : (
        <DiscountForm />
      )}

      <div className="horizontal-divider-3" />

      <div className="mt-4">
        {state &&
          (state.type === BusinessConditionType.PRODUCT_AMOUNT ||
            state.type === BusinessConditionType.FREE_UNIT) && (
            <SelectedProduct isEdition={!isCreate} />
          )}
      </div>
      <ConfirmationModal
        onConfirm={handleSubmit}
        onCancel={() => setIsOpen(false)}
        isLoading={isLoading}
        header={`Confirmation ${
          !isCreate ? "de modification d'une" : "d'ajout d'une nouvelle"
        } condition commerciale`}
        body={`Voulez-vous vraiment ${
          !isCreate ? 'modifier cette' : 'ajouter une nouvelle'
        } condition commerciale ? Celle-ci sera immédiatement active sur les produits sélectionnés pour les pharmacies concernées.`}
        isOpen={isOpen}
      />

      {changed && (
        <div className="fixed left-0 bottom-6 w-full flex justify-center">
          <div className="rounded-lg bg-neutral-600 text-white shadow max-w-[80%] text-center p-1.5 pl-2 select-none">
            Des modifications ont été apportées à cette condition commerciale
            <Button
              size="sm"
              className="text-white ml-3"
              onClick={() => setIsOpen(true)}
              style={{ height: 'fit-content' }}
            >
              Enregistrer
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default ContextWrapper
