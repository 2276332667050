import React from 'react'
import {
  BusinessConditionStepDto,
  BusinessConditionType,
} from '../../../../Types/BusinessCondition'
import DiscountStepForm from './discount-form/DiscountStepForm'
import {
  BusinessConditionActionType,
  BusinessConditionContext,
} from '../../../../Contexts/business-condition/context'
import deepEqual from 'deep-equal'
import { FiX } from 'react-icons/fi'

const DiscountForm = () => {
  const { state, dispatch } = React.useContext(BusinessConditionContext)

  const addStep = (step: BusinessConditionStepDto) => {
    if (
      step.discountRate > 0 &&
      !state.steps.find(
        (other) =>
          deepEqual(other, step) ||
          (step.discountRate <= other.discountRate &&
            step.minimumOrderAmount >= other.minimumOrderAmount)
      )
    ) {
      state.steps
        .filter(
          (other) =>
            other.discountRate <= step.discountRate &&
            other.minimumOrderAmount >= step.minimumOrderAmount
        )
        .forEach((other) =>
          dispatch({
            type: BusinessConditionActionType.REMOVE_STEP,
            payload: other,
          })
        )
      dispatch({ type: BusinessConditionActionType.ADD_STEP, payload: step })
    }
  }

  return (
    <>
      <div className="flex flex-col w-full">
        <div className="flex justify-between flex-wrap">
          <div>
            <div className="text-start">
              <span className="section-title">Réduction</span>
            </div>
            <DiscountStepForm
              handleSubmit={addStep}
              unitaire={state.type === BusinessConditionType.PRODUCT_AMOUNT}
            />
          </div>
          <div className="min-w-[50%]">
            <div className="text-start">
              <span className="section-title">Paliers</span>
            </div>

            {state.steps.length > 0 && (
              <>
                <div className="row">
                  <span className="col fw-bold">Pourcentage de réduction</span>
                  <span className="col fw-bold">
                    {state.type === BusinessConditionType.PRODUCT_AMOUNT
                      ? 'Quantité minimum'
                      : 'Montant minimum (€)'}
                  </span>
                </div>
                {state.steps
                  .sort((a, b) => a.minimumOrderAmount - b.minimumOrderAmount)
                  .map((step, index) => (
                    <div key={index}>
                      <div className="flex relative">
                        <div className="w-full flex">
                          <span className="col">{step.discountRate}%</span>
                          <span className="col">
                            {step.minimumOrderAmount}
                            {state.type === BusinessConditionType.PRODUCT_AMOUNT
                              ? ''
                              : '€'}
                          </span>
                        </div>
                        <FiX
                          onClick={() =>
                            dispatch({
                              type: BusinessConditionActionType.REMOVE_STEP,
                              payload: step,
                            })
                          }
                          className="absolute right-0 top-1 text-red-500 cursor-pointer"
                        />
                      </div>
                      <div className="horizontal-divider-1" />
                    </div>
                  ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default DiscountForm
