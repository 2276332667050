import React, { useEffect, useState } from 'react'
import { Provider } from '../../Types/Provider'
import { useNavigate, useParams } from 'react-router-dom'
import ItemCard from '../../Components/ItemCard'
import { Catalog, MinimalCatalog } from '../../Types/Catalog'
import {
  addViewCount,
  getBusinessConditionSteps,
  getCatalog,
  getItemsBusinessConditionSteps,
  getManualBusinessProducts,
  getMinimalCatalogs,
} from './actions'
import useToken from '../../Hooks/useToken'
import { Product } from '../../Types/Product'
import { LoadingComponent } from '../../Components/Loading'
import { SelectCatalog } from './SelectCatalog'
import { Slugs } from '../../utils/constants'
import useAuth from '../../Hooks/useAuth'
import { Roles } from '../../Types/Role'
import {
  BusinessConditionStep,
  ProductBusinessConditionStep,
} from '../../Types/BusinessCondition'
import { useHandleError } from '../../Hooks/useHandleError'
import { User } from '../../Types/User'
import ClickableBanner from './ClickableBanner'

const ShopView = () => {
  const [catalog, setCatalog] = useState<Catalog>({} as Catalog)
  const [allCatalogs, setAllCatalogs] = useState<MinimalCatalog[]>([])
  const [businessProducts, setBusinessProducts] = useState<Product[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')

  const navigate = useNavigate()
  const { user } = useAuth()

  const { catalogId } = useParams()
  const { loginUser } = useToken()
  const handleError = useHandleError()

  useEffect(() => {
    if (catalogId) {
      refresh()
    }
  }, [catalogId])

  const refresh = async () => {
    try {
      const fetchedCatalog = await getCatalog(loginUser, catalogId)
      setCatalog(fetchedCatalog)
      addViewCount(loginUser, fetchedCatalog.id.toString())
    } catch (error) {
      handleError(error)
      navigate('/404')
    }
  }

  useEffect(() => {
    if (catalog && user) {
      const businessId =
        user.business?.id !== catalog.business?.id
          ? catalog.business?.id
          : user.business?.id
      if (businessId) {
        getManualBusinessProducts(loginUser, businessId)
          .then((products) =>
            setBusinessProducts(
              products.sort((a, b) => a.name.localeCompare(b.name))
            )
          )
          .catch((error) => handleError(error))
      }
    }
  }, [catalog, user])

  useEffect(() => {
    const fetchAllCatalogs = async () => {
      try {
        const fetchedCatalogs = await getMinimalCatalogs(loginUser)
        setAllCatalogs(fetchedCatalogs)
      } catch (error) {
        handleError(error)
      }
    }

    fetchAllCatalogs()
  }, [])

  const updateCatalog = (catalogId: number) => {
    navigate(`${Slugs.SHOP}/${catalogId}`)
  }

  const handleSearch = (e: {
    target: { value: React.SetStateAction<string> }
  }) => {
    setSearchTerm(e.target.value)
  }

  return (
    <div>
      <ClickableBanner
        imageUrl={catalog.providerLogo}
        onClick={() => {
          navigate(`${Slugs.SHOP}/${catalogId}${Slugs.INFO}`)
        }}
        buttonLabel="En savoir plus"
        title={allCatalogs.find((c) => c.id === Number(catalogId))?.name}
      />

      {/* Search bar */}
      <div className="search-bar-container mb-2">
        <input
          type="text"
          placeholder="Rechercher un produit..."
          value={searchTerm}
          onChange={handleSearch}
          className="search-bar form-control"
        />
      </div>

      {catalog.id && allCatalogs.length > 0 ? (
        <>
          {user.role !== Roles.provider && (
            <div className="d-flex justify-content-between pe-xl-5 pb-3 items-center">
              <div>
                <SelectCatalog
                  catalogs={allCatalogs}
                  currentCatalog={allCatalogs.find(
                    (c) => c.id === Number(catalogId)
                  )}
                  updateCatalog={updateCatalog}
                />
              </div>
            </div>
          )}

          {catalog.providers.map((provider: Provider) => (
            <ProviderPart
              key={provider.id}
              provider={provider}
              user={user}
              refresh={refresh}
              searchTerm={searchTerm}
            />
          ))}
        </>
      ) : (
        <LoadingComponent />
      )}

      <div className="text-xs text-neutral-400 mb-3">
        Les prix et remises indiqués le sont à titre indicatifs. Philab ne peut
        garantir l'exactitude de ces informations. Pour en savoir plus, veuillez
        vous rapprocher du laboratoire.
      </div>
    </div>
  )
}

const ProviderPart: React.FC<{
  provider: Provider
  user: User
  refresh: () => void
  searchTerm: string
}> = ({ provider, user, refresh, searchTerm }) => {
  const [itemsSteps, setItemsSteps] = useState<ProductBusinessConditionStep[]>(
    []
  )
  const [bcSteps, setBcSteps] = useState<BusinessConditionStep[]>([])
  //const [searchTerm, setSearchTerm] = useState<string>('');

  const handleError = useHandleError()
  const { loginUser } = useToken()

  useEffect(() => {
    if (user.role === Roles.pharma) {
      getBusinessConditionSteps(loginUser, provider.id.toString())
        .then((res) => setBcSteps(res))
        .catch(handleError)
      // getItemsBusinessConditionSteps(loginUser, provider.id.toString())
      //   .then((res) => setItemsSteps(res))
      //   .catch(handleError)
    }
  }, [provider.id])

  const sortedProducts = provider.products.sort((a, b) =>
    a.name.localeCompare(b.name)
  )

  // Filtered products based on search term
  const filteredSortedProducts = sortedProducts
    .filter((product) =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => a.name.localeCompare(b.name))

  // Function to render products
  const renderProducts = (products: Product[]) => {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
        {products.map((item: Product) => (
          <ItemCard
            key={item.id}
            // sales={itemsSteps}
            product={item}
            handleRefresh={refresh}
          />
        ))}
      </div>
    )
  }

  return (
    <div key={provider.id}>
      <h3 className="page-subtitle">{provider.name}</h3>
      {renderProducts(filteredSortedProducts)}
    </div>
  )
}

export default ShopView
